import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import classes from './FormItemLabel.module.less';
import './FormItemLabel.less';

type FormItemLabelProps = {
  hasError?: boolean;
  translationKey?: string;
  isActive: boolean;
  disabled?: boolean;
  optional?: boolean;
};

const FormItemLabel: FC<FormItemLabelProps> = ({
  hasError,
  translationKey,
  isActive,
  disabled,
  optional,
}) => {
  const { t } = useTranslation();

  return (
    <span
      className={classNames({
        [classes.error]: hasError,
        [classes.active]: isActive && !hasError,
        [classes.disabled]: disabled,
      })}
      style={{ color: 'slategrey', fontSize: '0.7rem' }}
    >
      {translationKey ? t(translationKey) : ''}{' '}
      {optional ? (
        <span
          className={classNames({
            [classes.disabled]: !isActive,
          })}
        >
          ({t('common:labels.optional')})
        </span>
      ) : <span />}
    </span>
  );
};

export default FormItemLabel;
