import slice from './slice';
import API from '../../../services/ApiService';
import { AppThunk } from '../../../redux/store';

const {
  startSendingContactForm,
  finishSendingContactForm,
  setSendingContactFormError,
} = slice.actions;

const sendContactForm = (contactData: any): AppThunk<Promise<void>> => (
  async (dispatch) => {
    dispatch(startSendingContactForm());
    try {
      await API.contact.create(contactData);
    } catch (e) {
      const { response } = e as any;

      dispatch(setSendingContactFormError(response && response.data));
    } finally {
      dispatch(finishSendingContactForm());
    }
  }
);

export {
  sendContactForm,
};
