/* eslint-disable max-len */
import React, {
  FC, useEffect, useState,
} from 'react';
import './PickView.less';
import './slice/slice';
import { useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import FixWidthContent from '../../components/FixWidthContent';
import withTagManager from '../../hooks/withTagManager';
import { PictureOfWord } from '../../components/Pick/PictureOfWord';
import InfoButton from '../../components/InfoButton/InfoButton';
import RulesOfTheGame from './RulesOfTheGame';
import { BASE_API_URL } from '../../services/ApiService';
import { getActiveList } from '../auth/slice/selectors';
import EndGameInfo from '../../components/EndGameInfo/EndgameInfo';
import useGoToRoute from '../../hooks/useGoToRoute';
import { Path } from '../../constants/Path';

export const PickView: FC = () => {
  const activeList = useSelector(getActiveList);
  const { words } = activeList;
  const wordsCopy = [...words];
  const randomOrderOfWords = wordsCopy.sort(() => 0.5 - Math.random());

  const [wordOnScreen, setWordOnScreen] = useState<number>(0);
  const [mistakeStyle, setMistakeStyle] = useState<boolean>(false);
  const [correctStyle, setCorrectStyle] = useState<boolean>(false);
  const [audio, setAudio] = useState<any>(null);

  const [listEndInfo, setListEndInfo] = useState(false);

  const [stateWordsCopy, setStateWordsCopy] = useState(randomOrderOfWords);
  const removedPictureData = stateWordsCopy.filter((word) => word.id !== stateWordsCopy[wordOnScreen].id);
  const removedDuplicates = [...new Map(removedPictureData.map((word) => [JSON.stringify([word.term]), word])).values()];
  const shuffledWords = removedDuplicates.sort(() => 0.5 - Math.random());
  const removedAddedDuplicates = shuffledWords.filter((word) => word.term !== stateWordsCopy[wordOnScreen].term);
  const fiveWords = removedAddedDuplicates.slice(0, 5);
  const addPictureData = [...fiveWords, stateWordsCopy[wordOnScreen]];
  const sixWords = addPictureData.sort(() => 0.5 - Math.random());
  const [currentSixWords, setCurrentSixWords] = useState(sixWords);

  const numberOfWords = stateWordsCopy.length;
  const index = stateWordsCopy.indexOf(stateWordsCopy[wordOnScreen]) + 1;

  const addWordToRepeat = () => {
    const wordToRepeat = { ...stateWordsCopy[wordOnScreen] };
    wordToRepeat.index = numberOfWords;
    wordToRepeat.id = wordToRepeat.index.toString();
    setStateWordsCopy([...stateWordsCopy, wordToRepeat]);
  };

  const handleClick = (id: any, term: any) => {
    const playNextWord = () => {
      setWordOnScreen((prev: number) => prev + 1);
    };

    const handleAudioEnded = () => {
      playNextWord();
    };

    if (stateWordsCopy[wordOnScreen]?.term === term && index < numberOfWords) {
      setMistakeStyle(false);
      setCorrectStyle(id);

      if (audio) {
        audio.pause();
      }

      const newAudio = new Audio(`${BASE_API_URL}/v1/tts?q=${stateWordsCopy[wordOnScreen].term}`);
      newAudio.addEventListener('ended', handleAudioEnded);
      setAudio(newAudio);
      newAudio.play();
    } else if (stateWordsCopy[wordOnScreen]?.term === term && index === numberOfWords) {
      setCorrectStyle(id);

      if (audio) {
        audio.pause();
      }

      const newAudio = new Audio(`${BASE_API_URL}/v1/tts?q=${stateWordsCopy[wordOnScreen].term}`);
      newAudio.addEventListener('ended', () => {
        setListEndInfo(true);
      });
      setAudio(newAudio);
      newAudio.play();
    } else {
      setCorrectStyle(false);
      setMistakeStyle(id);
      addWordToRepeat();
    }
  };

  const goToNext = useGoToRoute(Path.PAIRING);
  const repeatGame = () => {
    setTimeout(() => {
      setWordOnScreen(0);
      setCorrectStyle(false);
      setMistakeStyle(false);
      setListEndInfo(false);
    }, 500);
  };

  useEffect(() => {
    setCurrentSixWords(sixWords);
    setCorrectStyle(false);
  }, [wordOnScreen]);

  return (
    <>
      <FixWidthContent>
        <div className="enter">

          <InfoButton content={<RulesOfTheGame />} />

          {!listEndInfo && (
            <>
              <PictureOfWord
                id={stateWordsCopy[wordOnScreen]?.id}
                style={{ backgroundImage: `url(${stateWordsCopy[wordOnScreen]?.image})` }}
              />
              <div className="pick_container">
                {currentSixWords.map((word: any) => (
                  <div
                    className={`picks ${word?.id === mistakeStyle ? 'mistake' : ''} ${word?.id === correctStyle ? 'correct' : ''}`}
                    key={nanoid()}
                    id={word?.id}
                    onClick={() => handleClick(word.id, word?.term)}
                    onKeyDown={() => handleClick(word.id, word?.term)}
                    role="button"
                    tabIndex={0}
                  >
                    {word?.term}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        {listEndInfo && (
          <EndGameInfo repeatGameTrigger={repeatGame} nextGame={goToNext} />
        )}

      </FixWidthContent>
    </>
  );
};

export default withTagManager(PickView);

