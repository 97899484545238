import * as Yup from 'yup';

const INITIAL_VALUES: any = {
  email: '',
  message: '',
};

export default (t: any) => Yup.object().shape({
  email: Yup.string().required(t('common:contact.validation.required')).email(t('common:contact.validation.email')),
  message: Yup.string().required(t('common:contact.validation.required')),
});

export { INITIAL_VALUES };
