import { useLocalStorage } from '@rehooks/local-storage';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { decode } from 'jsonwebtoken';
import isTokenValid from '../validators/isTokenValid';
import { UserData } from '../services/ApiService.types';

const useAuthToken = (
  onTokenInvalid?: () => void,
  onTokenValid?: (user: UserData) => void,
): boolean => {
  const [token, , deleteToken] = useLocalStorage('token');
  const dispatch = useDispatch();
  const isValid = useMemo(
    () => isTokenValid(token),
    [token],
  );

  useEffect(() => {
    if (!isValid) {
      // TODO wipe data
      deleteToken();
      if (onTokenInvalid) {
        onTokenInvalid();
      }
    } else if (onTokenValid && token) {
      const user = decode(token) as UserData;

      onTokenValid(user);
    }
  }, [isValid, deleteToken, dispatch, onTokenInvalid, onTokenValid]);

  return isValid;
};

export default useAuthToken;
