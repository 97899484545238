import React, { FC, useCallback } from 'react';
import { Button, Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  setDeletingWordsListError, deleteWordsList,
} from '../slice/actions';
import {
  getDeletingWordsListError,
  getIsDeletingWordsList,
} from '../slice/selectors';
import classes from './DeleteWordsListModal.module.less';
import styles from './DeleteWordsListModal.styles';
import { success, error } from '../../../components/Message';
import Text from '../../../components/Text';
import closeIcon from '../../../assets/icons/x.svg';
import useActionStatus from '../../../hooks/useActionStatus';
import { setActiveList } from '../../auth/slice/actions';

type DeleteWordsListModalProps = {
  wordsList: { id: number, name: string };
  hideModal: () => void;
};

const { Title } = Typography;

const DeleteWordsListModal: FC<DeleteWordsListModalProps> = ({ wordsList, hideModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsDeletingWordsList);
  const apiError = useSelector(getDeletingWordsListError);

  const onSuccess = useCallback(() => {
    success(t('words-list:delete.messages.success'));
    hideModal();
  }, [hideModal, t]);

  const onError = useCallback(() => {
    error(t('words-list:delete.messages.failure'));
  }, [t]);

  useActionStatus(onSuccess, onError, {
    isLoadingSelector: getIsDeletingWordsList,
    errorSelector: getDeletingWordsListError,
  });

  const onClick = useCallback(() => {
    if (wordsList && wordsList.id) {
      dispatch(deleteWordsList(wordsList.id));
      dispatch(setActiveList(null));
    }
  }, [dispatch, wordsList]);

  const onCancel = useCallback(() => {
    dispatch(setDeletingWordsListError(null));
    hideModal();
  }, [dispatch, hideModal]);

  return wordsList && (
    <Modal
      bodyStyle={styles.modalBodyStyle}
      footer={null}
      visible={Boolean(wordsList)}
      onCancel={onCancel}
      destroyOnClose
      centered
      closeIcon={<img src={closeIcon} alt="Close" />}
    >
      <Title className="centered" style={{ color: 'gray', fontWeight: 'bolder' }}>{t('words-list:delete.title')}</Title>
      <Text className={classes.description}>{t('words-list:delete.description')}</Text>
      <div className={classes.buttons}>
        <Button
          className={classNames([classes.button, classes.cancelButton])}
          size="large"
          type="ghost"
          onClick={onCancel}
          disabled={isLoading}
        >
          {t('words-list:delete.cancel')}
        </Button>
        <Button
          className={classes.button}
          size="large"
          type="primary"
          style={{ backgroundColor: 'darkred' }}
          danger
          onClick={onClick}
          disabled={isLoading}
          loading={isLoading}
        >
          {t('words-list:delete.submit')}
        </Button>
      </div>
      {apiError && <div>{apiError.message}</div>}
    </Modal>
  );
};

export default DeleteWordsListModal;
