/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';

type INITIAL_STATE_TYPE = {
  cardsInGame: string[],
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  cardsInGame: [],
};

const { name, reducer, ...rest } = createSlice({
  name: 'pairing',
  initialState: INITIAL_STATE,
  // reducers not implemented - probably to be deleted
  reducers: {
    dropCardAndMatch: (state, action) => {
      state.cardsInGame = [...state.cardsInGame, action.payload];
    },
    stopComparingItems: (state, action) => {
      state.cardsInGame = [...state.cardsInGame, action.payload];
    },
  },
});

export default {
  name,
  reducer,
  ...rest,
};
