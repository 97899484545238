export enum Path {
  STUDENTS = '/students',
  PROFILE = '/profile',
  MY_LISTS = '/my-lists',
  WORDS_LIST = '/words-lists',
  WORDS_LIST_ITEM = '/words-lists/:id',
  REPEAT = '/repeat',
  FIND = '/find',
  PICK = '/pick',
  MEMORY = '/memory',
  PAIRING = '/pairing',
  RECOGNIZE = '/recognize',
  RECOGNIZE2 = '/recognize2',
  WRITING = '/writing',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  CONTACT = '/contact',
  // PUBLIC
  HOME = '/',
  LOGIN = '/login',
  ACTIVATE_TEACHER = '/activate/teacher/:token',
  ACTIVATE_STUDENT = '/activate/student/:token',
  SIGN_UP = '/sign-up',
  SIGN_UP_CONFIRM = '/sign-up-confirm',
  RESET_PASSWORD = '/reset-password',
}

export const getWordsListLink = (wordsListId: number): Path => (
  Path.WORDS_LIST_ITEM.replace(':id', wordsListId ? wordsListId.toString() : '') as Path
);

export default Path;
