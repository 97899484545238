import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import {
  Form, SubmitButton, Input,
} from 'formik-antd';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import getValidationSchema, { INITIAL_VALUES } from './ContactForm.schema';
import classes from './ContactForm.module.less';
import FormItem from '../FormItem';
import { error, success } from '../Message';
import { getIsSending, getIsSendingError } from '../../views/contact/slice/selectors';
import useActionStatus from '../../hooks/useActionStatus';

const { TextArea } = Input;

interface ContactFormProps {
  onSubmit: (values: any) => any
}

const ContactForm = ({ onSubmit }: ContactFormProps) => {
  const { t } = useTranslation();
  const validationSchema = getValidationSchema(t);
  const isSending = useSelector(getIsSending);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

  const onError = useCallback(() => {
    error(t('common:contact.errorMessage'));
  }, [t]);

  const onSuccess = useCallback(() => {
    success(t('common:contact.successMessage'));
    setInitialValues(INITIAL_VALUES);
  }, [t]);

  const handleSubmit = (values: any, { resetForm }: any) => {
    onSubmit(values);
    resetForm(INITIAL_VALUES);
  };

  useActionStatus(onSuccess, onError, {
    isLoadingSelector: getIsSending,
    errorSelector: getIsSendingError,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors, touched, dirty, values, isValid,
      }) => (
        <Form id="contactForm" data-cy="contactForm" className={classes.form}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12}>
              <FormItem
                className={classes.fullWidth}
                name="email"
                error={errors.email}
                isTouched={touched.email}
                value={values.email}
                Component={Input}
                componentAdditionalProps={{ placeholder: t('common:contact.placeholders.email') }}
              />
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={24}>
              <FormItem
                className={classes.fullWidth}
                name="message"
                error={errors.message}
                isTouched={touched.message}
                value={values.message}
                Component={TextArea}
                componentAdditionalProps={{ rows: 5, placeholder: t('common:contact.placeholders.message') }}
              />
            </Col>
          </Row>
          <SubmitButton
            className={classes.submitButton}
            size="large"
            disabled={!(isValid && dirty)}
            loading={isSending}
          >
            {t('common:contact.send')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
