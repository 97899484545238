import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export const INITIAL_STATE = {
  userData: null,
  activeList: null,

  isLoggingIn: false,
  authError: null,
  isActivatingAccount: false,
  activationError: null,
  resetPasswordError: null,

  // Sign up
  isSignUpAccount: false,
  signUpError: null,
};

const { name, reducer, ...rest } = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setActiveList: (state, action) => {
      state.activeList = action.payload;
    },
    startSignUp: (state) => {
      state.isSignUpAccount = true;
      state.signUpError = null;
    },
    setSignUpError: (state, action) => {
      state.signUpError = action.payload;
    },
    finishSignUp: (state) => {
      state.isSignUpAccount = false;
    },
    startAuth: (state) => {
      state.isLoggingIn = true;
      state.authError = null;
    },
    finishAuth: (state) => {
      state.isLoggingIn = false;
    },
    setAuthError: (state, action) => {
      state.authError = action.payload;
    },
    removeAuthError: (state) => {
      state.authError = null;
    },
    startActivation: (state) => {
      state.isActivatingAccount = true;
      state.activationError = null;
    },
    finishActivation: (state) => {
      state.isActivatingAccount = false;
    },
    setActivationError: (state, action) => {
      state.activationError = action.payload;
    },
    // reset password
    removeResetPasswordError: (state) => {
      state.resetPasswordError = null;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    wipeUserData: (state) => {
      state.userData = null;
    },
  },
});

const persistConfig = {
  key: name,
  storage,
  whitelist: ['userData', 'activeList', 'isLoggingIn'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default {
  name,
  reducer: persistedReducer,
  ...rest,
};
