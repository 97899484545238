/* eslint-disable max-len */
import React, { FC } from 'react';
import { Input } from 'antd';
import { ReactComponent as ThumbUp } from '../../assets/icons/games/writing/thumbs-up.svg';
import './WritingInput.less';

type InputProps = {
  content: string,
  setFeedbackInfo: any,
  setInputContent: any;
  inputContent: any;
  tryAgainButton: boolean,
  tryAgain: any,
  setNextWordButton: (varName: boolean) => void,
  setTryAgainButton: (varName: boolean) => void,
};

export const WritingInput: FC<InputProps> = ({
  content, setNextWordButton, setTryAgainButton, setFeedbackInfo, tryAgainButton, inputContent, tryAgain, setInputContent,
}) => {
  const showNextWordButton = () => {
    setNextWordButton(true);
  };

  const checkTranslation = (event: { key: string }) => {
    if (inputContent !== '' && event.key === 'Enter') {
      if (inputContent.toLowerCase().replace(/[<>|:;'"`=?,.\\/]+/g, '') === content.toLowerCase()) {
        showNextWordButton();
        setTryAgainButton(false);
        setInputContent('');
        setFeedbackInfo(<ThumbUp />);
      } else {
        setTryAgainButton(true);
        setFeedbackInfo(content);
      }
    }
  };

  const handleKeyUp = (event: { key: string; }) => {
    if (tryAgainButton && event.key === 'Enter' && inputContent === '') {
      setInputContent('');
    } else if (tryAgainButton && event.key === 'Enter') {
      tryAgain();
    }
  };

  return (
    <>
      <div className="inputContainer">

        <Input
          ref={(input) => { if (input != null) { input.focus(); } }}
          size="large"
          type="search"
          value={inputContent}
          placeholder="wpisz tłumaczenie"
          onChange={(event) => setInputContent(event.target.value)}
          onKeyPress={checkTranslation}
          className="input"
          onKeyDown={handleKeyUp}
        />

      </div>
    </>
  );
};

export default WritingInput;
