/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';

type INITIAL_STATE_TYPE = {
  list: any[],
  isLoading: boolean,
  error: string | null,
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  list: [],
  isLoading: false,
  error: null,
};

const { name, reducer, ...rest } = createSlice({
  name: 'student-words-lists',
  initialState: INITIAL_STATE,
  reducers: {
    resetData: (state) => {
      state.list = [];
    },
    addWordsListToList: (state, action) => {
      state.list = [
        action.payload,
        ...state.list,
      ];
    },
    startLoadingList: (state) => {
      state.isLoading = true;
    },
    setPageData: (state, action: { payload: { records: any[] } }) => {
      state.list.push(...action.payload.records);
    },
    finishLoadingList: (state) => {
      state.isLoading = false;
    },
    setLoadingListError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default {
  name,
  reducer,
  ...rest,
};
