import React, {
  FC, useEffect,
} from 'react';
import '../../views/pairing/slice/slice';
import './CardToPair.less';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

type CardProps = {
  id: number,
  content: string,
  itemDone?: any,
};

export const CardToPair: FC<CardProps> = function CardToPair({
  content, id, itemDone,
}:CardProps) {
  const cardId = `card-${id}`;

  useEffect(() => {
    const handleDragEnd = (event: MouseEvent) => {
      const draggableElement = event.target as HTMLElement;
      const pictureElement = document.getElementById(`picture-${id}`);

      if (pictureElement) {
        const cardRect = draggableElement.getBoundingClientRect();
        const pictureRect = pictureElement.getBoundingClientRect();

        if (
          cardRect.left < pictureRect.right
          && cardRect.right > pictureRect.left
          && cardRect.top < pictureRect.bottom
          && cardRect.bottom > pictureRect.top
        ) {
          pictureElement.style.border = '5px solid green';
          draggableElement.style.border = '5px solid green';

          setTimeout(() => {
            pictureElement.style.transform = 'scale(0.001)';
            pictureElement.style.transition = 'scale, 0.5s ease';
          }, 200);

          setTimeout(() => {
            pictureElement.style.display = 'none';
            draggableElement.style.display = 'none';
            itemDone(id);
          }, 500);
        }
      }
    };

    Draggable.create(`#${cardId}`, {
      type: 'x,y',
      onDragEnd: handleDragEnd,
    });
  }, [cardId, id, itemDone]);

  return (
    <>
      <div
        className="cardtopair"
        id={cardId}
        key={id}
      >
        {content}
      </div>
    </>
  );
};

export default CardToPair;

