/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, {
  FC, useState, useCallback, useRef, memo,
} from 'react';
import { Form } from 'formik-antd';
import { Tooltip, Input as AntInput } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FormikErrors, FormikTouched } from 'formik';
import FormItemLabel from '../FormItemLabel';
import classes from './FormItem.module.less';
import { Input } from '../Input';

const { Item } = Form;

type FormItemProps = {
  name: string,
  placeholderKey?: string,
  labelKey?: string,
  disabled?: boolean;
  tooltipKey?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  Component?: (props: any) => JSX.Element | null;
  componentAdditionalProps?: object;
  optional?: boolean;
  isTouched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  value: string | number | null | undefined;
  validate?: (value: string) => string | undefined;
  className?: string;
  hidden?: boolean;
  style?: any;
};

const FormItem: FC<FormItemProps> = ({
  name,
  placeholderKey,
  labelKey,
  disabled,
  tooltipKey,
  Component,
  componentAdditionalProps,
  optional,
  error,
  isTouched,
  value,
  validate,
  className,
  hidden,
}) => {
  const Comp = Component || Input;
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const ref = useRef<AntInput>(null);

  const inactivate = useCallback(() => {
    setIsActive(false);
  }, []);

  const activate = useCallback(() => {
    if (!isActive) {
      setIsActive(true);

      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [isActive]);

  const empty = isEmpty(value && value.toString().trim());

  return (
    <Item
      className={classNames([className, classes.itemContainer, 'label'])}
      validate={validate}
      name={name}
      hidden={hidden}
      label={labelKey ? (
        <FormItemLabel
          translationKey={labelKey}
          isActive={isActive}
          disabled={disabled}
          optional={Boolean(labelKey) && optional}
          hasError={Boolean(error) && (Boolean(isTouched) || (isActive && !empty))}
        />
      ) : null}
      colon={false}
    >
      <Tooltip title={tooltipKey && t(tooltipKey)}>
        <Comp
          ref={ref}
          onFocus={activate}
          onBlur={inactivate}
          name={name}
          placeholder={placeholderKey && t(placeholderKey)}
          disabled={disabled}
          data-cy={name}
          className={classes.comp}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...componentAdditionalProps}
        />
      </Tooltip>
    </Item>
  );
};

export default memo(FormItem);
