import React, { FC } from 'react';
import '../../views/writing/slice/slice';

type CardProps = {
  id: any,
  content: string,
};

export const WordFromList: FC<CardProps> = ({
  content, id,
}) => (
  <>
    <div
      className="word"
      id={id}
    >
      {content}
    </div>
  </>
);

export default WordFromList;
