import React, { FC } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { SubmitButton, Form, Input } from 'formik-antd';
import { Divider, Button } from 'antd';
import { useSelector } from 'react-redux';
import { LockOutlined } from '@ant-design/icons';
import zxcvbn from 'zxcvbn';
import getValidationSchema, {
  INITIAL_VALUES,
  Values,
} from './SignUpForm.schema';
import './SignUpForm.css';
import classes from './SignUpForm.module.less';
import FormItem from '../../../../../components/FormItem';
import ErrorMessage from '../../../../../components/ErrorMessage/ErrorMessage';
import useGoToRoute from '../../../../../hooks/useGoToRoute';
import { Path } from '../../../../../constants/Path';
import { getIsSignUp, getSignUpError } from '../../../slice/selectors';
import PasswordStrengthMeter from '../../../../../components/PasswordStrengthMeter';

type LoginFormProps = {
  onSubmit: (values: Values) => void;
};

const SignUpForm: FC<LoginFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const isAuthenticating = useSelector(getIsSignUp);
  const validationSchema = getValidationSchema(t);
  const goToLogin = useGoToRoute(Path.LOGIN);
  const authError = useSelector(getSignUpError);

  const validatePasswordStrength = (password: string): string | undefined => {
    const { score } = zxcvbn(password);

    return score < 2 ? t('common:validation.password.tooWeak') : undefined;
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values }) => (
        <Form className={classes.form} layout="vertical" data-cy="loginForm" id="login-form">
          {authError && (
            <ErrorMessage>
              {t('common:signUp.labels.error')}
            </ErrorMessage>
          )}
          <FormItem
            name="name"
            labelKey="common:signUp.labels.name"
            placeholderKey="common:signUp.placeholder.name"
            error={errors.name}
            isTouched={touched.name}
            value={values.name}
            disabled={isAuthenticating}
          />
          <FormItem
            name="surname"
            labelKey="common:signUp.labels.surname"
            placeholderKey="common:signUp.placeholder.surname"
            error={errors.surname}
            isTouched={touched.surname}
            value={values.surname}
            disabled={isAuthenticating}
          />
          <FormItem
            name="email"
            labelKey="common:signUp.labels.email"
            placeholderKey="common:signUp.placeholder.email"
            error={errors.email}
            isTouched={touched.email}
            value={values.email}
            disabled={isAuthenticating}
          />
          <Form.Item label={t('common:signUp.labels.password')} name="password" validate={validatePasswordStrength}>
            <Input.Password
              name="password"
              placeholder={t('common:signUp.placeholder.password')}
              prefix={<LockOutlined />}
            />
          </Form.Item>
          <PasswordStrengthMeter password={values.password} />

          <SubmitButton
            disabled={isAuthenticating}
            loading={isAuthenticating}
            data-cy="submitButton"
            size="large"
            block
          >
            {t('common:signUp.labels.submit')}
          </SubmitButton>
          <Divider plain>{t('common:or')}</Divider>
          <Button
            disabled={isAuthenticating}
            loading={isAuthenticating}
            data-cy="signUpButton"
            size="large"
            block
            onClick={goToLogin}
          >
            {t('common:signUp.labels.back')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
