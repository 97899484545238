/* eslint-disable max-len */
// eslint-disable-next-line react/react-in-jsx-scope
import React from 'react';

const RulesOfTheGame = () => (
  <>
    <p style={{ color: 'darkslategray' }}>
      <b>Moduł ZNAJDŹ: </b>
      <li>Rozpoznaj po wymowie, które słowo z listy zostało odczytane. Jeśli nie usłyszałeś uruchom wymowę przyciskiem SOUND. </li>
      <li>Kliknij na grafikę/zdjęcie, które odpowiada temu słowu. </li>
      <li>Jeśli wybór był poprawny, zostanie pokazane kolejne słowo. Jeśli nie, ponów próbę. </li>
      <li> INFO: Błąd powoduje ponowne wyświetlenie danego słowa na koniec gry. </li>
    </p>
  </>
);

export default RulesOfTheGame;
