import React, { FC } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { SubmitButton, Form, Input } from 'formik-antd';
import { Divider, Button } from 'antd';
import classNames from 'classnames';
import getValidationSchema, {
  INITIAL_VALUES,
  Values,
} from './LoginForm.schema';
import './LoginForm.css';
import Text from '../../../../../components/Text';
import classes from './LoginForm.module.less';
import FormItem from '../../../../../components/FormItem';
import ErrorMessage from '../../../../../components/ErrorMessage/ErrorMessage';
import useGoToRoute from '../../../../../hooks/useGoToRoute';
import { Path } from '../../../../../constants/Path';

type LoginFormProps = {
  onSubmit: (values: Values) => void;
};

const LoginForm: FC<LoginFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const isAuthenticating = false;
  const validationSchema = getValidationSchema(t);
  const goToForgotPassword = useGoToRoute(Path.RESET_PASSWORD);
  const goToSignUp = useGoToRoute(Path.SIGN_UP);
  const authError = null;

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values }) => (
        <Form className={classes.form} layout="vertical" data-cy="loginForm" id="login-form">
          {authError && (
            <ErrorMessage>
              {t('common:login.labels.error')}
            </ErrorMessage>
          )}
          <FormItem
            name="email"
            labelKey="common:login.labels.email"
            error={errors.email}
            isTouched={touched.email}
            value={values.email}
          />
          <FormItem
            className={classes.passwordField}
            name="password"
            labelKey="common:login.labels.password"
            error={errors.password}
            Component={Input.Password}
            isTouched={touched.password}
            value={values.password}
          />
          <div
            tabIndex={0}
            role="button"
            onClick={goToForgotPassword}
            onKeyPress={goToForgotPassword}
            className={classNames([
              classes.forgotPasswordContainer,
              classes.row,
            ])}
          >
            <Text style={{ color: 'lightgrey', fontSize: '0.8rem' }}>{t('common:login.labels.forgotPassword')}</Text>
          </div>
          <SubmitButton
            disabled={false}
            loading={isAuthenticating}
            data-cy="submitButton"
            size="large"
            block
            style={{ borderRadius: '6px' }}
          >
            {t('common:login.labels.submit')}
          </SubmitButton>
          <Divider plain style={{ color: 'lightgrey' }}>{t('common:or')}</Divider>
          <Button
            disabled={false}
            loading={isAuthenticating}
            data-cy="signUpButton"
            size="large"
            block
            onClick={goToSignUp}
            style={{ borderRadius: '6px' }}
          >
            {t('common:login.labels.signUp')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
