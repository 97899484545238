import React, { FC, useCallback } from 'react';
import { Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './AddStudentModal.styles';
import classes from './AddStudentModal.module.less';
import closeIcon from '../../../assets/icons/x.svg';
import StudentForm from '../../../components/StudentForm';
import { UserData } from '../../../services/ApiService.types';
import { success, error } from '../../../components/Message';
import { addStudent, setAddingStudentError } from '../slice/actions';
import useActionStatus from '../../../hooks/useActionStatus';
import { getIsAddingError, getIsAdding } from '../slice/selectors';
import ErrorMessage from '../../../components/ErrorMessage';

type AddStudentModalProps = {
  isVisible: boolean;
  hideModal: () => void;
};

const { Title } = Typography;

const AddStudentModal: FC<AddStudentModalProps> = ({ isVisible, hideModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsAdding);
  const apiError = useSelector(getIsAddingError);

  const onSubmit = useCallback((values: UserData) => {
    dispatch(addStudent(values));
  }, [dispatch]);

  const cleanupError = useCallback(() => {
    dispatch(setAddingStudentError(null));
  }, [dispatch]);

  const onCancel = useCallback(() => {
    hideModal();
    cleanupError();
  }, [hideModal, cleanupError]);

  const onSuccess = useCallback(() => {
    success(t('student:add.messages.success'));
    hideModal();
  }, [hideModal, t]);

  const onError = useCallback(() => {
    error(t('student:add.messages.error'));
  }, [t]);

  useActionStatus(onSuccess, onError, {
    isLoadingSelector: getIsAdding,
    errorSelector: getIsAddingError,
  });

  return (
    <Modal
      bodyStyle={styles.modalBodyStyle}
      footer={null}
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      centered
      closeIcon={<img src={closeIcon} alt="Close" />}
    >
      <Title className={classes.title} style={{ color: 'gray', fontWeight: 'bolder' }}>{t('student:add.title')}</Title>
      {apiError && <ErrorMessage>{apiError.message}</ErrorMessage>}
      <StudentForm isLoading={isLoading} onSubmit={onSubmit} />
    </Modal>
  );
};

export default AddStudentModal;
