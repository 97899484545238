/* eslint-disable max-len */
// eslint-disable-next-line react/react-in-jsx-scope
import React from 'react';

const RulesOfTheGame = () => (
  <>
    <p style={{ color: 'darkslategray', margin: '0' }}>
      <b> Moduł NAPISZ: </b>
      <li>Ostatnie, najbardziej angażujące zadanie: wpisz tłumaczenie widocznego słowa w języku obcym. Naciśnij ENTER, aby zatwierdzić. Jeżeli zrobisz to poprawnie, pojawi się kolejne słowo.</li>
      <li>Błąd spowoduje pojawienie się podpowiedzi i przycisku CLEAR. Skorzystaj z podpowiedzi i naciśnij ENTER (lub kliknij CLEAR), aby podjąć kolejną próbę.   </li>
      <li>Poprawne wpisanie kolejnych słów z listy oznacza, że lista opanowana. CONGRATS!!!  </li>
      <li>INFO: Pamiętaj, że arkusz jest wrażliwy na znaki specjalne np.spację, którą łatwo wpisać na koniec słowa przez nieuwagę przy tworzeniu listy. Więc jeśli jesteś pewien że wpisujesz poprawnie, a wciąż nie akceptuje, tu może być przyczyna. </li>
      <li>TIP: Ostatecznie jeśli kwestia dotyczy jedynie literówki lub innego niewielkiego błędu możesz użyć przycisku NEXT (oczywiście radzimy nie nadużywać tej funkcji, aby nie zmniejszyć efektywności gry). </li>
    </p>
  </>
);

export default RulesOfTheGame;
