import { AxiosError } from 'axios';
import studentSlice from './slice';
import API from '../../../services/ApiService';
import { AppThunk } from '../../../redux/store';
import {
  ApiError, UserData,
} from '../../../services/ApiService.types';

const {
  startLoadingList, finishLoadingList, setLoadingListError, setPageData,
  startAddingStudent, setAddingStudentError, finishAddingStudent, addStudentToList,
  startDeletingStudent, finishDeletingStudent, setDeletingStudentError, removeStudent,
  startEditingStudent, finishEditingStudent, setEditingStudentError, replaceStudent,
  resetData,
} = studentSlice.actions;

const fetchStudentsPage = (): AppThunk => (
  async (dispatch) => {
    dispatch(startLoadingList());
    try {
      const { data: { records } } = await API.students.list();

      dispatch(setPageData({ records }));
    } catch (e) {
      const { response } = e as AxiosError<ApiError>;
      dispatch(setLoadingListError(response && response.data));
    } finally {
      dispatch(finishLoadingList());
    }
  });

const addStudent = (userData: UserData): AppThunk => async (dispatch) => {
  dispatch(startAddingStudent());
  try {
    const { data: student } = await API.students.create(userData);
    dispatch(addStudentToList(student));
  } catch (e) {
    const { response } = e as AxiosError<ApiError>;
    dispatch(setAddingStudentError(response && response.data));
  } finally {
    dispatch(finishAddingStudent());
  }
};

const deleteStudent = (studentId: number): AppThunk => async (dispatch) => {
  dispatch(startDeletingStudent());
  try {
    await API.students.delete(studentId);
    dispatch(removeStudent(studentId));
  } catch (e) {
    const { response } = e as AxiosError<ApiError>;
    dispatch(setDeletingStudentError(response && response.data));
  } finally {
    dispatch(finishDeletingStudent());
  }
};

const editStudent = (studentId: number, userData: UserData): AppThunk => (
  async (dispatch) => {
    dispatch(startEditingStudent());
    try {
      const { data: student } = await API.students.edit(studentId, userData);
      dispatch(replaceStudent(student));
    } catch (e) {
      const { response } = e as AxiosError<ApiError>;
      dispatch(setEditingStudentError(response && response.data));
    } finally {
      dispatch(finishEditingStudent());
    }
  });

export {
  resetData,
  fetchStudentsPage,
  setAddingStudentError,
  addStudent,
  setDeletingStudentError,
  deleteStudent,
  setEditingStudentError,
  editStudent,
};
