/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';

type INITIAL_STATE_TYPE = {
  wordsList: { id: number, name: string, words: any[] } | null,
  isLoading: boolean,
  error: string | null,
  isSaving: boolean,
  savingError: string | null,
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  wordsList: null,
  isLoading: true,
  error: null,
  isSaving: false,
  savingError: null,
};

// const wordSlice = createSlice({
const { name, reducer, ...rest } = createSlice({
  name: 'words-list',
  initialState: INITIAL_STATE,
  reducers: {
    startSaving: (state) => {
      state.isSaving = true;
    },
    finishSaving: (state) => {
      state.isSaving = false;
    },
    setSavingError: (state, action) => {
      state.savingError = action.payload;
    },
    startLoadingData: (state) => {
      state.isLoading = true;
    },
    setData: (state, action: { payload: { data: { id: number, name: string, words: any[] } } }) => {
      state.wordsList = action.payload.data;
    },
    finishLoadingData: (state) => {
      state.isLoading = false;
    },
    setLoadingDataError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default {
  name,
  reducer,
  ...rest,
};
