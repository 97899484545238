import { AxiosError } from 'axios';
import slice from './slice';
import history from '../../../getBrowserHistory';
import { ApiError } from '../../../services/ApiService.types';
import { AppThunk } from '../../../redux/store';
import API from '../../../services/ApiService';
import { getWordsListLink } from '../../../constants/Path';

const {
  addWordsListToList, finishAddingWordsList, startAddingWordsList, setAddingWordsListError,
  startLoadingList, setPageData, setLoadingListError, finishLoadingList,
  finishDeletingWordsList, setDeletingWordsListError, startDeletingWordsList, removeWordsList,

  startSharingWordsList,
  stopSharingWordsList,
  finishSharingWordsList,
  setSharingWordsListError,
  resetData,

  setAccessToWordListByCombinedId,
  removeAccessForStudent,
} = slice.actions;

const fetchWordsListPage = (): AppThunk => (
  async (dispatch) => {
    dispatch(startLoadingList());
    try {
      const { data: { records } } = await API.wordsList.list();

      dispatch(setPageData({ records }));
    } catch (e) {
      const { response } = e as AxiosError<ApiError>;
      dispatch(setLoadingListError(response && response.data));
    } finally {
      dispatch(finishLoadingList());
    }
  });

const deleteWordsList = (wordsListId: number): AppThunk => async (dispatch) => {
  dispatch(startDeletingWordsList());
  try {
    await API.wordsList.delete(wordsListId);
    dispatch(removeWordsList(wordsListId));
  } catch (e) {
    const { response } = e as AxiosError<ApiError>;
    dispatch(setDeletingWordsListError(response && response.data));
  } finally {
    dispatch(finishDeletingWordsList());
  }
};

const shareWordsList = (wordsListId: number, combinedId: string): AppThunk => async (dispatch) => {
  dispatch(startSharingWordsList(wordsListId));
  try {
    await API.wordsList.share(wordsListId, combinedId);
  } catch (e) {
    const { response } = e as AxiosError<ApiError>;
    dispatch(setSharingWordsListError(response && response.data));
  } finally {
    dispatch(finishSharingWordsList(wordsListId));
  }
};

const addWordsList = (wordsList: { name: string }): AppThunk => async (dispatch) => {
  dispatch(startAddingWordsList());
  try {
    const { data: list } = await API.wordsList.create(wordsList);
    dispatch(addWordsListToList(list));
    history.push(getWordsListLink(list.id));
  } catch (e) {
    const { response } = e as AxiosError<ApiError>;
    dispatch(setAddingWordsListError(response && response.data));
  } finally {
    dispatch(finishAddingWordsList());
  }
};

export {
  resetData,
  fetchWordsListPage,
  deleteWordsList,
  setDeletingWordsListError,
  addWordsList,
  setAddingWordsListError,

  shareWordsList,
  startSharingWordsList,
  finishSharingWordsList,
  setSharingWordsListError,
  stopSharingWordsList,

  setAccessToWordListByCombinedId,
  removeAccessForStudent,
};
