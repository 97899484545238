import { createSlice } from '@reduxjs/toolkit';

// eslint-disable-next-line @typescript-eslint/naming-convention
type INITIAL_STATE_TYPE = {
  cardsInGame?: string[],
  actualMatched?: string[],
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  cardsInGame: [],
  actualMatched: [],
};

const CLEARactualMatch = (state: any) => {
  const { actualMatched } = state.actualMatched;
  state.actualMatched = [];
  state.cardsInGame = state.cardsInGame.map((card: { id: string; isFlipped: boolean; }) => {
    if (actualMatched.find((matchedCard: { id: string; }) => matchedCard.id === card.id)) {
      card.isFlipped = false;
    }

    return card;
  });
};

const memorySlice:any = createSlice({
  name: 'memory',
  initialState: INITIAL_STATE,
  reducers: {
    saveCards: (state:any, action) => {
      if (state.cardsInGame! && state.cardsInGame.length < 24) {
        CLEARactualMatch(state);
        state.cardsInGame.push(action.payload[0]);
        state.cardsInGame.push(action.payload[1]);
        state.cardsInGame.sort(() => 0.5 - Math.random());
      }
    },

    flipCardAndMatch: (state:any, action) => {
      if (state.actualMatched! && state.actualMatched.length === 2) {
        CLEARactualMatch(state);
      }

      state.cardsInGame = state.cardsInGame!.map((card: { id: any; isFlipped: any; }) => {
        if (card.id === action.payload.id) {
          card.isFlipped = action.payload.isFlipped;
          state.actualMatched!.push(card);
        }

        return card;
      });

      if (state.actualMatched.length === 2
        && state.actualMatched[0].value === state.actualMatched![1].value) {
        state.actualMatched = [];
      }
    },

    clearActualMatch: (state) => {
      CLEARactualMatch(state);
    },

    newGame: (state) => {
      state.cardsInGame = [];
    },

    removeCard: (state:any) => {
      state.cardsInGame = state.cardsInGame.forEach(() => null);
    },

    repeatGame: (state:any) => {
      state.cardsInGame.sort(() => 0.5 - Math.random());
      state.cardsInGame = state.cardsInGame.map((card: { isFlipped: boolean; }) => {
        card.isFlipped = false;

        return card;
      });
    },
  },
});

export const {
  saveCards, flipCardAndMatch, clearActualMatch, newGame, repeatGame, removeCard,
} = memorySlice.actions;

export default memorySlice.reducer;
