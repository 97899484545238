import React, { FC, useCallback, useEffect } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import withTagManager from '../../hooks/withTagManager';
import styles from './StudentWordsListsView.module.less';
import {
  getIsLoadingWordsListList,
  getStudentListOfWordsList,
} from './slice/selectors';
import { fetchStudentWordsListPage, resetData } from './slice/actions';
import ListCard from '../../components/ListCard';
import { getUserData } from '../auth/slice/selectors';

const StudentWordsListsView: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getIsLoadingWordsListList);
  const lists = useSelector(getStudentListOfWordsList);
  const user = useSelector(getUserData);

  const filteredLists = lists.filter((list: any) => list.combinedIds.some((combinedId: string) => {
    const userIdString = user.id.toString();
    const combinedIdToMatch = list.id.toString() + userIdString;

    return (
      combinedId.length === combinedIdToMatch.length
        && combinedId.startsWith(userIdString)
        && combinedId.endsWith(list.id.toString())
    );
  }));

  const loadMoreData = useCallback(() => {
    if (loading) {
      return;
    }
    dispatch(fetchStudentWordsListPage());
  }, [dispatch, loading]);

  useEffect(() => {
    dispatch(resetData());
    loadMoreData();
  }, []);

  return (
    <Spin spinning={loading} wrapperClassName={styles.spin}>
      {filteredLists.length > 0 ? (
        <div className={styles.cardsLayout}>
          {filteredLists.map((list: any) => (
            <ListCard list={list} key={nanoid()} />
          ))}
        </div>
      ) : (
        <p style={{ color: 'white' }}>Brak dostępnych list do wyświetlenia.</p>
      )}
    </Spin>
  );
};

export default withTagManager(StudentWordsListsView);
