import React from 'react';
import { Switch } from 'react-router-dom';
import './App.less';
import NotFoundRoute from './routes/NotFoundRoute';
import PublicRoutes from './routes/PublicRoutes';
import ProtectedRoutes from './routes/ProtectedRoutes';

function Routes() {
  return (
    <Switch>
      {ProtectedRoutes}
      {PublicRoutes}
      {NotFoundRoute}
    </Switch>
  );
}

export default Routes;
