/* eslint-disable max-len */
import React, { FC } from 'react';
import '../../views/writing/slice/slice';
import { WritingInput } from './WritingInput';

type CardProps = {
  id: any,
  content: string,
  translation: string,
  setNextWordButton: any,
  setFeedbackInfo: any,
  setTryAgainButton: any;
  setInputContent: any;
  inputContent: any;
  tryAgainButton: boolean;
  tryAgain: any;
};

export const WordFromList: FC<CardProps> = ({
  content, id, translation,
  setInputContent, inputContent,
  tryAgainButton, tryAgain,
  setNextWordButton,
  setFeedbackInfo,
  setTryAgainButton,
}) => (
  <>
    <div
      className="word"
      id={id}
    >
      {translation}
    </div>
    <div>
      <WritingInput
        content={content}
        setNextWordButton={setNextWordButton}
        setFeedbackInfo={setFeedbackInfo}
        setTryAgainButton={setTryAgainButton}
        setInputContent={setInputContent}
        tryAgainButton={tryAgainButton}
        tryAgain={tryAgain}
        inputContent={inputContent}
      />
    </div>
  </>
);

export default WordFromList;
