import { Selector, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Nullable } from '../types';

type AppSelector = Selector<any, any>;

type ActionStatusSelectors = {
  isLoadingSelector: AppSelector;
  errorSelector: AppSelector;
};

type OnSuccess = Nullable<() => void>;
type OnError = Nullable<(error: any) => void>;

type UseActionStatus = (
  onSuccess: OnSuccess,
  onError: OnError,
  { isLoadingSelector, errorSelector }: ActionStatusSelectors
) => void;

const useActionStatus: UseActionStatus = (
  onSuccess, onError, { isLoadingSelector, errorSelector },
) => {
  const isLoading = useSelector(isLoadingSelector);
  const error = useSelector(errorSelector);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    if (isLoading && !hasStarted) {
      setHasStarted(true);
    } else if (hasStarted && !isLoading) {
      setHasStarted(false);
      if (error && onError) {
        onError(error);
      } else if (!error && onSuccess) {
        onSuccess();
      }
    }
  }, [hasStarted, isLoading, error, onSuccess, onError]);
};

export default useActionStatus;
