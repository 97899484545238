import React, { FC, useEffect } from 'react';
import './Picture.less';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

type PictureProps = {
  id: string;
  link: string;
  translation: string;
};

export const Picture: FC<PictureProps> = function Picture({
  id, link, translation,
}: any) {
  const pictureId = `picture-${id}`;

  // useEffect(() => {
  //   if (!Draggable.get(`#${pictureId}`)) {
  //     Draggable.create(`#${pictureId}`, {
  //       type: 'x,y',
  //       bounds: '.enter',
  //     });
  //   }
  // }, [pictureId]);
  useEffect(() => {
    const pictureElement = document.getElementById(pictureId);

    if (pictureElement && !Draggable.get(`#${pictureId}`)) {
      const maxX = window.innerWidth - 200 - pictureElement.offsetWidth;
      const maxY = window.innerHeight - 200 - pictureElement.offsetHeight;

      const randomX = Math.random() * maxX;
      const randomY = Math.random() * maxY;

      pictureElement.style.position = 'absolute';
      pictureElement.style.left = `${randomX}px`;
      pictureElement.style.top = `${randomY}px`;

      Draggable.create(`#${pictureId}`, {
        type: 'x,y',
        bounds: '.boardForPics',
        throwProps: true,
      });
    }
  }, [pictureId]);

  return (
    <>
      <div
        id={pictureId}
        className="picture"
      >
        <img alt={translation} src={link} />
      </div>
    </>
  );
};

