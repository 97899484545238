import React, { FC } from 'react';
import {
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import FixWidthContent from '../../components/FixWidthContent';
import classes from './PrivacyPolicyView.module.less';
import withTagManager from '../../hooks/withTagManager';

const PrivacyPolicyView: FC = () => {
  const { t } = useTranslation();

  return (
    <FixWidthContent>
      <Col span={24}>
        <h1 className={classes.title}>{t('common:privacyPolicy.title')}</h1>
        <div className={classes.subContent}>
          Polityka prywatności
        </div>
      </Col>
    </FixWidthContent>
  );
};

export default withTagManager(PrivacyPolicyView);
