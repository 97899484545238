/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, {
  FC, useState,
} from 'react';
import './InfoButton.less';

interface InfoButtonProps {
  content: JSX.Element;
}

const InfoButton: FC<InfoButtonProps> = ({ content }) => {
  const [showRules, setShowRules] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setShowRules((current) => !current);
    setIsActive((current) => !current);
  };

  return (
    <div className="info">

      <button
        type="button"
        className={isActive ? 'active' : ''}
        onClick={handleClick}
        id="infoButton"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="orange" viewBox="0 0 24 24">
          <path fillRule="evenodd" d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25zm.75 9.75a.75.75 0 00-1.5 0v5a.75.75 0 001.5 0v-5zM13 8a1 1 0 11-2 0 1 1 0 012 0z" />
        </svg>
      </button>

      {showRules && (
        <div
          className="rules"
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyPress={handleClick}
        >
          { content }
        </div>
      )}

    </div>
  );
};

export default InfoButton;
