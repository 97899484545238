/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Checkbox, List } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListOfStudents,
  getIsLoadingStudentsList,
} from '../../student/slice/selectors';
import { fetchStudentsPage } from '../../student/slice/actions';
import { UserData } from '../../../services/ApiService.types';
import classes from './ShareWordsListModal.module.less';

import {
  shareWordsList,
} from '../slice/actions';

const ListOfStudentsInModal: React.FC<{ wordsList: { id: number, name: string, combinedIds: string[] } }> = ({ wordsList }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getIsLoadingStudentsList);
  const currentList = useSelector(getListOfStudents);
  const [stateOfListsGranted, setStateOfListsGranted] = useState<string[]>(wordsList.combinedIds);

  const handleCheckboxChange = useCallback((e: CheckboxChangeEvent) => {
    const studentId = e.target.value;
    const checkedWordlistId = wordsList.id;
    const combinedId: string = `${studentId}${checkedWordlistId}`;

    if (wordsList && wordsList.id) {
      if (stateOfListsGranted.includes(combinedId)) {
        setStateOfListsGranted(() => stateOfListsGranted.filter((id) => id !== combinedId));
      } else {
        setStateOfListsGranted(() => [...stateOfListsGranted, combinedId]);
      }

      dispatch(shareWordsList(wordsList.id, combinedId));
    }
  }, [dispatch, stateOfListsGranted, wordsList]);

  // eliminacja akumulacji listy
  const lists = currentList.reduce((acc: UserData[], item: UserData) => {
    if (!acc.find((i) => i.id === item.id)) {
      acc.push(item);
    }

    return acc;
  }, []);

  const loadMoreData = useCallback(() => {
    if (loading) {
      return;
    }
    dispatch(fetchStudentsPage());
  }, [dispatch, loading]);

  useEffect(() => {
    dispatch(fetchStudentsPage());
    loadMoreData();
  }, []);

  return (
    <div
      id="scrollableDiv"
      style={{
        height: 'fitContent',
        overflow: 'auto',
        padding: '0 10px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
        width: '100%',
      }}
    >
      <InfiniteScroll
        dataLength={lists.length}
        next={loadMoreData}
        hasMore={lists.length < 50}
        loader={null}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={lists}
          renderItem={(item: UserData) => (
            <List.Item
              key={item.id}
              tabIndex={0}
              role="button"
              className={classes.listItem}
            >
              <List.Item.Meta
                avatar={(
                  <Avatar>
                    {item.name[0].toUpperCase()}
                    {item.surname[0].toUpperCase()}
                  </Avatar>
                  )}
                title={(
                  <span style={{ color: 'darkred', fontSize: 'larger' }}>
                    {item.name} {item.surname}
                  </span>
                  )}
                description={(
                  <span style={{ fontSize: 'smaller' }}>
                    {item.email}
                  </span>
                  )}
              />
              <Checkbox
                onChange={handleCheckboxChange}
                checked={stateOfListsGranted.includes(`${item.id}${wordsList.id}`)}
                value={item.id}
                key={item.id}
                className={classes.mark}
              />
            </List.Item>
          )}
        />
      </InfiniteScroll>

    </div>
  );
};

export default ListOfStudentsInModal;

