/* eslint-disable react/require-default-props */
import React from 'react';
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { getValidationSchema, INITIAL_VALUES } from './StudentForm.schema';
import './StudentForm.less';
import FormItem from '../FormItem';
import { UserData } from '../../services/ApiService.types';

type StudentFormProps = {
  onSubmit: (values: UserData) => void;
  isLoading: boolean;
  student?: UserData;
};

const StudentForm = ({ onSubmit, isLoading, student }: StudentFormProps) => {
  const { t } = useTranslation();
  const validationSchema = getValidationSchema(t);
  const initialValues = student ? {
    name: student.name,
    surname: student.surname,
    email: student.email,
  } : INITIAL_VALUES;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values }) => (
        <Form id="studentForm">
          <FormItem
            name="name"
            labelKey="student:studentForm.labels.name"
            error={errors.name}
            isTouched={touched.name}
            value={values.name}
          />
          <FormItem
            name="surname"
            labelKey="student:studentForm.labels.surname"
            error={errors.surname}
            isTouched={touched.surname}
            value={values.surname}
          />
          <FormItem
            name="email"
            labelKey="student:studentForm.labels.email"
            error={errors.email}
            isTouched={touched.email}
            value={values.email}
          />

          <SubmitButton size="large" loading={isLoading} block style={{ borderRadius: '8px' }}>
            {t('student:studentForm.labels.submit')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default StudentForm;
