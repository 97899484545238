/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { nanoid } from 'nanoid';
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Card, Drawer, Input, Spin, Upload,
} from 'antd';
import { DownloadOutlined, FileImageOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ReactComponent as Thrash } from '../../assets/icons/teacher/trash.svg';
import API from '../../services/ApiService';
import classes from './WordsListView.module.less';
import styles from './WordsListView.styles';
import withTagManager from '../../hooks/withTagManager';
import FormItem from '../../components/FormItem/FormItem';
import { INITIAL_VALUES, getValidationSchema } from './WordsList.schema';
import {
  getIsLoadingWordsListData, getIsSaving, getIsSavingError, getWordsListData,
} from './slice/selectors';
import { fetchWordsListData, saveWordList } from './slice/actions';
import { error, success } from '../../components/Message';
import useActionStatus from '../../hooks/useActionStatus';
import { getActiveList } from '../auth/slice/selectors';
import { setActiveList } from '../auth/slice/actions';

const WordsListView: FC = () => {
  const { id } = useParams<{ id?: string | undefined }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [words, setWords] = useState<any[]>([]);
  const [selectedWordId, setSelectedWordId] = useState<string | null>(null);
  const activeList = useSelector(getActiveList);

  const [clientId] = useState(`${process.env.REACT_APP_API_UNSPLASH_KEY}`);
  const [photoResults, setPhotoResults] = useState([]);

  const loading = useSelector(getIsLoadingWordsListData);
  const wordsList = useSelector(getWordsListData);

  const [isInputDrawerVisible, setInputDrawerVisible] = useState<boolean>(false);
  const [currentTerm, setCurrentTerm] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');

  const validationSchema = getValidationSchema(t);
  const initialValues: { name: string, description: string, combinedId: string, words: any[] } = wordsList
    ? {
      name: wordsList.name,
      description: wordsList.description,
      combinedId: wordsList.combinedId,
      words: wordsList.words || [],
    } : INITIAL_VALUES;

  const loadData = useCallback(() => {
    if (id) {
      dispatch(fetchWordsListData(id));
    }
  }, [id, dispatch]);

  const onSubmit = useCallback((values: { name: string, description: string, combinedId: string, words: any[] }) => {
    if (id) {
      dispatch(saveWordList(id, values));
      if (activeList) {
        if (activeList.words !== values.words) {
          const updatedActiveList = { ...activeList, words: values.words };
          dispatch(setActiveList(updatedActiveList));
        }
      }
    }
  }, [activeList, dispatch, id]);

  const onClose = () => {
    setSelectedWordId(null);
    setPhotoResults([]);
    setInputDrawerVisible(false);
  };

  const setWordImage = useCallback((values, wordId: string | null, url: string) => {
    values.words = values.words.map((word: any) => {
      if (word.id === wordId) {
        return {
          ...word,
          image: url,
        };
      }

      return word;
    });
    setWords(values.words);
    onClose();
  }, []);

  const addWord = useCallback((values) => {
    const newWords = [
      ...(values.words || []),
      {
        id: nanoid(),
        term: '',
        definition: '',
        image: '',
      },
    ];
    setWords(newWords);
    values.words = newWords;
  }, [words]);

  const removeWord = useCallback((wordId, values) => {
    const newWords = (values.words || []).filter((word: any) => word.id !== wordId);
    setWords(newWords);
    values.words = newWords;
  }, [words]);

  useEffect(() => {
    if (wordsList) {
      setWords(wordsList.words || []);
    }
  }, [wordsList]);

  useEffect(() => {
    loadData();
  }, []);

  const onSuccess = useCallback(() => {
    success(t('words-list:save.messages.success'));
  }, [t]);

  const onError = useCallback(() => {
    error(t('words-list:save.messages.error'));
  }, [t]);

  useActionStatus(onSuccess, onError, {
    isLoadingSelector: getIsSaving,
    errorSelector: getIsSavingError,
  });

  if (loading) {
    return <Spin />;
  }

  const fetchPhotosApi = (term: string) => {
    const perPage = 14;
    const sendQuery:string = `https://api.unsplash.com/search/photos?page=1&query=${term}&client_id=${clientId}&orientation=landscape&per_page=${perPage}`;

    axios.get(sendQuery).then((response) => {
      setPhotoResults(response.data.results);
    });

    setInputDrawerVisible(true);
    setCurrentTerm(term);
  };

  const askAnotherTerm = (newTerm: string) => {
    const perPage = 14;
    const sendQuery:string = `https://api.unsplash.com/search/photos?page=1&query=${newTerm}&client_id=${clientId}&orientation=landscape&per_page=${perPage}`;

    axios.get(sendQuery).then((response) => {
      setPhotoResults(response.data.results);
    });

    setInputDrawerVisible(true);
  };

  const savePhotoFromApi = (photo: any, values: any) => {
    const url = photo.urls.small;
    const wordId = selectedWordId;
    setWordImage(values, wordId, url);
  };

  const showDrawer = (wordsListId: string, values: any) => {
    setSelectedWordId(wordsListId);
    const selectItem = values.words.filter((word: any) => word.id === wordsListId);
    console.log('SelectItem:', selectItem[0].term);

    fetchPhotosApi(selectItem[0].term);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values as any)}
          validationSchema={validationSchema}
        >
          {({ errors, touched, values }) => (
            <Form className={classes.form} layout="vertical" id="words-list">
              <div>
                <Form id="wordsListForm">
                  <FormItem
                    name="name"
                    error={errors.name}
                    isTouched={touched.name}
                    value={values.name}
                    placeholderKey="nazwa listy"
                  />

                  <FormItem
                    name="description"
                    error={errors.description}
                    isTouched={touched.description}
                    value={values.description}
                    placeholderKey="opis listy"
                  />

                  <div className={classes.separatorLine} />

                  {
                    (!words || !words.length) && (
                      <Card style={styles.wordContainer} bordered={false}>
                        <Card.Grid hoverable={false} className={classes.emptyList}>
                          {/* <Card.Grid style={styles.wordsEmpty as any} hoverable={false} className={classes.emptyList}> */}
                          {t('words-list:wordsListForm.info.wordsEmpty')}
                        </Card.Grid>
                      </Card>
                    )
                  }

                  {
                    words && (
                      <>
                        {
                          words.map((word, index) => (
                            <Card
                              key={word.id}
                              style={styles.wordContainer}
                              bodyStyle={styles.wordContainerBody}
                              headStyle={styles.wordContainerHead}
                              title={index + 1}
                              bordered={false}
                              extra={(
                                <Button
                                  onClick={() => removeWord(word.id, values)}
                                  style={{ background: 'transparent', border: 'none' }}
                                  icon={<Thrash style={{ fill: 'red' }} />}
                                />
                              )}
                            >
                              <Card.Grid
                                className={classes.inputs}
                                hoverable={false}
                              >
                                <FormItem
                                  name={`words[${index}].id`}
                                  value={word.term}
                                  hidden
                                />
                                <FormItem
                                  name={`words[${index}].term`}
                                  labelKey="words-list:wordsListForm.labels.term"
                                  placeholderKey="words-list:wordsListForm.labels.term"
                                  value={word.term}
                                />
                              </Card.Grid>
                              <Card.Grid
                                className={classes.inputs}
                                hoverable={false}
                              >
                                <FormItem
                                  name={`words[${index}].definition`}
                                  labelKey="words-list:wordsListForm.labels.definition"
                                  placeholderKey="words-list:wordsListForm.labels.definition"
                                  value={word.definition}
                                />
                              </Card.Grid>
                              <Card.Grid
                                className={classes.pictureWrapper}
                                hoverable={false}
                              >
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => showDrawer(word.id, values)}
                                  onKeyPress={() => showDrawer(word.id, values)}
                                  className={classes.imageButtonContainer}
                                >
                                  {word.image ? (
                                    <img className={classes.img} src={word.image} alt={word.term} />
                                  ) : (
                                    <>
                                      <FileImageOutlined style={{ fontSize: '2em' }} />
                                      <span className={classes.imageButtonTitle}>
                                        {t('words-list:wordsListForm.labels.image')}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </Card.Grid>
                            </Card>
                          ))
                        }
                      </>
                    )
                  }

                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => addWord(values)}
                    onKeyPress={() => addWord(values)}
                    className={classes.addWordButtonContainer}
                  >
                    {t('words-list:button.addWord')}
                  </div>
                </Form>
              </div>

              <div className={classes.action}>
                <SubmitButton
                  type="primary"
                  loading={loading}
                  id={classes.submitt}
                >
                  {t('words-list:button.save')}
                </SubmitButton>
              </div>

              <Drawer
                title={t('words-list:images.select')}
                width="360px"
                bodyStyle={{ backgroundColor: '#002525' }}
                headerStyle={{ backgroundColor: 'lightgray' }}
                placement="right"
                onClose={onClose}
                visible={!!selectedWordId}
              >
                <Upload
                  name="file"
                  action={async (file) => {
                    const data = new FormData();
                    data.set('file', file);
                    if (id) {
                      data.set('wordsListId', id);
                    }

                    const { data: photo } = await API.photos.add(data);

                    if (selectedWordId) {
                      setWordImage(values, selectedWordId, photo.publicUrl);
                    }

                    return photo.publicUrl;
                  }}
                  onChange={(info) => {
                    if (info.file.status === 'error') {
                      error(t('words-list:photo.messages.error'));
                    }
                  }}
                >
                  <Button type="primary" icon={<DownloadOutlined />} size="large" className={classes.loadButton}>
                    {t('words-list:images.loadFromComputer')}
                  </Button>
                </Upload>

                {/* <Button
                  type="primary"
                  shape="round"
                  icon={<DownloadOutlined />}
                  size="large"
                  style={{ marginTop: '30px', marginBottom: '30px' }}
                  // onClick={() => fetchPhotosApi(values)}
                >
                  {t('words-list:images.findInPhotoBank')}
                </Button> */}

                {isInputDrawerVisible && (
                  <Input
                    type="text"
                    placeholder={currentTerm}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onPressEnter={() => askAnotherTerm(inputValue)}
                    className={classes.inputDrawer}
                  />
                )}

                <div className={classes.picturesOptionsContainer}>
                  {photoResults.map((photo:any) => (
                    <div
                      className={classes.picturesOption}
                      key={photo.urls.id}
                      tabIndex={0}
                      role="button"
                      aria-label="Mark right word"
                      onClick={() => savePhotoFromApi(photo, values)}
                      onKeyDown={() => savePhotoFromApi(photo, values)}
                    >
                      <img src={photo.urls.small} alt={photo.term} key={photo.urls.id} className={classes.pictureOption} />
                    </div>
                  ))}
                </div>
                <div>
                  <Link to={{ pathname: 'https://unsplash.com/' }} target="_blank" style={{ color: 'lightgreen', cursor: 'pointer', marginTop: '40px' }}> Powered by Unsplash.com</Link>
                </div>
              </Drawer>

            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default withTagManager(WordsListView);
