import { Input as AntInput } from 'antd';
import { Field, FieldProps } from 'formik';
import React, { forwardRef, Ref } from 'react';
import {
  InputProps as $InputProps,
  PasswordProps as $PasswordProps,
  TextAreaProps as $TextAreaProps,
} from 'antd/lib/input';
import { FormikFieldProps } from 'formik-antd/lib/FieldProps';

export type InputProps = FormikFieldProps & $InputProps;

export const Input = forwardRef(({
  name,
  validate,
  maxLength: number,
  onChange: $onChange,
  onBlur: $onBlur,
  ...restProps
}: InputProps, ref: Ref<AntInput>) => (
  <Field name={name} validate={validate}>
    {({ field: { value, onChange, onBlur } }: FieldProps) => (
      <AntInput
        ref={ref}
        maxLength={75}
        name={name}
        value={value}
        onChange={(event) => {
          onChange(event);
          if ($onChange) {
            $onChange(event);
          }
        }}
        onBlur={(event) => {
          onBlur(event);
          if ($onBlur) {
            $onBlur(event);
          }
        }}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...restProps}
      />
    )}
  </Field>
));

export default Input;

export type PasswordProps = FormikFieldProps & $PasswordProps;

// @ts-ignore
Input.Password = ({
  name,
  validate,
  onChange: $onChange,
  onBlur: $onBlur,
  ...restProps
}: PasswordProps) => (
  <Field name={name} validate={validate}>
    {({ field: { value, onChange, onBlur } }: FieldProps) => (
      <AntInput.Password
        name={name}
        value={value}
        onChange={(event) => {
          onChange(event);
          if ($onChange) {
            $onChange(event);
          }
        }}
        onBlur={(event) => {
          onBlur(event);
          if ($onBlur) {
            $onBlur(event);
          }
        }}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...restProps}
      />
    )}
  </Field>
);

export type TextAreaProps = FormikFieldProps & $TextAreaProps;

// @ts-ignore
Input.TextArea = ({
  name,
  validate,
  onChange: $onChange,
  onBlur: $onBlur,
  ...restProps
}: TextAreaProps) => (
  <Field name={name} validate={validate}>
    {({ field: { value, onChange, onBlur } }: FieldProps) => (
      <AntInput.TextArea
        name={name}
        value={value}
        onChange={(event) => {
          onChange(event);
          if ($onChange) {
            $onChange(event);
          }
        }}
        onBlur={(event) => {
          onBlur(event);
          if ($onBlur) {
            $onBlur(event);
          }
        }}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...restProps}
      />
    )}
  </Field>
);

