import React, { FC } from 'react';
import { ExclamationOutlined } from '@ant-design/icons';
import classes from './ErrorMessage.module.less';
import Text from '../Text';

type ErrorMessageProps = {
  children: string;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ children }) => (
  <div className={classes.error}>
    <ExclamationOutlined />
    <Text className={classes.errorMessage}>
      {children}
    </Text>
  </div>
);

export default ErrorMessage;
