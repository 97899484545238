/* eslint-disable max-len */
import React from 'react';

const RulesOfTheGame = () => (
  <>
    <p style={{ color: 'darkslategray', margin: '0' }}>
      <b> Moduł SPARUJ: </b>
      <li> Na środku w górnej części planszy są przesuwalne plakietki ze słowami/zwrotami, a na całej planszy rozrzucone są obrazy, które również można przesuwać.</li>
      <li> Zadaniem jest przenieść PLAKIETKĘ ze zwrotem NA odpowiadający jej obrazek (wystarczy aby zetknęła się krawędzią). </li>
      <li>Jeśli wybór był poprawny, słowo zniknie. Jeśli nie - próbuj dalej. </li>
      <li>Celem jest usunięcie wszystkich plakietek ze słowami. </li>
      <li>INFO: Jeśli lista jest dłuższa niż 10 elementów, karty zostaną rodzielone na mniejsze grupy.</li>
    </p>
  </>
);

export default RulesOfTheGame;
