import React from 'react';
import { Layout } from 'antd';
import { Redirect, Route } from 'react-router';
import LoginView from '../views/auth/LoginView';

import { Path } from '../constants/Path';
import AppContent from '../components/AppContent';
import ResetPasswordView from '../views/auth/ResetPasswordView';
import SignUpView from '../views/auth/SignUpView';
import SignUpConfirmView from '../views/auth/SignUpConfirmView';
import ActivationView from '../views/auth/ActivationView';
import ActivationStudentView from '../views/auth/ActivationStudentView';

const {
  LOGIN,
  HOME,
  RESET_PASSWORD,
  SIGN_UP,
  SIGN_UP_CONFIRM,
  ACTIVATE_TEACHER,
  ACTIVATE_STUDENT,
} = Path;

const publicRoutes = [LOGIN, HOME, RESET_PASSWORD, SIGN_UP, SIGN_UP_CONFIRM, ACTIVATE_TEACHER, ACTIVATE_STUDENT];

const PublicRoutes = (
  <Route exact path={publicRoutes}>
    <Layout>
      <Layout>
        <AppContent isPublic noFrames>
          <Route exact path={HOME}>
            <Redirect to={LOGIN} />
          </Route>
          <Route path={LOGIN}>
            <LoginView />
          </Route>
          <Route path={ACTIVATE_TEACHER}>
            <ActivationView />
          </Route>
          <Route path={ACTIVATE_STUDENT}>
            <ActivationStudentView />
          </Route>
          <Route path={SIGN_UP}>
            <SignUpView />
          </Route>
          <Route path={SIGN_UP_CONFIRM}>
            <SignUpConfirmView />
          </Route>
          <Route path={RESET_PASSWORD}>
            <ResetPasswordView />
          </Route>
        </AppContent>
      </Layout>
    </Layout>
  </Route>
);

export default PublicRoutes;
