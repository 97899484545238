/* eslint-disable max-len */
import React, {
  FC, useCallback, useState,
} from 'react';
import {
  Card,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classes from './ListCard.module.less';
import Text from '../Text';
import useGoToRoute from '../../hooks/useGoToRoute';
import { Path } from '../../constants/Path';
import { setActiveList } from '../../views/auth/slice/actions';
import { getActiveList } from '../../views/auth/slice/selectors';

interface ListCardProp {
  list: any;
}

const { Title } = Typography;

const ListCard: FC<ListCardProp> = ({
  list,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const goToFirstGame = useGoToRoute(Path.REPEAT);
  const activeList = useSelector(getActiveList);
  const [clickedItem, setClickedItem] = useState<{ id: number, wordsLength: number } | null>(null);

  const setActiveWordsList = useCallback(() => {
    if (list.words.length > 2) {
      dispatch(setActiveList(list));
      goToFirstGame();
    } else {
      setClickedItem({ id: list.id, wordsLength: list.words.length });
    }
  }, [list, dispatch, goToFirstGame]);

  return (
    <Card
      hoverable
      key={Math.random()}
      className={classes.singleCard}
    >
      <div
        role="button"
        tabIndex={0}
        className={classes.content}
        onClick={setActiveWordsList}
        onKeyPress={setActiveWordsList}
      >
        <div className={classes.projectNameContainer}>
          <Title level={2} className={classes.title}>{list.name}</Title>
          {activeList?.id === list.id && (
          <div className={classes.badge}>{t('words-list:card.active')}</div>
          )}
        </div>
        <div
          className={`${classes.itemsNumber} ${clickedItem && clickedItem.id === list.id && clickedItem.wordsLength < 3 ? `${classes.clickedItem} ${classes['listTooShort-animation']}` : ''} ${classes.itemsNumber}`}
        >
          {list.words.length}
        </div>

        <Text id={classes.description}>{list.description}</Text>
      </div>
    </Card>
  );
};

export default ListCard;
