import React, { FC, useCallback } from 'react';
import { Button, Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  setDeletingStudentError, deleteStudent,
} from '../slice/actions';
import {
  getDeletingStudentError,
  getIsDeletingStudent,
} from '../slice/selectors';
import classes from './DeleteStudentModal.module.less';
import styles from './DeleteStudentModal.styles';
import { success, error } from '../../../components/Message';
import Text from '../../../components/Text';
import closeIcon from '../../../assets/icons/x.svg';
import { UserData } from '../../../services/ApiService.types';
import useActionStatus from '../../../hooks/useActionStatus';

type DeleteStudentModalProps = {
  student: UserData;
  hideModal: () => void;
};

const { Title } = Typography;

const DeleteStudentModal: FC<DeleteStudentModalProps> = ({ student, hideModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsDeletingStudent);
  const apiError = useSelector(getDeletingStudentError);

  const onSuccess = useCallback(() => {
    success(t('student:delete.messages.success'));
    hideModal();
  }, [hideModal, t]);

  const onError = useCallback(() => {
    error(t('student:delete.messages.failure'));
  }, [t]);

  useActionStatus(onSuccess, onError, {
    isLoadingSelector: getIsDeletingStudent,
    errorSelector: getDeletingStudentError,
  });

  const onClick = useCallback(() => {
    if (student && student.id) {
      dispatch(deleteStudent(student.id));
    }
  }, [dispatch, student]);

  const onCancel = useCallback(() => {
    dispatch(setDeletingStudentError(null));
    hideModal();
  }, [dispatch, hideModal]);

  return student && (
    <Modal
      bodyStyle={styles.modalBodyStyle}
      footer={null}
      visible={Boolean(student)}
      onCancel={onCancel}
      destroyOnClose
      centered
      closeIcon={<img src={closeIcon} alt="Close" />}
    >
      <Title className="centered" style={{ color: 'gray', fontWeight: 'bolder' }}>{t('student:delete.title')}</Title>
      <Text className={classes.description}>{t('student:delete.description')}</Text>
      <div className={classes.buttons}>
        <Button
          className={classNames([classes.button, classes.cancelButton])}
          size="large"
          type="ghost"
          onClick={onCancel}
          disabled={isLoading}
        >
          {t('student:delete.cancel')}
        </Button>
        <Button
          className={classes.button}
          size="large"
          type="primary"
          danger
          onClick={onClick}
          disabled={isLoading}
          loading={isLoading}
          style={{ backgroundColor: 'darkred' }}
        >
          {t('student:delete.submit')}
        </Button>
      </div>
      {apiError && <div>{apiError.message}</div>}
    </Modal>
  );
};

export default DeleteStudentModal;
