/* eslint-disable max-len */
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ListAccessStudentModal.styles';
import classes from './ListAccessStudentModal.module.less';
import closeIcon from '../../../assets/icons/x.svg';
import { UserData } from '../../../services/ApiService.types';
import { getIsLoadingWordsListList, getListOfWordsList } from '../../words-lists/slice/selectors';
import { fetchWordsListPage, resetData } from '../../words-lists/slice/actions';
import { getActiveList } from '../../auth/slice/selectors';
import { setActiveList } from '../../auth/slice/actions';

type ListAccessStudentModalProps = {
  isVisible: boolean;
  hideModal: () => void;
  student: UserData;
};

const { Title } = Typography;

const ListAccessStudentModal: FC<ListAccessStudentModalProps> = ({
  student, isVisible, hideModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(getIsLoadingWordsListList);
  const lists = useSelector(getListOfWordsList);

  const activeList = useSelector(getActiveList);
  const [clickedItem, setClickedItem] = useState<{ id: number, wordsLength: number } | null>(null);

  const onCancel = useCallback(() => {
    hideModal();
  }, [hideModal]);

  const loadMoreData = useCallback(() => {
    if (loading) {
      return;
    }
    dispatch(fetchWordsListPage());
  }, [dispatch, loading]);

  useEffect(() => {
    dispatch(resetData());
    loadMoreData();
  }, []);

  const filteredLists = lists.filter((list: { combinedIds: string[]; }) => {
    const studentIdString = String(student.id);

    return list.combinedIds.some((id: string) => id.startsWith(studentIdString.substring(0, id.length)));
  });

  const toggleActiveWordsList = useCallback((item) => {
    if (!activeList && item.words.length >= 2) {
      dispatch(setActiveList(item));
    } else if (activeList.id === item.id) {
      dispatch(setActiveList(!activeList));
    } else if (activeList && item.words.length >= 2) {
      dispatch(setActiveList(item));
    }

    if (item.words.length < 2) {
      setClickedItem({ id: item.id, wordsLength: item.words.length });
      setTimeout(() => setClickedItem(null), 2500);
    } else {
      setClickedItem(null);
    }
  }, [activeList, dispatch]);

  return (
    <Modal
      bodyStyle={styles.modalBodyStyle}
      style={{ maxWidth: '70vw' }}
      footer={null}
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      centered
      closeIcon={<img src={closeIcon} alt="Close" />}
    >
      <Title className={classes.title}>{t('student:access.title')}</Title>

      <p className={classes.studentName}>{student.name} {student.surname} </p>

      <p className={classes.listNumber}>[ {filteredLists.length} ]</p>

      <div className={classes.listWrap}>
        {filteredLists.map((item: any) => (
          <div
            key={item.id}
            role="button"
            tabIndex={0}
            onClick={() => toggleActiveWordsList(item)}
            onKeyPress={() => toggleActiveWordsList(item)}
            className={classes.itemStyle}
          >
            <p className={`${classes.listLabel} ${item.id === activeList?.id ? `${classes.activeStudent} ${classes['activeStudent-animation']}` : ''} ${clickedItem && clickedItem.id === item.id && clickedItem.wordsLength < 3 ? `${classes.clickItem} ${classes['clickedItem-animation']}` : ''}`}>
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ListAccessStudentModal;
