/* eslint-disable max-len */
import React, { FC } from 'react';
import '../../views/writing/slice/slice';
import './PictureOfWord.less';

type CardProps = {
  id: any,
  style: any,
};

export const PictureOfWord: FC<CardProps> = ({
  id, style,
}) => (
  <>
    <div
      className="pick_word"
      id={id}
      style={style}
    />
  </>
);

export default PictureOfWord;
