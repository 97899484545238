import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ApiError } from '../../../../../services/ApiService.types';

export type Values = {
  email: string;
};

export const INITIAL_VALUES: Values = {
  email: '',
};

export default (t: any) => Yup.object().shape({
  email: Yup.string()
    .email(t('common:validation.email.invalid'))
    .required(t('common:validation.email.required')),
});

export const getErrorMessage = (error: ApiError, t: TFunction) => {
  switch (error.statusCode) {
    case 400:
      return t('user:requestResetPassword.errors.invalidEmail');
    case 404:
      return t('user:requestResetPassword.errors.emailNotFound');
    default:
      return t('user:requestResetPassword.errors.default');
  }
};
