import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import SignUpForm from './components/SignUpForm';
import logo from '../../../assets/ logo.png';
import classes from './SignUpView.module.less';
import { Path } from '../../../constants/Path';
import useGoToRoute from '../../../hooks/useGoToRoute';
import useAuthToken from '../../../hooks/useAuthToken';
import { signUpAccount } from '../slice/actions';
import useActionStatus from '../../../hooks/useActionStatus';
import { error } from '../../../components/Message';
import { getIsSignUp, getSignUpError } from '../slice/selectors';

const { Title } = Typography;

const SignUpView: FC = () => {
  const { t } = useTranslation();
  const goToHomePage = useGoToRoute(Path.STUDENTS);
  const goToConfirm = useGoToRoute(Path.SIGN_UP_CONFIRM);
  const dispatch = useDispatch();

  useAuthToken(undefined, goToHomePage);

  const onError = useCallback(() => {
    error(t('common:signUp.messages.error'));
  }, [t]);

  useActionStatus(goToConfirm, onError, {
    isLoadingSelector: getIsSignUp,
    errorSelector: getSignUpError,
  });

  const onSubmit = useCallback(
    (values) => {
      dispatch(signUpAccount(values));
    },
    [dispatch],
  );

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={logo} alt="Logo" />
      <Title className={classes.title}>{t('common:signUp.title')}</Title>
      <SignUpForm onSubmit={onSubmit} />
    </div>
  );
};

export default SignUpView;
