/* eslint-disable max-len */
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import {
  List, Avatar, Skeleton, Divider, Button, Tooltip,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddStudentModal from '../AddStudentModal';
import { getListOfStudents, getIsLoadingStudentsList } from '../slice/selectors';
import { fetchStudentsPage, resetData } from '../slice/actions';
import styles from './StudentListView.module.less';
import DeleteStudentModal from '../DeleteStudentModal';
import { UserData } from '../../../services/ApiService.types';
import EditStudentModal from '../EditStudentModal/EditStudentModal';
import ListAccessStudentModal from '../ListAccessStudentModal/ListAccessStudentModal';
import { ReactComponent as Thrash } from '../../../assets/icons/teacher/trash.svg';
import { ReactComponent as Edit } from '../../../assets/icons/teacher/edit.svg';
import { ReactComponent as ListAccess } from '../../../assets/icons/teacher/list-access.svg';

const StudentListView: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [editedStudent, setEditedStudent] = useState<UserData | null>(null);
  const [deletedStudent, setDeletedStudent] = useState<UserData | null>(null);
  const [listAccessStudent, setListAccessStudent] = useState<UserData | null>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const loading = useSelector(getIsLoadingStudentsList);
  const list = useSelector(getListOfStudents);

  const loadMoreData = useCallback(() => {
    if (loading) {
      return;
    }
    dispatch(fetchStudentsPage());
  }, [dispatch, loading]);

  const hideListAccessStudentModal = useCallback(() => setListAccessStudent(null), [setListAccessStudent]);
  const showListAccessStudentModal = useCallback((student: UserData) => () => setListAccessStudent(student), [setListAccessStudent]);

  const hideEditModal = useCallback(() => setEditedStudent(null), [setEditedStudent]);
  const showEditModal = useCallback((student: UserData) => () => setEditedStudent(student), [setEditedStudent]);

  const hideDeleteModal = useCallback(() => setDeletedStudent(null), [setDeletedStudent]);
  const showDeleteModal = useCallback((student: UserData) => () => setDeletedStudent(student), [setDeletedStudent]);

  const showAddModal = () => {
    setIsModalVisible(true);
  };

  const hideAddModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    dispatch(resetData());
    loadMoreData();
  }, []);

  return (
    <>
      <AddStudentModal isVisible={isModalVisible} hideModal={hideAddModal} />
      {listAccessStudent && (
      <ListAccessStudentModal
        isVisible={!!listAccessStudent}
        student={listAccessStudent}
        hideModal={hideListAccessStudentModal}
      />
      )}
      {editedStudent && <EditStudentModal isVisible={!!editedStudent} student={editedStudent} hideModal={hideEditModal} />}
      {deletedStudent && <DeleteStudentModal student={deletedStudent} hideModal={hideDeleteModal} />}
      <div className={styles.wrapper}>
        <div className={styles.action}>
          <Button
            type="primary"
            onClick={showAddModal}
            className={styles.addStudentButton}
          >
            {t('student:button.create')}
          </Button>
        </div>
        <div
          id="scrollableDiv"
          className={styles.listWrapper}
        >
          <InfiniteScroll
            dataLength={list.length}
            next={loadMoreData}
            hasMore={false}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            endMessage={<Divider />}
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={list}
              renderItem={(item: UserData) => (
                <List.Item key={item.id} className={styles.listPoint}>
                  <List.Item.Meta
                    avatar={<Avatar className={styles.avatar}>{item.name[0].toUpperCase()}{item.surname[0].toUpperCase()}</Avatar>}
                    title={<span className={styles.name}>{item.name} {item.surname}</span>}
                    description={<span className={styles.email}>{item.email}</span>}
                  />
                  <div>

                    <div
                      className={styles.switchActive}
                      tabIndex={0}
                      role="button"
                      onClick={showListAccessStudentModal(item)}
                      onKeyPress={showListAccessStudentModal(item)}
                    >
                      <Tooltip title="udostępnione listy" aria-labelledby="udostępnione listy">
                        <span> <ListAccess className={styles.icon} /> </span>
                      </Tooltip>
                    </div>

                    <div
                      className={styles.switchActive}
                      tabIndex={0}
                      role="button"
                      onClick={showEditModal(item)}
                      onKeyPress={showEditModal(item)}
                    >
                      <Tooltip title="edytuj" aria-labelledby="edytuj">
                        <span> <Edit className={styles.icon} /> </span>
                      </Tooltip>
                    </div>

                    <div
                      className={styles.switchActive}
                      tabIndex={0}
                      role="button"
                      onClick={showDeleteModal(item)}
                      onKeyPress={showDeleteModal(item)}
                    >
                      <Tooltip title="usuń" aria-labelledby="usuń">
                        <span> <Thrash className={styles.icon} /> </span>
                      </Tooltip>
                    </div>

                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default StudentListView;

/* eslint-disable max-len */
// import React, {
//   FC, useCallback, useEffect, useState,
// } from 'react';
// import {
//   List, Avatar, Skeleton, Divider, Button, Tooltip,
// } from 'antd';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import AddStudentModal from '../AddStudentModal';
// import { getListOfStudents, getIsLoadingStudentsList } from '../slice/selectors';
// import { fetchStudentsPage, resetData } from '../slice/actions';
// import styles from './StudentListView.module.less';
// import DeleteStudentModal from '../DeleteStudentModal';
// import { UserData } from '../../../services/ApiService.types';
// import EditStudentModal from '../EditStudentModal/EditStudentModal';
// import ListAccessStudentModal from '../ListAccessStudentModal/ListAccessStudentModal';
// import { ReactComponent as Thrash } from '../../../assets/icons/teacher/trash.svg';
// import { ReactComponent as Edit } from '../../../assets/icons/teacher/edit.svg';
// import { ReactComponent as ListAccess } from '../../../assets/icons/teacher/list-access.svg';

// const StudentListView: FC = () => {
//   const dispatch = useDispatch();
//   const { t } = useTranslation();

//   const [editedStudent, setEditedStudent] = useState<UserData | null>(null);
//   const [deletedStudent, setDeletedStudent] = useState<UserData | null>(null);
//   const [listAccessStudent, setListAccessStudent] = useState<UserData | null>(null);

//   const [isModalVisible, setIsModalVisible] = useState(false);

//   const loading = useSelector(getIsLoadingStudentsList);
//   const list = useSelector(getListOfStudents);

//   const [folders, setFolders] = useState<any>([
//     { id: 1, name: 'Folder 1', items: ['Item 1', 'Item 2'] },
//     { id: 2, name: 'Folder 2', items: ['Item 3', 'Item 4'] },
//   ]);

//   const addItemToFolder = (folderId: any, newItem: any) => {
//     setFolders((prevFolders: any[]) => prevFolders.map((folder) => (folder.id === folderId
//       ? { ...folder, items: [...folder.items, newItem] }
//       : folder)));
//   };

//   const removeItemFromFolder = (folderId: number, itemIndex: number) => {
//     setFolders((prevFolders: any[]) => prevFolders.map((folder) => (folder.id === folderId
//       ? { ...folder, items: folder.items.filter((_: any, index: number) => index !== itemIndex) }
//       : folder)));
//   };
//   const addFolder = (newFolderName: any) => {
//     setFolders((prevFolders: string | any[]) => [
//       ...prevFolders,
//       { id: prevFolders.length + 1, name: newFolderName, items: [] },
//     ]);
//   };
//   const removeFolder = (folderId: any) => {
//     setFolders((prevFolders: any[]) => prevFolders.filter((folder) => folder.id !== folderId));
//   };

//   const loadMoreData = useCallback(() => {
//     if (loading) {
//       return;
//     }
//     dispatch(fetchStudentsPage());
//   }, [dispatch, loading]);

//   const hideListAccessStudentModal = useCallback(() => setListAccessStudent(null), [setListAccessStudent]);
//   const showListAccessStudentModal = useCallback((student: UserData) => () => setListAccessStudent(student), [setListAccessStudent]);

//   const hideEditModal = useCallback(() => setEditedStudent(null), [setEditedStudent]);
//   const showEditModal = useCallback((student: UserData) => () => setEditedStudent(student), [setEditedStudent]);

//   const hideDeleteModal = useCallback(() => setDeletedStudent(null), [setDeletedStudent]);
//   const showDeleteModal = useCallback((student: UserData) => () => setDeletedStudent(student), [setDeletedStudent]);

//   const showAddModal = () => {
//     setIsModalVisible(true);
//   };

//   const hideAddModal = () => {
//     setIsModalVisible(false);
//   };

//   useEffect(() => {
//     dispatch(resetData());
//     loadMoreData();
//   }, []);

//   return (
//     <>
//       <AddStudentModal isVisible={isModalVisible} hideModal={hideAddModal} />
//       {listAccessStudent && (
//       <ListAccessStudentModal
//         isVisible={!!listAccessStudent}
//         student={listAccessStudent}
//         hideModal={hideListAccessStudentModal}
//       />
//       )}
//       {editedStudent && <EditStudentModal isVisible={!!editedStudent} student={editedStudent} hideModal={hideEditModal} />}
//       {deletedStudent && <DeleteStudentModal student={deletedStudent} hideModal={hideDeleteModal} />}
//       <div className={styles.wrapper}>
//         <div className={styles.action}>
//           <Button
//             type="primary"
//             onClick={showAddModal}
//             className={styles.addStudentButton}
//           >
//             {t('student:button.create')}
//           </Button>
//         </div>
//         <div>
//           {folders.map((folder: any) => (
//             <div key={folder.id}>
//               <div>
//                 <h3>{folder.name}</h3>
//                 <ul>
//                   {folder.items.map((item: any, index: any) => (
//                     <li key={Math.random()}>
//                       {item}
//                       <button type="button" onClick={() => removeItemFromFolder(folder.id, index)}>Usuń</button>
//                     </li>
//                   ))}
//                 </ul>
//                 <button type="button" onClick={() => addItemToFolder(folder.id, prompt('Nowy element:'))}>Dodaj element</button>
//               </div>
//               <button type="button" onClick={() => removeFolder(folder.id)}>Usuń folder</button>
//             </div>
//           ))}
//           <button type="button" onClick={() => addFolder(prompt('Nowy folder:'))}>Dodaj folder</button>
//         </div>
//         <div
//           id="scrollableDiv"
//           className={styles.listWrapper}
//         >
//           <InfiniteScroll
//             dataLength={list.length}
//             next={loadMoreData}
//             hasMore={false}
//             loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
//             endMessage={<Divider />}
//             scrollableTarget="scrollableDiv"
//           >
//             <List
//               dataSource={list}
//               renderItem={(item: UserData) => (
//                 <List.Item key={item.id} className={styles.listPoint} draggable>
//                   <List.Item.Meta
//                     avatar={<Avatar className={styles.avatar}>{item.name[0].toUpperCase()}{item.surname[0].toUpperCase()}</Avatar>}
//                     title={<span className={styles.name}>{item.name} {item.surname}</span>}
//                     description={<span className={styles.email}>{item.email}</span>}
//                   />
//                   <div>

//                     <div
//                       className={styles.switchActive}
//                       tabIndex={0}
//                       role="button"
//                       onClick={showListAccessStudentModal(item)}
//                       onKeyPress={showListAccessStudentModal(item)}
//                     >
//                       <Tooltip title="udostępnione listy">
//                         <span> <ListAccess className={styles.icon} /> </span>
//                       </Tooltip>
//                     </div>

//                     <div
//                       className={styles.switchActive}
//                       tabIndex={0}
//                       role="button"
//                       onClick={showEditModal(item)}
//                       onKeyPress={showEditModal(item)}
//                     >
//                       <Tooltip title="edytuj">
//                         <span> <Edit className={styles.icon} /> </span>
//                       </Tooltip>
//                     </div>

//                     <div
//                       className={styles.switchActive}
//                       tabIndex={0}
//                       role="button"
//                       onClick={showDeleteModal(item)}
//                       onKeyPress={showDeleteModal(item)}
//                     >
//                       <Tooltip title="usuń">
//                         <span> <Thrash className={styles.icon} /> </span>
//                       </Tooltip>
//                     </div>

//                   </div>
//                 </List.Item>
//               )}
//             />
//           </InfiniteScroll>
//         </div>
//       </div>
//     </>
//   );
// };

// export default StudentListView;
