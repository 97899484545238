import React, { FC, useCallback, useEffect } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import ResetPasswordForm from './components/ResetPasswordForm';
import classes from './ResetPasswordView.module.less';
import { removeResetPasswordError } from '../slice/actions';
import Text from '../../../components/Text';
import logo from '../../../assets/ logo.png';

const { Title } = Typography;

const ResetPasswordView: FC = () => {
  const { t } = useTranslation('user');
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(removeResetPasswordError());
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    // TODO reset action
  }, []);

  return (
    <div className={classNames([classes.container])}>
      <img className={classes.logo} src={logo} alt="Logo" />
      <Title className={classes.title}>{t('common:requestResetPassword.title')}</Title>
      <Text className={classes.subtitle}>{t('common:requestResetPassword.subtitle')}</Text>
      <ResetPasswordForm onSubmit={onSubmit} />
    </div>
  );
};

export default ResetPasswordView;
