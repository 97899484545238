import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './MenuButton.module.less';
import useGoToRoute from '../../hooks/useGoToRoute';
import { Path } from '../../constants/Path';
import useMatchRoute from '../../hooks/useMatchRoute';
// import Text from '../Text';

type MenuButtonProps = {
  text: string;
  to?: Path;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  nestedPaths?: Path[];
  style?: React.CSSProperties;
  icons: {
    main: string,
    active: string,
    disabled: string,
  };
};

const MenuButton: FC<MenuButtonProps> = ({
  text,
  to,
  disabled,
  className,
  nestedPaths,
  icons,
  onClick,
  style,
}) => {
  const { t } = useTranslation();
  const goTo = useGoToRoute(to);
  const onButtonClick = useCallback(() => {
    if (to && !disabled) {
      goTo();
    } else if (!disabled && onClick) {
      onClick();
    }
  }, [to, goTo, disabled, onClick]);

  const highlighted = useMatchRoute([...(to ? [to] : []), ...(nestedPaths || [])]);

  const iconRendered = useMemo(() => {
    let src = icons.main;

    if (disabled) {
      src = icons.disabled;
    } else if (highlighted) {
      src = icons.active;
    }

    return <div className={classes.iconContainer}><img src={src} alt={text} /></div>;
  }, [disabled, highlighted, icons, text]);

  return (
    <Tooltip
      title={t(text)}
      trigger="hover"
      placement="right"
    >
      <div className={classNames([classes.container, className])}>
        <div
          role="button"
          tabIndex={0}
          style={style}
          onClick={onButtonClick}
          onKeyPress={onButtonClick}
          className={classNames({
            [classes.button]: true,
            [classes.active]: highlighted,
            [classes.inactive]: !highlighted,
            [classes.disabled]: disabled,
          })}
        >
          {iconRendered}
          {/* <Text
            className={classNames({
              [classes.text]: true,
              [classes.textActive]: highlighted,
              [classes.textDisabled]: disabled,
            })}
            ellipsis
          > */}
          {/* {text} */}
          {/* </Text> */}
        </div>
      </div>
    </Tooltip>
  );
};

export default MenuButton;
