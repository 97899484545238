import React, { FC } from 'react';
import '../../views/writing/slice/slice';

type CardProps = {
  id: any,
  content: any,
  style: any,
};

export const WordFromList: FC<CardProps> = ({ content, id, style }) => (
  <>
    <div
      className="word"
      id={id}
      style={style}
    >
      {content}
    </div>
  </>
);

export default WordFromList;
