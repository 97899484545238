import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Result } from 'antd';
import { Path } from '../../constants/Path';
import notFoundIcon from '../../assets/not-found-icon.svg';
import withTagManager from '../../hooks/withTagManager';

const NotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Result
        title={t('common:notFound.title')}
        subTitle={t('common:notFound.subtitle')}
        icon={<img src={notFoundIcon} alt="404" />}
        extra={(
          <Button type="primary" size="large">
            <a href={Path.HOME}>{t('common:notFound.backHome')}</a>
          </Button>
        )}
      />
    </>
  );
};

export default withTagManager(NotFound);
