import {
  FLUSH, PAUSE, PERSIST,
  persistStore, PURGE, REGISTER, REHYDRATE,
} from 'redux-persist';
import {
  configureStore, Action, Slice, getDefaultMiddleware, combineReducers,
} from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import HomeSlice from '../views/home/slice/slice';
import ContactSlice from '../views/contact/slice/slice';
import WordsListsSlice from '../views/words-lists/slice/slice';
import WordsListSlice from '../views/words-list/slice/slice';
import MemorySlice from '../views/memory/slice/slice';
import FindSlice from '../views/find/slice/slice';
import PairingWordsSlice from '../views/pairing/slice/slice';
import WritingSlice from '../views/writing/slice/slice';
import AuthSlice from '../views/auth/slice/slice';
import StudentSlice from '../views/student/slice/slice';
import StudentWordsListsSlice from '../views/student-words-list/slice/slice';

export const mapSliceToReducers = (slices: Slice[]) => slices
  .map((slice) => ({ [slice.name]: slice.reducer }))
  .reduce((previous, current) => ({ ...previous, ...current }), {});

const reducer = combineReducers(mapSliceToReducers(
  [
    HomeSlice,
    FindSlice,
    MemorySlice,
    ContactSlice,
    PairingWordsSlice,
    WritingSlice,
    StudentWordsListsSlice,
    WordsListsSlice,
    WordsListSlice,
    AuthSlice,
    StudentSlice,
  ],
));

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof reducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, null, Action<string>>;
export type AppDispatch = ThunkDispatch<RootState, null, Action<string>>;
