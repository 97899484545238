import React, { FC } from 'react';
import { TextProps } from 'antd/es/typography/Text';
import { Typography } from 'antd';
import classNames from 'classnames';
import classes from './Text.module.less';

type TextPropsType = TextProps & {
  overline?: boolean;
  caption?: boolean;
  caption2?: boolean;
  subtitle?: boolean;
  subtitle2?: boolean;
  subtitle3?: boolean;
  subtext?: boolean;
  body2?: boolean;
  block?: boolean;
  white?: boolean;
  center?: boolean;
};

const Text: FC<TextPropsType> = ({
  caption,
  caption2,
  overline,
  className,
  subtitle,
  subtitle2,
  subtitle3,
  subtext,
  body2,
  block,
  white,
  center,
  ...rest
}) => (
  <Typography.Text
    className={
      classNames({
        [classes.overline]: overline,
        [classes.caption]: caption,
        [classes.caption2]: caption2,
        [classes.subtitle]: subtitle,
        [classes.subtitle2]: subtitle2,
        [classes.subtitle3]: subtitle3,
        [classes.subtext]: subtext,
        [classes.body2]: body2,
        [classes.block]: block,
        [classes.white]: white,
        [classes.center]: center,
        ...(className && { [className]: true }),
      })
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

export default Text;
