/* eslint-disable max-len */
import { MemoryTranslations } from '../interfaces/mamory';

const PolishMemoryTranslations: MemoryTranslations = {
  congratulations: 'Brawo!',
  finished: {
    new: 'Nowe rozdanie',
    close: 'Zakończ',
  },
};

export default PolishMemoryTranslations;
