import { AxiosError, AxiosResponse } from 'axios';
import { decode } from 'jsonwebtoken';
import { writeStorage, deleteFromStorage } from '@rehooks/local-storage';
import authSlice from './slice';
import { AppThunk } from '../../../redux/store';
import API from '../../../services/ApiService';
import {
  ActivateError, ApiError, SignInSuccess, UserData,
} from '../../../services/ApiService.types';

const {
  startAuth, finishAuth, setAuthError, startActivation, setActivationError, finishActivation,
  removeAuthError, removeResetPasswordError, setUserData, wipeUserData,
  startSignUp, finishSignUp, setSignUpError, setActiveList,
} = authSlice.actions;

const logout = (): AppThunk => async () => {
  deleteFromStorage('token');
};

const signIn = (emailInput: string, password: string): AppThunk => async (dispatch) => {
  dispatch(startAuth());
  try {
    const resp = await API.user.signIn(emailInput, password);
    const { data: { accessToken } } = resp as AxiosResponse<SignInSuccess>;
    const {
      id, email, surname, name, role,
    } = decode(accessToken) as UserData;

    writeStorage('token', accessToken);

    dispatch(setUserData({
      id, email, surname, name, role,
    }));
  } catch (e) {
    const { response } = e as AxiosError<ApiError>;
    deleteFromStorage('token');
    dispatch(setAuthError(response && response.data));
    dispatch(wipeUserData());
  } finally {
    dispatch(finishAuth());
  }
};

const signUpAccount = (data: any): AppThunk => async (dispatch) => {
  dispatch(startSignUp());
  try {
    dispatch(logout());

    await API.user.signUp(data);
  } catch (e) {
    const { response } = e as AxiosError<any>;
    dispatch(setSignUpError(response && response.data));
  } finally {
    dispatch(finishSignUp());
  }
};

const activateAccount = (token: string): AppThunk => (
  async (dispatch) => {
    dispatch(startActivation());
    try {
      dispatch(logout());

      await API.user.activateAccount(token);
    } catch (e) {
      const { response } = e as AxiosError<ActivateError>;
      dispatch(setActivationError(response && response.data));
    } finally {
      dispatch(finishActivation());
    }
  }
);

const activateStudentAccount = (token: string, password: string): AppThunk => (
  async (dispatch) => {
    dispatch(startActivation());
    try {
      dispatch(logout());

      await API.user.activateStudentAccount(token, password);
    } catch (e) {
      const { response } = e as AxiosError<ActivateError>;
      dispatch(setActivationError(response && response.data));
    } finally {
      dispatch(finishActivation());
    }
  }
);

export {
  activateAccount,
  activateStudentAccount,
  signUpAccount,
  logout,
  signIn,
  setActiveList,
  removeAuthError,
  removeResetPasswordError,
};
