/* eslint-disable max-len */

export default {
  wordContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: 'transparent',
  },
  wordContainerHead: {
    backgroundColor: '#f2f1ef',
    fontWeight: 'bolder',
    fontSize: '1.6rem',
    borderRadius: '3px 2px 0px 0px',
    color: 'darkgray',
    width: '70%',
  },
  wordContainerBody: {
    backgroundColor: '#d3d3d3',
  },
  // wordsEmpty: {
  //   width: '100%',
  //   textAlign: 'center',
  // },
};

