/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';

type INITIAL_STATE_TYPE = {
  receivedWords: string[],
  list: any[],
  isLoading: boolean,
  error: string | null,
  isAdding: boolean,
  addingError: string | null,
  isEditing: boolean,
  editingError: string | null,
  isDeleting: boolean,
  deletingError: string | null,
  isSharing: any,
  sharingError: string | null,

  startSharingWordsList: boolean,
  finishSharingWordsList: boolean,
  setSharingWordsListError: boolean,
  stopSharingWordsList: boolean,

  combinedIdsForListAccess: string[],
  setStudentIdForListAccess: number[],
  setAccessToWordListByCombinedId: number[],
  removeAccessForStudent: number[],
  studentsReducer: number[];

  startAddingComboId: boolean,
  setComboIdWordsListError: boolean,
  finishComboIdForWordsList: boolean,
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  receivedWords: [],
  list: [],
  isLoading: false,
  error: null,
  isAdding: false,
  addingError: null,
  isEditing: false,
  editingError: null,
  isDeleting: false,
  deletingError: null,
  isSharing: false,
  sharingError: null,

  startSharingWordsList: false,
  finishSharingWordsList: false,
  setSharingWordsListError: false,
  studentsReducer: [],
  stopSharingWordsList: false,

  setStudentIdForListAccess: [],
  combinedIdsForListAccess: [],
  setAccessToWordListByCombinedId: [],
  removeAccessForStudent: [],
  startAddingComboId: false,
  setComboIdWordsListError: false,
  finishComboIdForWordsList: false,
};

// const wordSlice = createSlice({
const { name, reducer, ...rest } = createSlice({
  name: 'words-lists',
  initialState: INITIAL_STATE,
  reducers: {
    resetData: (state) => {
      state.list = [];
    },

    addWordsToList: (state, action) => {
      state.receivedWords.push(action.payload);
    },

    startAddingWordsList: (state) => {
      state.isAdding = true;
      state.addingError = null;
    },
    setAddingWordsListError: (state, action) => {
      state.addingError = action.payload;
    },
    finishAddingWordsList: (state) => {
      state.isAdding = false;
    },
    addWordsListToList: (state, action) => {
      state.list = [
        action.payload,
        ...state.list,
      ];
    },
    startLoadingList: (state) => {
      state.isLoading = true;
    },
    setPageData: (state, action: { payload: { records: any[] } }) => {
      state.list.push(...action.payload.records);
    },
    finishLoadingList: (state) => {
      state.isLoading = false;
    },
    setLoadingListError: (state, action) => {
      state.error = action.payload;
    },
    startDeletingWordsList: (state) => {
      state.isDeleting = true;
    },
    finishDeletingWordsList: (state) => {
      state.isDeleting = false;
    },
    setDeletingWordsListError: (state, action) => {
      state.deletingError = action.payload;
    },

    //
    setAccessToWordListByCombinedId: (state, action) => {
      state.combinedIdsForListAccess = [
        ...state.combinedIdsForListAccess,
        action.payload,
      ];
    },

    removeAccessForStudent: (state, action) => {
      state.combinedIdsForListAccess = state.combinedIdsForListAccess.filter((id) => id !== action.payload);
    },

    //
    startSharingWordsList: (state, action) => {
      state.isSharing = action.payload;
    },
    //
    stopSharingWordsList: (state, action) => {
      state.isSharing = action.payload;
    },
    //
    finishSharingWordsList: (state, action) => {
      state.isSharing = action.payload;
    },
    setSharingWordsListError: (state, action) => {
      state.sharingError = action.payload;
    },

    removeWordsList: (state, action) => {
      state.list = state.list.filter((wordsList: { name: string, id: number }) => wordsList.id !== action.payload);
    },
  },
});

export default {
  name,
  reducer,
  ...rest,
};
