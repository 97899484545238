import React, { FC } from 'react';
import { Layout } from 'antd';
import AppContent from '../components/AppContent/AppContent';
import SideMenu from '../components/SideMenu/SideMenu';
import AppFooter from '../components/AppFooter';
import classes from './DefaultLayout.module.less';
import DefaultHeader from '../components/Headers/DefaultHeader';

const DefaultLayout: FC = ({ children }) => (
  <Layout className={classes.main}>
    <DefaultHeader />
    <Layout className={classes.content}>
      <SideMenu />
      <AppContent id="main">
        {children}
      </AppContent>
    </Layout>
    <AppFooter />
  </Layout>
);

export default DefaultLayout;
