/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';

type INITIAL_STATE_TYPE = {
  isLoading: boolean,
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  isLoading: true,
};

const { name, reducer, ...rest } = createSlice({
  name: 'home',
  initialState: INITIAL_STATE,
  reducers: {
    startFetchData: (state) => {
      state.isLoading = true;
    },
    finishFetchData: (state) => {
      state.isLoading = false;
    },
  },
});

export default {
  name,
  reducer,
  ...rest,
};
