import linenBackground from '../../../assets/linen.png';

export default {
  modalBodyStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column' as const,
    // padding: '10px',
    borderRadius: '10px',
    border: '3px dashed orange',
    margin: '-10px',
    backgroundImage: `url(${linenBackground})`,
  },
};
