import React, { FC, useMemo } from 'react';
import zxcvbn from 'zxcvbn';
import { useTranslation } from 'react-i18next';
import classes from './PasswordStrengthMeter.module.less';
import Text from '../Text';
import PasswordStrengthIndicator from '../PasswordStrengthIndicator';

type PasswordStrengthMeterProps = {
  password: string;
};
const PasswordStrengthMeter: FC<PasswordStrengthMeterProps> = ({ password }) => {
  const { t } = useTranslation('common');
  const { score } = useMemo(() => zxcvbn(password), [password]);

  return (
    <div className={classes.container}>
      <PasswordStrengthIndicator score={score} />
      <Text className={classes.strengthText}>{t(`passwordStrength.levels.${score}`)}</Text>
    </div>
  );
};

export default PasswordStrengthMeter;
