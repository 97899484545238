import React, { FC, useCallback } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import useAuthToken from '../../hooks/useAuthToken';
import { Path } from '../../constants/Path';

type ProtectedRouteProps = RouteProps;

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  ...props
}) => {
  const isTokenValid = useAuthToken();

  const render = useCallback(() => {
    if (!isTokenValid) {
      return <Redirect to={Path.LOGIN} />;
    }

    return children;
  }, [
    isTokenValid,
    children,
  ]);

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      render={render}
    />
  );
};

export default ProtectedRoute;
