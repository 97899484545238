/* eslint-disable max-len */
import { StudentTranslations } from '../interfaces/student';

const PolishStudentTranslations: StudentTranslations = {
  button: {
    create: 'Dodaj ucznia',
  },
  add: {
    title: 'Dodaj ucznia',
    messages: {
      success: 'Dodano nowego ucznia',
      error: 'Nie udało się dodać nowego ucznia. Spróbuj ponownie za chwilę.',
    },
  },
  access: {
    title: 'Udostępnione listy',
    messages: {
      success: 'yay!',
      error: 'Nie udało się.',
    },
  },
  edit: {
    title: 'Edycja',
    messages: {
      success: 'Edytowano ucznia',
      error: 'Nie udało się edytować ucznia. Spróbuj ponownie za chwilę.',
    },
  },
  delete: {
    title: 'Usuń',
    description: 'Czy jesteś pewien, że chcesz usunąć tego ucznia ?',
    submit: 'Usuń',
    cancel: 'Anuluj',
    messages: {
      success: 'Uczeń został usunięty',
      error: 'Nie udało się usunąć ucznia',
    },
  },
  studentForm: {
    labels: {
      name: 'Imię:',
      surname: 'Nazwisko:',
      email: 'Email:',
      submit: 'Zapisz',
      edit: 'Edytuj',
    },

    validation: {
      name: {
        required: 'Imię jest wymagane',
      },
      surname: {
        required: 'Nazwisko jest wymagane',
      },
      email: {
        required: 'Email jest wymagany',
      },
    },
  },
};

export default PolishStudentTranslations;
