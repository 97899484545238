import React, {
  FC, memo, useState,
} from 'react';
import './Recognize.less';
import './slice/slice';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import ReactCardFlip from 'react-card-flip';
import { shuffle } from 'lodash';
import FixWidthContent from '../../components/FixWidthContent';
import withTagManager from '../../hooks/withTagManager';
import { WordFromList } from '../../components/Recognize/WordFromList';
import { ReactComponent as Repeat } from '../../assets/icons/games/recognize/refresh-ccw-rec.svg';
import { ReactComponent as Correct } from '../../assets/icons/games/recognize/check-circle-2.svg';
import InfoButton from '../../components/InfoButton/InfoButton';
import RulesOfTheGame from './RulesOfTheGame';
import { getActiveList } from '../auth/slice/selectors';
import { BASE_API_URL } from '../../services/ApiService';
import EndGameInfo from '../../components/EndGameInfo/EndgameInfo';
import useGoToRoute from '../../hooks/useGoToRoute';
import { Path } from '../../constants/Path';

export const RecognizeView: FC = memo(() => {
  const activeList = useSelector(getActiveList);
  const { words } = activeList;

  const [currentWords, setCurrentWords] = useState(shuffle(words));
  const [wordOnScreen, setWordOnScreen] = useState(0);
  const [listEndInfo, setListEndInfo] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [audio, setAudio] = useState<any>(null);

  const goToNext = useGoToRoute(Path.RECOGNIZE2);
  const repeatGame = () => {
    setCurrentWords(shuffle(words));
    setListEndInfo(false);
    setWordOnScreen(0);
  };

  const handleFlipAndSound = () => {
    if (audio) {
      audio.pause();
    }
    const newAudio = new Audio(`${BASE_API_URL}/v1/tts?q=${currentWords[wordOnScreen].term}`);
    setAudio(newAudio);
    newAudio.play();

    setIsFlipped((current) => !current);
  };

  const handleFlip = () => {
    setIsFlipped((current) => !current);
  };

  const handleNextClick = (event: any, updatedCurrentWords: any): any => {
    if (wordOnScreen < updatedCurrentWords.length - 1) {
      setIsFlipped(false);
      setTimeout(() => {
        setWordOnScreen((prev) => prev + 1);
      }, 100);
    } else {
      setListEndInfo(true);
    }
  };

  const handleRepeat = (): any => {
    const wordToRepeat = { ...currentWords[wordOnScreen] };
    wordToRepeat.id = currentWords.length + 1;

    if (wordOnScreen === currentWords.length - 1) {
      const updatedCurrentWords = [...currentWords, currentWords[0], wordToRepeat];
      setCurrentWords(updatedCurrentWords);
      handleNextClick(null, updatedCurrentWords);
    } else {
      setCurrentWords(() => [...currentWords, wordToRepeat]);
      handleNextClick(null, currentWords);
    }
  };

  return (
    <>
      <FixWidthContent>

        <InfoButton content={<RulesOfTheGame />} />

        {!listEndInfo && (
        <>
          <div>
            <WordFromList
              id={currentWords[wordOnScreen].id}
              content={currentWords[wordOnScreen].term}
            />
          </div>

          <div className="flipper">
            <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical" flipSpeedFrontToBack={0.15} flipSpeedBackToFront={0.15}>
              <div
                className="front"
                onClick={handleFlipAndSound}
                aria-hidden="true"
              >
                ?
              </div>
              <div
                className="back"
                onClick={handleFlip}
                aria-hidden="true"
              >
                {currentWords[wordOnScreen].definition}
              </div>
            </ReactCardFlip>
          </div>

          <div className="options">
            <Button
              onClick={handleRepeat}
              className={wordOnScreen === currentWords.length - 1 ? 'optionButton lastWord' : 'optionButton'}
            >
              <Repeat className="btnLabel" />
              <br />
              REPEAT
            </Button>

            <Button
              onClick={(event) => handleNextClick(event, currentWords)}
              className="optionButton"
            >
              <Correct className="btnLabel" />
              <br />
              CORRECT
            </Button>

          </div>
        </>
        )}

        {listEndInfo && (
          <EndGameInfo repeatGameTrigger={repeatGame} nextGame={goToNext} />
        )}

      </FixWidthContent>
    </>
  );
});

export default withTagManager(RecognizeView);
