import React, { FC } from 'react';
import classNames from 'classnames';
import { Typography } from 'antd';
import classes from './MessageContent.module.less';

interface MessageContentProps {
  type: 'success' | 'error'
}

const MessageContent: FC<MessageContentProps> = ({ children, type }) => (
  <div
    className={classNames({
      [classes.messageContent]: true,
      [classes.success]: type === 'success',
      [classes.error]: type === 'error',
    })}
  >
    <Typography.Text className={classes.text}>{children}</Typography.Text>
  </div>
);

export default MessageContent;
