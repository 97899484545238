import { Selector } from 'react-redux';
import { RootState } from '../../../redux/store';
import slice from './slice';
import { Nullable } from '../../../types';

export const getIsSending: Selector<RootState, boolean> = (state) => (
  state[slice.name].isSending
);

export const getIsSendingError: Selector<RootState, Nullable<any>> = (state) => (
  state[slice.name].error
);
