import { Selector } from 'react-redux';
import { RootState } from '../../../redux/store';
import slice from './slice';

export const getIsLoadingWordsListList = (state: RootState) => state[slice.name].isLoading;
export const getStudentListOfWordsList = (state: RootState) => state[slice.name].list;

export const getStudentIdForListAccess: Selector<RootState, boolean> = (state) => (
  state[slice.name].studentIdForWordListAccess
);/// ///////////////////////////////////////

export const getIsLoading: Selector<RootState, boolean> = (state) => (
  state[slice.name].isLoading
);

