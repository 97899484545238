import React, { FC } from 'react';
import { ZXCVBNScore } from 'zxcvbn';
import classes from '../PasswordStrengthMeter/PasswordStrengthMeter.module.less';
import PasswordStrengthBlock from '../PasswordStrengthBlock/PasswordStrengthBlock';

type PasswordStrengthMeterIndicator = {
  score: ZXCVBNScore;
};

const getClassNameForScore = (score: ZXCVBNScore): string => {
  switch (score) {
    case 1: return classes.score1;
    case 2: return classes.score2;
    case 3: return classes.score3;
    case 4: return classes.score4;
    default: return '';
  }
};

const PasswordStrengthIndicator: FC<PasswordStrengthMeterIndicator> = ({ score }) => {
  const className = getClassNameForScore(score);

  return (
    <div className={classes.indicator}>
      <PasswordStrengthBlock className={score > 0 && className} />
      <PasswordStrengthBlock className={score > 1 && className} />
      <PasswordStrengthBlock className={score > 2 && className} />
      <PasswordStrengthBlock className={score > 3 && className} />
    </div>
  );
};

export default PasswordStrengthIndicator;
