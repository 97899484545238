/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button, Divider, List, Skeleton, Tooltip,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import withTagManager from '../../hooks/withTagManager';
import styles from './WordsListsView.module.less';
import AddWordsListModal from './AddWordsListModal';
import { getIsLoadingWordsListList, getListOfWordsList } from './slice/selectors';
import { fetchWordsListPage, resetData } from './slice/actions';
import DeleteWordsListModal from './DeleteWordsListModal';
import { getWordsListLink } from '../../constants/Path';
import ShareWordsListModal from './ShareWordsListModal';

import { ReactComponent as Edit } from '../../assets/icons/teacher/edit.svg';
import { ReactComponent as Share } from '../../assets/icons/teacher/share.svg';
import { ReactComponent as Thrash } from '../../assets/icons/teacher/trash.svg';
import { setActiveList } from '../auth/slice/actions';
import { getActiveList } from '../auth/slice/selectors';

const WordsListsView: FC = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(getIsLoadingWordsListList);
  const lists = useSelector(getListOfWordsList);

  const activeList = useSelector(getActiveList);
  const [deletedWordsList, setDeletedWordsList] = useState<{ id: number, name: string } | null>(null);
  const [sharedWordsList, setSharedWordsList] = useState<{ id: number, name: string, combinedIds: string[] } | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clickedItem, setClickedItem] = useState<{ id: number, wordsLength: number } | null>(null);

  const sortedListsByDate = [...lists].sort((a, b) => {
    const dateA = new Date(a.updatedAt).getTime();
    const dateB = new Date(b.updatedAt).getTime();

    return dateB - dateA;
  });

  // const listsCombinedIds: string[] = lists.map((item: { combinedIds: string[]; }) => item.combinedIds);

  const onItemClick = useCallback((item) => {
    push(getWordsListLink(item.id));
  }, [push]);

  const showAddModal = () => {
    setIsModalVisible(true);
  };

  const hideAddModal = () => {
    setIsModalVisible(false);
  };

  const hideShareModal = useCallback(() => setSharedWordsList(null), [setSharedWordsList]);

  const showShareModal = useCallback((wordsList: { id: number, name: string, combinedIds: string[] }) => {
    setSharedWordsList(wordsList);
  }, [setSharedWordsList]);

  const hideDeleteModal = useCallback(() => setDeletedWordsList(null), [setDeletedWordsList]);

  const showDeleteModal = useCallback(
    (wordsList: { id: number, name: string }) => setDeletedWordsList(wordsList), [setDeletedWordsList],
  );

  const toggleActiveWordsList = useCallback((item) => {
    if (!activeList && item.words.length >= 2) {
      dispatch(setActiveList(item));
    } else if (activeList.id === item.id) {
      dispatch(setActiveList(!activeList));
    } else if (activeList && item.words.length >= 2) {
      dispatch(setActiveList(item));
    }

    if (item.words.length < 2) {
      setClickedItem({ id: item.id, wordsLength: item.words.length });
      setTimeout(() => setClickedItem(null), 2500);
    } else {
      setClickedItem(null);
    }
  }, [activeList, dispatch]);

  const loadMoreData = useCallback(() => {
    if (loading) {
      return;
    }
    dispatch(fetchWordsListPage());
  }, [dispatch, loading]);

  useEffect(() => {
    dispatch(resetData());
    loadMoreData();
  }, []);

  return (
    <>
      <AddWordsListModal isVisible={isModalVisible} hideModal={hideAddModal} />
      {deletedWordsList && <DeleteWordsListModal wordsList={deletedWordsList} hideModal={hideDeleteModal} />}
      {sharedWordsList && <ShareWordsListModal wordsList={sharedWordsList} hideModal={hideShareModal} />}
      <div className={styles.wrapper}>
        <div className={styles.action}>
          <Button
            type="primary"
            onClick={showAddModal}
            className={styles.addListButton}
          >
            {t('words-list:button.create')}
          </Button>
        </div>

        <div
          id="scrollableDiv"
          className={styles.listWrapper}
        >
          <InfiniteScroll
            dataLength={lists.length}
            next={loadMoreData}
            hasMore={false}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            endMessage={<Divider />}
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={sortedListsByDate}
              renderItem={(item: { id: number, name: string, combinedIds: string[], /* shared: boolean, */ words: any }) => (
                <List.Item key={item.id} className={styles.listPoint}>
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => toggleActiveWordsList(item)}
                    onKeyPress={() => toggleActiveWordsList(item)}
                    className={styles.listsNames}
                  >

                    <div className={`${styles.listItems} ${item.id === activeList?.id ? `${styles.activeItem} ${styles['activeItem-animation']}` : ''} ${clickedItem && clickedItem.id === item.id && clickedItem.wordsLength < 3 ? `${styles.clickItem} ${styles['clickedItem-animation']}` : ''}`}>
                      {item.name}

                      { activeList && item.id === activeList.id && (
                        <div className={styles.marker} />
                      )}

                    </div>

                  </div>

                  <div className={styles.actionsContainer}>

                    <Tooltip title="liczba elementów" aria-labelledby="liczba elementów">
                      <div
                        className={`${styles.switchActive} ${clickedItem && clickedItem.id === item.id && clickedItem.wordsLength < 3 ? `${styles.clickedItem} ${styles['listTooShort-animation']}` : ''} ${styles.itemsNumber}`}
                        style={{ cursor: 'default' }}
                        id={styles.itemsNumber}
                      >
                        <span>
                          {item.words.length}
                        </span>
                      </div>
                    </Tooltip>

                    { item && (
                    <div
                      className={styles.switchActive}
                      tabIndex={0}
                      role="button"
                      onClick={() => onItemClick(item)}
                      onKeyPress={() => onItemClick(item)}
                    >
                      <Tooltip title="edytuj listę" aria-labelledby="edytuj listę">
                        <span> <Edit className={styles.icon} /> </span>
                      </Tooltip>
                    </div>
                    )}

                    {/* { !item.shared && ( */}
                    <div
                      className={styles.switchActive}
                      tabIndex={0}
                      role="button"
                      onClick={(event) => {
                        event.preventDefault();
                        showShareModal(item);
                      }}
                      onKeyPress={(event) => {
                        event.preventDefault();
                        showShareModal(item);
                      }}
                    >
                      <Tooltip title="udostępnij" aria-labelledby="udostępnij">
                        <span> <Share className={styles.icon} /> </span>
                      </Tooltip>
                    </div>
                    {/* )} */}

                    <div
                      className={styles.switchActive}
                      tabIndex={0}
                      role="button"
                      onClick={(event) => {
                        event.preventDefault();
                        showDeleteModal(item);
                      }}
                      onKeyPress={(event) => {
                        event.preventDefault();
                        showDeleteModal(item);
                      }}
                    >
                      <Tooltip title="usuń całą listę" aria-labelledby="usuń">
                        <span> <Thrash className={styles.icon} /> </span>
                      </Tooltip>
                    </div>

                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default withTagManager(WordsListsView);

