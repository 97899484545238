import React, {
  FC, memo, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import FixWidthContent from '../../components/FixWidthContent';
import withTagManager from '../../hooks/withTagManager';
import { CardToPair } from '../../components/Pairing/CardToPair';
import { Picture } from '../../components/Pairing/Picture';
import './slice/slice';
import { getActiveList } from '../auth/slice/selectors';
import InfoButton from '../../components/InfoButton/InfoButton';
import RulesOfTheGame from './RulesOfTheGame';
import './PairingView.less';
import EndGameInfo from '../../components/EndGameInfo/EndgameInfo';
import useGoToRoute from '../../hooks/useGoToRoute';
import { Path } from '../../constants/Path';

gsap.registerPlugin(Draggable);

const PairingView: FC = memo(() => {
  const activeList = useSelector(getActiveList);
  const { words } = activeList;
  const [stateOfCards, setStateOfCards] = useState<any[]>([]);
  const [shuffledPictures, setShuffledPictures] = useState<any[]>([]);
  const [activePartIndex, setActivePartIndex] = useState(0);
  const [listEndInfo, setListEndInfo] = useState(false);

  const PART_SIZE = 10;

  const divideWordsIntoParts = (inputWords: any): any[] => {
    const dividedWords: any[] = [];
    for (let i = 0; i < inputWords.length; i += PART_SIZE) {
      dividedWords.push(inputWords.slice(i, i + PART_SIZE));
    }

    return dividedWords;
  };

  const dividedWords = useMemo(() => divideWordsIntoParts(words), [words]);

  useEffect(() => {
    if (dividedWords.length > 0 && activePartIndex < dividedWords.length) {
      const currentPart = dividedWords[activePartIndex];

      const currentTerms = currentPart.map((word: any) => ({
        id: word.id,
        term: word.term,
      })).sort(() => 0.5 - Math.random());

      const currentPictures = currentPart.map((word: any) => ({
        id: word.id,
        image: word.image,
        definition: word.definition,
      })).sort(() => 0.5 - Math.random());

      setStateOfCards(currentTerms);
      setShuffledPictures(currentPictures);
    }
  }, [dividedWords, activePartIndex]);

  const makeItemDone = (id: string) => {
    setStateOfCards((prevStateOfCards) => {
      const newTerms = prevStateOfCards.filter((term) => term.id !== id);

      if (newTerms.length === 0) {
        if (activePartIndex < dividedWords.length - 1) {
          setActivePartIndex((prevIndex) => prevIndex + 1);
        } else {
          setTimeout(() => setListEndInfo(true), 400);
        }
      }

      return newTerms;
    });
  };

  const goToNext = useGoToRoute(Path.MEMORY);
  const repeatGame = () => {
    const currentPart = dividedWords[activePartIndex];

    const currentTerms = currentPart.map((word: any) => ({
      id: word.id,
      term: word.term,
    })).sort(() => 0.5 - Math.random());

    const currentPictures = currentPart.map((word: any) => ({
      id: word.id,
      image: word.image,
      definition: word.definition,
    })).sort(() => 0.5 - Math.random());

    setStateOfCards(currentTerms);
    setShuffledPictures(currentPictures);
    setListEndInfo(false);
    setActivePartIndex(0);
  };

  return (
    <>
      <FixWidthContent className="boardForPics">

        <InfoButton content={<RulesOfTheGame />} />

        {dividedWords.length >= 2 && (
          <p id="pages"> {activePartIndex + 1} / {dividedWords.length} </p>
        )}

        {!listEndInfo && (
          <>
            <div className="words_container">
              {stateOfCards.map((term: any) => (
                <CardToPair
                  id={term.id}
                  content={term.term}
                  key={term.id}
                  itemDone={() => makeItemDone(term.id)}
                />
              ))}
            </div>

            <div>
              {shuffledPictures.map((picture: any) => (
                <Picture
                  id={picture.id}
                  link={picture.image}
                  key={picture.id}
                  translation={picture.definition}
                />
              ))}
            </div>
          </>
        )}

        {listEndInfo && (
          <EndGameInfo repeatGameTrigger={repeatGame} nextGame={goToNext} />
        )}
      </FixWidthContent>
    </>
  );
});

export default withTagManager(PairingView);
