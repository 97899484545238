import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import {
  getIsResettingPassword,
  getResetPasswordError,
} from '../../../slice/selectors';
import { Path } from '../../../../../constants/Path';
import getValidationSchema, {
  getErrorMessage,
  Values,
} from './ResetPasswordForm.schema';
import useGoToRoute from '../../../../../hooks/useGoToRoute';
import classes from './ResetPasswordForm.module.less';
import Text from '../../../../../components/Text';
import FormItem from '../../../../../components/FormItem';
import ErrorMessage from '../../../../../components/ErrorMessage';

type ResetPasswordFormProps = {
  onSubmit: (values: Values) => void;
};

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('user');
  const isResettingPassword = useSelector(getIsResettingPassword);
  const resetPasswordError = useSelector(getResetPasswordError);
  const initialValues = {
    email: '',
  };
  const goToLogin = useGoToRoute(Path.LOGIN);
  const validationSchema = getValidationSchema(t);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values }) => (
        <Form className={classes.form} data-cy="resetPasswordForm">
          {resetPasswordError && (
            <ErrorMessage>
              {getErrorMessage(resetPasswordError, t)}
            </ErrorMessage>
          )}
          <FormItem
            name="email"
            labelKey="common:requestResetPassword.labels.email"
            isTouched={touched.email}
            error={errors.email}
            value={values.email}
          />
          <SubmitButton
            className={classes.submitButton}
            data-cy="submitButton"
            loading={isResettingPassword}
            disabled={isResettingPassword}
            size="large"
            block
          >
            {t('common:requestResetPassword.labels.submit')}
          </SubmitButton>

          <Button
            className={classes.backButton}
            data-cy="backToLogInButton"
            onClick={goToLogin}
            type="link"
            loading={isResettingPassword}
            disabled={isResettingPassword}
            size="large"
            block
          >
            <Text className={classes.backLink}>
              {t('common:requestResetPassword.labels.goBack')}
            </Text>
          </Button>

        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
