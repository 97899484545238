/* eslint-disable max-len */
import React, {
  FC, memo, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import './slice/slice';
import { Button } from 'antd';
import './Repeat.less';
import FixWidthContent from '../../components/FixWidthContent';
import withTagManager from '../../hooks/withTagManager';
import { WordFromList } from '../../components/Repeat/WordFromList';
import { ReactComponent as Play } from '../../assets/icons/games/repeat/play-circle-game.svg';
import { ReactComponent as Pause } from '../../assets/icons/games/repeat/pause-circle.svg';
import InfoButton from '../../components/InfoButton/InfoButton';
import RulesOfTheGame from './RulesOfTheGame';
import { getActiveList } from '../auth/slice/selectors';
import { BASE_API_URL } from '../../services/ApiService';
import BackgroundLinen from '../../assets/linen.png';
import useGoToRoute from '../../hooks/useGoToRoute';
import { Path } from '../../constants/Path';
import EndGameInfo from '../../components/EndGameInfo/EndgameInfo';

export const RepeatView: FC = memo(() => {
  const activeList = useSelector(getActiveList);
  const { words } = activeList;
  const wordsCopy = [...words];
  const shuffledWords = wordsCopy.sort(() => 0.5 - Math.random());
  const [currentWords, setCurrentWords] = useState(shuffledWords);

  const [wordOnScreen, setWordOnScreen] = useState<number>(0);
  const [listEndInfo, setListEndInfo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState<any>(null);
  const numberOfWords = currentWords.length - 1;

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);

  const goToNext = useGoToRoute(Path.FIND);
  const repeatGame = () => {
    setTimeout(() => {
      setCurrentWords(wordsCopy);
      setWordOnScreen(0);
      setListEndInfo(false);
      setIsPlaying(true);
    }, 800);
  };

  const togglePlayPause = (): any => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsPlaying((prevIsPlaying) => {
      if (!prevIsPlaying && wordOnScreen === numberOfWords) {
        setListEndInfo(false);
      }

      return !prevIsPlaying;
    });
  };

  useEffect(() => {
    const endReading = () => {
      if (isPlaying && wordOnScreen < numberOfWords) {
        setAudio(null);
        setTimeoutId(
          setTimeout(() => {
            setWordOnScreen((prevWord) => prevWord + 1);
          }, 1200),
        );
      } else if (isPlaying && wordOnScreen === numberOfWords) {
        clearTimeout(timeoutId);
        setAudio(null);
        setTimeoutId(
          setTimeout(() => {
            setListEndInfo(true);
          }, 1200),
        );
      } else {
        clearTimeout(timeoutId);
        setWordOnScreen(wordOnScreen);
        setAudio(null);
        setIsPlaying(false);
      }

      if (!isPlaying) {
        setWordOnScreen(wordOnScreen);
        setAudio(null);
        clearTimeout(timeoutId);
      }

      return () => {};
    };

    const handleEnded = () => {
      if (audio) {
        audio.removeEventListener('ended', handleEnded);
        audio.pause();
        setAudio(null);
      }
      endReading();
    };

    if (audio) {
      audio.pause();
    }

    const newAudio = new Audio(`${BASE_API_URL}/v1/tts?q=${currentWords[wordOnScreen].term}`);
    newAudio.addEventListener('ended', handleEnded);
    setAudio(newAudio);
    newAudio.play();

    if (!isPlaying) {
      newAudio.pause();
      clearTimeout(timeoutId);
      setAudio(null);
    }

    return () => {
      if (audio) {
        audio.removeEventListener('ended', handleEnded);
        audio.pause();
        setAudio(null);
        clearTimeout(timeoutId);
      }
    };
  }, [isPlaying, wordOnScreen]);

  return (
    <>
      <FixWidthContent>

        <InfoButton content={<RulesOfTheGame />} />

        {!listEndInfo && (
        <>
          <div className="pict" style={{ backgroundImage: `url(${currentWords[wordOnScreen].image})` }} />
          <div>
            <WordFromList
              id={currentWords[wordOnScreen].id}
              content={currentWords[wordOnScreen].term}
              style={{ color: 'verydarkgrey' }}
            />
          </div>
          <div>
            <WordFromList
              id={currentWords[wordOnScreen].id}
              content={currentWords[wordOnScreen].definition}
              style={{ background: `url(${BackgroundLinen})`, color: '#005f5f' }}
            />
          </div>

          <div className="control_panel">
            <Button id="play_button" onClick={togglePlayPause}>
              {!isPlaying ? <Play className="btnLabel" /> : <Pause className="btnLabel_active" />}
              <br />
              {!isPlaying ? 'GO' : 'PAUSE'}
            </Button>
          </div>
        </>
        )}

        {listEndInfo && (
          <EndGameInfo repeatGameTrigger={repeatGame} nextGame={goToNext} firstRound />
        )}

      </FixWidthContent>
    </>
  );
});

export default withTagManager(RepeatView);
