import React, { FC } from 'react';
import {
  Layout, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './AppFooter.module.less';

const { Footer } = Layout;

const AppFooter: FC = () => {
  const { t } = useTranslation();

  return (
    <Footer
      className={classes.footerContainer}
    >
      <div className={classes.copyright}>
        <Typography.Text className={classes.faded}>
          {t('common:footer.copyright', { year: new Date().getFullYear() })}
        </Typography.Text>
      </div>
    </Footer>
  );
};

export default AppFooter;
