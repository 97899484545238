import axios, { AxiosResponse } from 'axios';
import { SignIn, UserData } from './ApiService.types';

export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const getAxiosInstance = () => {
  const http = axios.create({
    baseURL: BASE_API_URL,
  });

  http.interceptors.request.use((config: any) => {
    const token = localStorage.getItem('token');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  return http;
};

class ApiService {
  private axiosInstance = getAxiosInstance();

  public user = {
    signIn: async (email: string, password: string): Promise<SignIn> => (
      this.axiosInstance.post('v1/sessions', { email, password })
    ),
    signUp: async (data: any): Promise<any> => (
      this.axiosInstance.post('v1/users', data)
    ),
    activateAccount: async (token: string): Promise<void> => (
      this.axiosInstance.post('v1/users/activate', { token })
    ),
    activateStudentAccount: async (token: string, password: string): Promise<void> => (
      this.axiosInstance.post('v1/users/activate-student', { token, password })
    ),
  };

  public photos = {
    add: async (
      data: FormData,
    ): Promise<AxiosResponse<{ publicUrl: string }>> => (
      this.axiosInstance.post('v1/photos', data)
    ),
  };

  public wordsList = {
    create: async (wordsListData: { name: string }): Promise<AxiosResponse<any>> => (
      this.axiosInstance.post('v1/words-lists', wordsListData)
    ),
    delete: async (id: number): Promise<AxiosResponse<null>> => (
      this.axiosInstance.delete(`v1/words-lists/${id}`)
    ),
    // share: async (id: number, combinedId: string): Promise<AxiosResponse<null>> => (
    share: async (id: number, combinedId: string): Promise<AxiosResponse<{ combinedIds: any[] }>> => (
      this.axiosInstance.post(`v1/words-lists/${id}/share`, { combinedId })
    ),
    edit: async (id: number, userData: UserData): Promise<AxiosResponse<null>> => (
      this.axiosInstance.put(`v1/words-lists/${id}`, userData)
    ),
    list: async (page: number = 1): Promise<AxiosResponse<{ records: { name: string }[] }>> => (
      this.axiosInstance.get(`v1/words-lists?page=${page}`)
    ),
    getOne: async (id: string): Promise<AxiosResponse<{ name: string, id: number, combinedId: any[], words: any[] }>> => (
      this.axiosInstance.get(`v1/words-lists/${id}`)
    ),
    save: async (
      id: string,
      values: { name: string, description: string, combinedId: any, words: any[] },
    ): Promise<AxiosResponse<{ name: string, description: string, id: number, combinedId: any, words: any[] }>> => (
      this.axiosInstance.put(`v1/words-lists/${id}`, values)
    ),
  };

  public students = {
    create: async (userData: UserData): Promise<AxiosResponse<UserData>> => (
      this.axiosInstance.post('v1/students', userData)
    ),
    delete: async (id: number): Promise<AxiosResponse<null>> => (
      this.axiosInstance.delete(`v1/students/${id}`)
    ),
    edit: async (id: number, userData: UserData): Promise<AxiosResponse<null>> => (
      this.axiosInstance.put(`v1/students/${id}`, userData)
    ),
    list: async (page: number = 1): Promise<AxiosResponse<{ records: UserData[] }>> => (
      this.axiosInstance.get(`v1/students?page=${page}`)
    ),
    wordsList: async (page: number = 1): Promise<AxiosResponse<{ records: { name: string }[] }>> => (
      this.axiosInstance.get(`v1/students/@me/words-lists?page=${page}`)
    ),
  };

  public contact = {
    create: async (payload: any): Promise<AxiosResponse<void>> => (
      this.axiosInstance.post('/v1/contact', payload)
    ),
  };
}

export default new ApiService();
