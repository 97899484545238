import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Result } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import classes from './SignUpConfirmView.module.less';
import FixWidthContent from '../../../components/FixWidthContent';
import useGoToRoute from '../../../hooks/useGoToRoute';
import { Path } from '../../../constants/Path';

const SignUpConfirmView: FC = () => {
  const { t } = useTranslation();
  const goToLogin = useGoToRoute(Path.LOGIN);

  return (
    <FixWidthContent>
      <div className={classes.container}>
        <Result
          title={t('common:signUp.confirm')}
          icon={<CheckCircleOutlined style={{ color: '#287DA4' }} />}
        />
        <Button
          size="large"
          block
          onClick={goToLogin}
        >
          {t('common:signUp.labels.back')}
        </Button>
      </div>
    </FixWidthContent>
  );
};

export default SignUpConfirmView;
