import { Selector } from 'react-redux';
import { RootState } from '../../../redux/store';
import slice from './slice';
import { ApiError } from '../../../services/ApiService.types';

export const getIsLoadingWordsListList = (state: RootState) => state[slice.name].isLoading;
export const getListOfWordsList = (state: RootState) => state[slice.name].list;
export const getIsAdding = (state: RootState) => state[slice.name].isAdding;
export const getIsAddingError = (state: RootState) => state[slice.name].addingError;

export const getIsDeletingWordsList: Selector<RootState, boolean> = (state) => (
  state[slice.name].isDeleting
);
export const getDeletingWordsListError: Selector<RootState, ApiError | null> = (state) => (
  state[slice.name].deletingError
);
export const getIsLoading: Selector<RootState, boolean> = (state) => (
  state[slice.name].isLoading
);
/// //////////////////////////////////////////////////////////////////////////////////////
export const getCombinedIdsForListAccess: Selector<RootState, string[]> = (state) => (
  state[slice.name].combinedIdsForListAccess
);
/// //////////////////////////////////////////////////////////////////////////////////////
export const getIsSharingWordsList: Selector<RootState, boolean> = (state) => (
  state[slice.name].isSharing
);

export const getSharingWordsListError: Selector<RootState, ApiError | null> = (state) => (
  state[slice.name].sharingError
);

