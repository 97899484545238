import React, { FC, useCallback } from 'react';
import './SideMenu.less';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveList, getUserData } from '../../views/auth/slice/selectors';
import { Path } from '../../constants/Path';
import { Roles } from '../../constants/Roles';
import { logout } from '../../views/auth/slice/actions';

import MenuButton from '../MenuButton/MenuButton';
import classes from './SideMenu.module.less';
import Sizes from '../../constants/Sizes';

import studentsIcons from '../../assets/icons/menu/students';
import listsIcons from '../../assets/icons/menu/lists';
// import contactIcons from '../../assets/icons/menu/contact';
// import settings from '../../assets/icons/menu/settings';
import logoutIcons from '../../assets/icons/menu/logout';

import repeatIcons from '../../assets/icons/games/repeat';
import findIcons from '../../assets/icons/games/find';
import pickIcons from '../../assets/icons/games/pick';
import pairIcons from '../../assets/icons/games/pair';
import memoryIcons from '../../assets/icons/games/memory';
import recognizeIcons from '../../assets/icons/games/recognize';
import recognizeIcons2 from '../../assets/icons/games/recognize2';
import writingIcons from '../../assets/icons/games/writing';

const { Sider } = Layout;

const SideMenu: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getUserData);
  const activeList = useSelector(getActiveList);

  const onLogoutClick = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Sider className={classes.sider} width={Sizes.SIDE_MENU}>
      <div className={classes.sectionContainer}>
        <div className={classes.cockpit}>
          {
            user.role === Roles.TEACHER && (
              <MenuButton
                icons={studentsIcons}
                text={t('common:menu.students')}
                to={Path.STUDENTS}
                style={{}}
              />
            )
          }
          {
            user.role === Roles.TEACHER && (
              <MenuButton
                icons={listsIcons}
                text={t('common:menu.wordList')}
                to={Path.WORDS_LIST}
                style={{}}
              />
            )
          }
          {
            user.role === Roles.STUDENT && (
              <MenuButton
                icons={listsIcons}
                text={t('common:menu.wordList')}
                to={Path.MY_LISTS}
                style={{}}
              />
            )
          }
        </div>

        <div className={classes.upperSeparator} />

        <div className={classes.games}>
          {
          !!activeList && (
          // (user.role === Roles.STUDENT && !!activeList) && (
            <MenuButton
              icons={repeatIcons}
              text={t('common: 1. Powtórz')}
              to={Path.REPEAT}
              style={{ marginTop: '1vh' }}
            />
          )
        }
          {
          !!activeList && (
            <MenuButton
              icons={findIcons}
              text={t('common: 2. Znajdź')}
              to={Path.FIND}
              style={{ }}
            />
          )
        }
          {
          !!activeList && (
            <MenuButton
              icons={pickIcons}
              text={t('common: 3. Wybierz')}
              to={Path.PICK}
              style={{}}
            />
          )
        }
          {
          !!activeList && (
            <MenuButton
              icons={pairIcons}
              text={t('common: 4. Sparuj')}
              to={Path.PAIRING}
              style={{}}
            />
          )
        }
          {
          !!activeList && (
          <MenuButton
            icons={memoryIcons}
            text={t('common: 5. Memory')}
            to={Path.MEMORY}
            style={{}}
          />
          )
        }
          {
          !!activeList && (
          <MenuButton
            icons={recognizeIcons}
            text={t('common:  6. Rozpoznaj słowo')}
            to={Path.RECOGNIZE}
            style={{}}
          />
          )
        }
          {
          !!activeList && (
            <MenuButton
              icons={recognizeIcons2}
              text={t('common:  7. Rozpoznaj tłumaczenie')}
              to={Path.RECOGNIZE2}
              style={{}}
            />
          )
        }
          {
          !!activeList && (
          <MenuButton
            icons={writingIcons}
            text={t('common: 8. Napisz')}
            to={Path.WRITING}
            // style={{ marginBottom: '1vh' }}
          />
          )
        }

        </div>
        {/* <div className={classes.separator} /> */}
      </div>

      <div className={classes.lastSection}>

        <div className={classes.downSeparator} />

        {/* <MenuButton
          icons={contactIcons}
          text={t('common:menu.contact')}
          to={Path.CONTACT}
          style={{}}
        />
        <MenuButton
          icons={settings}
          text={t('common:menu.profile')}
          to={Path.PROFILE}
          style={{}}
        /> */}
        <MenuButton
          icons={logoutIcons}
          text={t('common:menu.logout')}
          onClick={onLogoutClick}
          style={{}}
        />
      </div>
    </Sider>
  );
};

export default SideMenu;
