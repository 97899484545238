import { ProductTranslations } from '../interfaces/product';

const PolishProductTranslations: ProductTranslations = {
  description: 'Opis',
  attributes: {
    label: {
      link: 'Link:',
      quantity: 'Ilość:',
      comment: 'Dodaj komentarz:',
    },
    placeholder: {
      comment: 'Wpisz komentarz. Następnie naciśnij ENTER',
    },
  },
  buttons: {
    addToCart: 'Dodaj do koszyka',
    addComment: 'Dodaj',
    more: 'Więcej...',
    less: 'Mniej',
  },
};

export default PolishProductTranslations;
