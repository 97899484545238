import React, { FC } from 'react';
import TagManager from 'react-gtm-module';

export default (Component: FC<any>) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'pageview',
    },
  };

  return class extends React.Component {
    componentDidMount() {
      TagManager.dataLayer(tagManagerArgs);
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};
