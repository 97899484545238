import { Selector } from 'react-redux';
import { RootState } from '../../../redux/store';
import studentSlice from './slice';
import { ApiError } from '../../../services/ApiService.types';

const getIsLoadingStudentsList = (state: RootState) => state[studentSlice.name].isLoading;
const getListOfStudents = (state: RootState) => state[studentSlice.name].list;

const getIsAdding = (state: RootState) => state[studentSlice.name].isAdding;
const getIsAddingError = (state: RootState) => state[studentSlice.name].addingError;

export const getIsEditing = (state: RootState) => state[studentSlice.name].isEditing;
export const getIsEditingError = (state: RootState) => state[studentSlice.name].editingError;

export const getIsDeletingStudent: Selector<RootState, boolean> = (state) => (
  state[studentSlice.name].isDeleting
);
export const getDeletingStudentError: Selector<RootState, ApiError | null> = (state) => (
  state[studentSlice.name].deletingError
);
export {
  getIsLoadingStudentsList,
  getListOfStudents,
  getIsAdding,
  getIsAddingError,
};
