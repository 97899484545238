/* eslint-disable max-len */
// eslint-disable-next-line react/react-in-jsx-scope
import React from 'react';

const RulesOfTheGame = () => (
  <>
    <p style={{ color: 'darkslategray', margin: '0' }}>
      <b> Moduł ROZPOZNAJ SŁOWO: </b>
      <li>JEŚLI rozponajesz znaczenie wyświetlonego słowa, świetnie. Kliknij przycisk CORRECT, aby pojawiło się następne. </li>
      <li>Jeśli nie rozponajesz słowa, albo nie masz pewności, możesz kliknąć plakietkę ze znakiem &quot;?&quot;, aby pojawiło się tłumaczenie.</li>
      <li>Jeśli chcesz jeszcze raz wywołać słowo, możesz kliknąć przycisk REPEAT. Słowo pojawi się ponownie na koniec rozdania. </li>
      <li>INFO: przy ostatnim słowie z listy przycisk REPEAT to sygnalizuje. Jeśli wtedy użyjesz REPEAT, słowo zostanie dodane do powtórzenia razem z jeszcze jednym słowem z listy.</li>
    </p>
  </>
);

export default RulesOfTheGame;
