import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { UserData } from '../../../services/ApiService.types';

export const INITIAL_STATE: any = {
  list: [],
  isLoading: false,
  error: null,
  isAdding: false,
  addingError: null,
  isEditing: false,
  editingError: null,
  isDeleting: false,
  deletingError: null,
};

const { name, reducer, ...rest } = createSlice({
  name: 'students',
  initialState: INITIAL_STATE,
  reducers: {
    resetData: (state) => {
      state.list = [];
    },
    startAddingStudent: (state) => {
      state.isAdding = true;
      state.addingError = null;
    },
    setAddingStudentError: (state, action) => {
      state.addingError = action.payload;
    },
    finishAddingStudent: (state) => {
      state.isAdding = false;
    },
    addStudentToList: (state, action) => {
      state.list = [
        action.payload,
        ...state.list,
      ];
    },
    startEditingStudent: (state) => {
      state.isEditing = true;
      state.editingError = null;
    },
    setEditingStudentError: (state, action) => {
      state.editingError = action.payload;
    },
    replaceStudent: (state, action) => {
      state.list = state.list.map((student: UserData) => {
        if (student.id === action.payload.id) {
          return action.payload;
        }

        return student;
      });
    },
    finishEditingStudent: (state) => {
      state.isEditing = false;
    },
    startDeletingStudent: (state) => {
      state.isDeleting = true;
    },
    finishDeletingStudent: (state) => {
      state.isDeleting = false;
    },
    setDeletingStudentError: (state, action) => {
      state.deletingError = action.payload;
    },
    removeStudent: (state, action) => {
      state.list = state.list.filter((student: UserData) => student.id !== action.payload);
    },
    startLoadingList: (state) => {
      state.isLoading = true;
    },
    setPageData: (state, action: { payload: { records: any[] } }) => {
      state.list.push(...action.payload.records);
    },
    finishLoadingList: (state) => {
      state.isLoading = false;
    },
    setLoadingListError: (state, action) => {
      state.error = action.payload;
    },
  },
});

const persistConfig = {
  key: name,
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default {
  name,
  reducer: persistedReducer,
  ...rest,
};
