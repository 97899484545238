import React from 'react';
import { Layout } from 'antd';

import { Path } from '../constants/Path';
import ProtectedRoute from '../components/ProtectedRoute';
import DefaultLayout from '../layouts/DefaultLayout';
import WordsListsView from '../views/words-lists';
import StudentWordsListsView from '../views/student-words-list';
import TermsAndConditionsView from '../views/terms-and-conditions';
import PrivacyPolicyView from '../views/privacy-policy';
import ContactView from '../views/contact';
import StudentListView from '../views/student/StudentListView';
import WordsListView from '../views/words-list';
import RepeatView from '../views/repeat';
import FindView from '../views/find';
import MemoryView from '../views/memory';
import WritingView from '../views/writing';
import RecognizeView from '../views/recognize';
import RecognizeView2 from '../views/recognize2';
import PickView from '../views/pick';
import PairingView from '../views/pairing';

const {
  MY_LISTS,
  WORDS_LIST,
  WORDS_LIST_ITEM,
  STUDENTS,
  PROFILE,
  REPEAT,
  MEMORY,
  FIND,
  PAIRING,
  WRITING,
  RECOGNIZE,
  RECOGNIZE2,
  PICK,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  CONTACT,
} = Path;

const protectedRoutes = [
  MY_LISTS,
  WORDS_LIST,
  WORDS_LIST_ITEM,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  CONTACT,
  STUDENTS,
  PROFILE,
  REPEAT,
  FIND,
  MEMORY,
  PAIRING,
  WRITING,
  RECOGNIZE,
  RECOGNIZE2,
  PICK,
];

const ProtectedRoutes = (
  <ProtectedRoute exact path={protectedRoutes}>
    <Layout>
      <DefaultLayout>
        <ProtectedRoute exact path={MY_LISTS}>
          <StudentWordsListsView />
        </ProtectedRoute>
        <ProtectedRoute exact path={WORDS_LIST}>
          <WordsListsView />
        </ProtectedRoute>
        <ProtectedRoute exact path={WORDS_LIST_ITEM}>
          <WordsListView />
        </ProtectedRoute>
        <ProtectedRoute exact path={PROFILE}>
          <div>PROFILE</div>
        </ProtectedRoute>
        <ProtectedRoute exact path={STUDENTS}>
          <StudentListView />
        </ProtectedRoute>
        <ProtectedRoute exact path={REPEAT}>
          <RepeatView />
        </ProtectedRoute>
        <ProtectedRoute exact path={FIND}>
          <FindView />
        </ProtectedRoute>
        <ProtectedRoute exact path={MEMORY}>
          <MemoryView />
        </ProtectedRoute>
        <ProtectedRoute exact path={PAIRING}>
          <PairingView />
        </ProtectedRoute>
        <ProtectedRoute exact path={WRITING}>
          <WritingView />
        </ProtectedRoute>
        <ProtectedRoute exact path={RECOGNIZE}>
          <RecognizeView />
        </ProtectedRoute>
        <ProtectedRoute exact path={RECOGNIZE2}>
          <RecognizeView2 />
        </ProtectedRoute>
        <ProtectedRoute exact path={PICK}>
          <PickView />
        </ProtectedRoute>
        <ProtectedRoute exact path={PRIVACY_POLICY}>
          <PrivacyPolicyView />
        </ProtectedRoute>
        <ProtectedRoute exact path={TERMS_AND_CONDITIONS}>
          <TermsAndConditionsView />
        </ProtectedRoute>
        <ProtectedRoute exact path={CONTACT}>
          <ContactView />
        </ProtectedRoute>
      </DefaultLayout>
    </Layout>
  </ProtectedRoute>
);

export default ProtectedRoutes;
