/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { getValidationSchema, INITIAL_VALUES } from './WordsListForm.schema';
import './WordsListForm.less';
import FormItem from '../FormItem';

type WordsListFormProps = {
  onSubmit: (values: { name: string, description: string }) => void;
  isLoading: boolean;
  wordsList?: { name: string, description: string };
};

const WordsListForm = ({ onSubmit, isLoading, wordsList }: WordsListFormProps) => {
  const { t } = useTranslation();
  const validationSchema = getValidationSchema(t);
  const initialValues: { name: string, description: string } = wordsList ? {
    name: wordsList.name,
    description: wordsList.description,
  } : INITIAL_VALUES;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values }) => (
        <Form id="wordsListForm">
          <FormItem
            name="name"
            labelKey="words-list:wordsListForm.labels.name"
            error={errors.name}
            isTouched={touched.name}
            value={values.name}
          />

          <FormItem
            name="description"
            labelKey="words-list:wordsListForm.labels.description"
            error={errors.description}
            isTouched={touched.description}
            value={values.description}
          />

          <SubmitButton
            size="large"
            loading={isLoading}
            block
            className="saveButton"
          >
            {t('words-list:wordsListForm.labels.submit')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default WordsListForm;
