import { AxiosError } from 'axios';
import slice from './slice';
import { ApiError } from '../../../services/ApiService.types';
import { AppThunk } from '../../../redux/store';
import API from '../../../services/ApiService';

const {
  startLoadingList, setPageData, setLoadingListError, finishLoadingList,
  resetData,
} = slice.actions;

const fetchStudentWordsListPage = (): AppThunk => (
  async (dispatch) => {
    dispatch(startLoadingList());
    try {
      const { data: { records } } = await API.students.wordsList();

      dispatch(setPageData({ records }));
    } catch (e) {
      const { response } = e as AxiosError<ApiError>;
      dispatch(setLoadingListError(response && response.data));
    } finally {
      dispatch(finishLoadingList());
    }
  });

export {
  resetData,
  fetchStudentWordsListPage,
};
