/* eslint-disable max-len */
import React, {
  FC, useEffect, useState,
} from 'react';
import './FindView.less';
import './slice/slice';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import FixWidthContent from '../../components/FixWidthContent';
import withTagManager from '../../hooks/withTagManager';
import InfoButton from '../../components/InfoButton/InfoButton';
import RulesOfTheGame from './RulesOfTheGame';
import { BASE_API_URL } from '../../services/ApiService';
import { ReactComponent as Sound } from '../../assets/icons/games/find/volume-high.svg';
import { getActiveList } from '../auth/slice/selectors';
import EndGameInfo from '../../components/EndGameInfo/EndgameInfo';
import useGoToRoute from '../../hooks/useGoToRoute';
import { Path } from '../../constants/Path';

export const FindView: FC = () => {
  const activeList = useSelector(getActiveList);
  const { words } = activeList;
  const wordsCopy = [...words];
  const shuffledWords = wordsCopy.sort(() => 0.5 - Math.random());

  const [wordSpokenIndex, setWordSpokenIndex] = useState<number>(0);
  const [mistakeStyle, setMistakeStyle] = useState<boolean>(false);
  const [correctStyle, setCorrectStyle] = useState<boolean>(false);
  const [audio, setAudio] = useState<any>(null);

  const [listEndInfo, setListEndInfo] = useState(false);

  const [statePics, setStatePics] = useState(shuffledWords);
  const removeCurrentWord = statePics.filter((word) => word.id !== statePics[wordSpokenIndex].id);
  const removedDuplicates = [...new Map(removeCurrentWord.map((word) => [JSON.stringify([word.term]), word])).values()];
  const shufflePictures = removedDuplicates.sort(() => 0.5 - Math.random());
  const removedAddedDuplicates = shufflePictures.filter((word) => word.term !== statePics[wordSpokenIndex].term);
  const sevenPictures = removedAddedDuplicates.slice(0, 7);
  const addCurrentWord = [...sevenPictures, statePics[wordSpokenIndex]];
  const eightPictures = addCurrentWord.sort(() => 0.5 - Math.random());

  const [currentPictures, setCurrentPictures] = useState(eightPictures);
  const numberOfWords = statePics.length;
  const index = statePics.indexOf(statePics[wordSpokenIndex]) + 1;

  const addWordToRepeat = () => {
    const wordToRepeat = { ...statePics[wordSpokenIndex] };
    wordToRepeat.index = numberOfWords;
    wordToRepeat.id = wordToRepeat.index.toString();
    setStatePics([...statePics, wordToRepeat]);
  };

  const handleClick = () => {
    if (audio) {
      audio.pause();
    }
    const newAudio = new Audio(`${BASE_API_URL}/v1/tts?q=${statePics[wordSpokenIndex].term}`);
    setAudio(newAudio);
    newAudio.play();
  };

  const handlePictureClick = (id: any, term: any) => {
    if (statePics[wordSpokenIndex].term === term && index < numberOfWords) {
      setTimeout(() => (setWordSpokenIndex((prev: number) => prev + 1)), 500);
      setMistakeStyle(false);
      setCorrectStyle(id);
      setTimeout(() => setCorrectStyle(false), 500);
    } else if (statePics[wordSpokenIndex].term === term && index === numberOfWords) {
      setCorrectStyle(id);
      setTimeout(() => (setListEndInfo(true)), 500);
    } else {
      setCorrectStyle(false);
      setMistakeStyle(id);
      addWordToRepeat();
    }
  };

  const goToNext = useGoToRoute(Path.PICK);
  const repeatGame = () => {
    setTimeout(() => {
      setWordSpokenIndex(0);
      setCorrectStyle(false);
      setMistakeStyle(false);
      setListEndInfo(false);
    }, 500);
  };

  useEffect(() => {
    setTimeout(() => handleClick(), 700);
    setTimeout(() => setCurrentPictures(eightPictures), 10);
  }, [wordSpokenIndex]);

  return (
    <>
      <FixWidthContent>
        <div className="main">

          <InfoButton content={<RulesOfTheGame />} />

          {!listEndInfo && (
          <>
            <section>
              <div className="pictures_container">
                {currentPictures.map((word: any) => (
                  <>
                    <div>
                      <div
                        className={`pictures ${word.id === mistakeStyle ? 'mistake' : ''} ${word.id === correctStyle ? 'correct' : ''}`}
                        key={word.id}
                        id={word.id}
                        onClick={() => handlePictureClick(word.id, word.term)}
                        onKeyDown={() => handlePictureClick(word.id, word.term)}
                        tabIndex={0}
                        role="button"
                        aria-label="Mark right word"
                        style={{ backgroundImage: `url(${word.image})` }}
                      />
                      {/* <div className="sign_under_picture">
                        {word.definition}
                      </div> */}
                    </div>
                  </>
                ))}
              </div>
            </section>

            <div
              className="sound_button_container"
            >
              <Button
                id="sound_button"
                onClick={handleClick}
              >
                <Sound className="btnLabel" />
                <br />
                sound
              </Button>
            </div>
          </>
          )}
        </div>

        {listEndInfo && (
          <EndGameInfo repeatGameTrigger={repeatGame} nextGame={goToNext} />
        )}

      </FixWidthContent>
    </>
  );
};

export default withTagManager(FindView);

