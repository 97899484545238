/* eslint-disable max-len */
import { CommonTranslations } from '../interfaces/common';

const PolishCommonTranslations: CommonTranslations = {
  home: 'Strona główna',
  login: {
    title: 'Logowanie',
    labels: {
      error: 'Błąd',
      email: 'EMAIL',
      password: 'HASŁO',
      forgotPassword: 'Zapomniałeś hasła ?',
      submit: 'Zaloguj',
      signUp: 'Zarejestruj',
    },
  },
  passwordStrength: {
    label: 'Siła hasła',
    levels: {
      0: 'Zbyt łatwe',
      1: 'Bardzo łatwe',
      2: 'Dobre hasło',
      3: 'Bezpieczne hasło',
      4: 'Bardzo dobre hasło',
    },
    reminder: 'Twoje hasło musi mieć poziom 2 (dobre hasło) lub wyższy',
    title: 'Ustaw hasło',
    description: 'Upewnij się, że hasło jest wystarczająco silne',
  },
  activation: {
    labels: {
      submit: 'Aktywuj konto',
    },
    placeholders: {
      password: '●●●●●●●●',
    },
  },
  signUp: {
    title: 'Zarejestruj się jako nauczyciel',
    confirm: 'Potwierdź swój adres e-mail klikając na link w wiadomości e-mail, którą do Ciebie wysłaliśmy.',
    labels: {
      error: 'Rejestracja nie powiodła się. Spróbuj ponownie za chwilę.',
      email: 'Email',
      password: 'Hasło',
      name: 'Imię',
      surname: 'Nazwisko',
      submit: 'Zarejestruj',
      back: 'Wróć',
    },
    messages: {
      activationError: 'Aktywacja konta nie powiodła się',
      activationSuccess: 'Aktywacja konta. Możesz się zalogować.',
      error: 'Rejestracja nie powiodła się',
    },
    placeholder: {
      email: 'jannowak@elk.com',
      password: '●●●●●●●●',
      name: 'Jan',
      surname: 'Nowak',
    },
  },
  requestResetPassword: {
    title: 'Resetuj hasło',
    subtitle: 'Wprowadz adres email wyślemy Ci instrukcje',
    labels: {
      email: 'Email',
      submit: 'Resetuj hasło',
      goBack: 'Wróć do strony logowania',
    },
  },
  or: 'lub',
  validation: {
    required: 'Pole jest wymagane',
    name: {
      required: 'Imię jest wymagane',
    },
    surname: {
      required: 'Nazwisko jest wymagane',
    },
    email: {
      invalid: 'Email jest nieprawidłowy',
      required: 'Email jest wymagany',
    },
    password: {
      tooShort: 'Hasło jest nieprawidłowe',
      tooLong: 'Hasło jest nieprawidłowe',
      tooWeak: 'Hasło zbyt proste',
      required: 'Hasło jest wymagane',
    },
  },
  menu: {
    students: 'Uczniowie',
    wordList: 'Listy słów',
    memory: 'Memory',
    pairing: 'Dopasowywanie',
    contact: 'Kontakt',
    profile: 'Mój profil',
    logout: 'Wyloguj',
  },

  cart: {
    name: 'Koszyk',
    total: 'Razem',
    toCart: 'Przejdź do koszyka',
  },
  remove: 'USUŃ',
  more: 'Zobacz więcej...',
  for: 'za',
  price: 'Cena:',
  pcs: 'szt.',
  quantity: 'Ilość',
  link: 'Link:',
  from: 'Od',
  delete: 'Usuń',
  company: {
    name: 'ELK',
  },
  footer: {
    copyright: '© {{year}} ELK. Wszelkie prawa zastrzeżone.',
    termsAndConditions: 'Regulamin',
    privacyPolicy: 'Polityka prywatności',
    contact: 'Kontakt',
    api: 'API',
    headers: {
      important: 'Ważne linki',
      services: 'Lista usług',
    },
  },
  contact: {
    title: 'Skontaktuj się z nami',
    headquarters: 'Siedziba:',
    email: 'Email: ',
    phone: 'Telefon: ',
    send: 'Wyślij',
    successMessage: 'Twoja wiadomość została wysłana',
    errorMessage: 'Nie udało się wysłać wiadomości. Spróbuj ponownie później.',
    validation: {
      required: 'Pole jest wymagane',
      email: 'Nieprawidłowy format adresu e-mail',
    },
    placeholders: {
      email: 'Twój email',
      message: 'Napisz swoją wiadomość',
    },
  },
  terms: {
    title: 'Regulamin',
  },
  privacyPolicy: {
    title: 'Polityka prywatności',
  },
  notFound: {
    title: 'Nie znaleziono',
    subtitle: 'Podana strona nie istnieje',
    backHome: 'Wróć na stronę główną',
  },
};

export default PolishCommonTranslations;
