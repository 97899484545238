/* eslint-disable jsx-a11y/click-events-have-key-events  */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */
import React, { FC } from 'react';
import {
  Col, Layout, Row, Avatar,
} from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import logo from '../../../assets/logo-header.png';
import classes from './DefaultHeader.module.less';
import './DefaultHeader.less';
import { Path } from '../../../constants/Path';
import { getUserData } from '../../../views/auth/slice/selectors';

const { Header } = Layout;
const DefaultHeader: FC<any> = () => {
  const user = useSelector(getUserData);

  return (
    <Header className={classes.header}>
      <Row gutter={0} className={classes.wrapper}>
        <Col xs={18} sm={6} md={4} lg={3}>
          <Link to={Path.STUDENTS}>
            <img src={logo} className={classes.logo} alt="Logo" />
          </Link>
        </Col>
        <Col xs={4} sm={14} md={16} lg={17} />
        <Col xs={4}>
          <div className={classes.avatarContainer}>
            <div className={classes.avatar}>
              <Avatar size="large" icon={<UserOutlined />} />
            </div>
            {user && (
              <p style={{ marginBottom: 0 }}>
                {user.name}
                {/* {user.surname} */}
              </p>
            )}
          </div>
        </Col>
      </Row>
    </Header>
  );
};

export default withRouter(DefaultHeader);
