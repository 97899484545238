import { decode } from 'jsonwebtoken';
import isExpiryDateInTheFuture from './isExpiryDateInTheFuture';
import { DecodedToken, Nullable } from '../types';

const isTokenValid = (token?: Nullable<string>) => {
  if (!token) {
    return false;
  }

  const decodedToken = decode(token);

  if (decodedToken === null) {
    return false;
  }

  return isExpiryDateInTheFuture(decodedToken as DecodedToken);
};

export default isTokenValid;
