import * as Yup from 'yup';

const INITIAL_VALUES: { name: string, description: string } = {
  name: '',
  description: '',
};

const getValidationSchema = (t: any) => Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(95)
    .required(t('words-list:wordsListForm.validation.name.required')),

  description: Yup.string()
    .trim()
    .max(95)
    .required(t('words-list:wordsListForm.validation.description.required')),
});

export { getValidationSchema, INITIAL_VALUES };
