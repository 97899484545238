/* eslint-disable max-len */
import { WordsListTranslations } from '../interfaces/words-list';

const PolishWordsListTranslations: WordsListTranslations = {
  photo: {
    messages: {
      success: 'Dodano zdjęcie',
      error: 'Nie udało się dodać zdjęcia',
    },
  },
  button: {
    create: 'Dodaj listę',
    save: 'Zapisz zmiany',
    addWord: 'Dodaj słowo',
  },
  save: {
    messages: {
      success: 'Lista została zapisana',
      error: 'Nie udało się zapisać listy',
    },
  },
  add: {
    title: 'Dodaj listę',
    messages: {
      success: 'Dodano nową listę',
      error: 'Nie udało się dodać nowej listy. Spróbuj ponownie za chwilę.',
    },
  },
  edit: {
    title: 'Edycja',
    messages: {
      success: 'Edytowano listę',
      error: 'Nie udało się edytować listy. Spróbuj ponownie za chwilę.',
    },
  },
  share: {
    title: 'Udostępnij',
    description: 'Zaznacz komu chcesz udostępnić listę',
    submit: 'Udostępnij',
    cancel: 'Anuluj',
    messages: {
      success: 'Lista została udostępniona',
      error: 'Nie udało się udostępnić listy',
    },
  },
  delete: {
    title: 'Usuń',
    description: 'Czy jesteś pewien, że chcesz usunąć tą listę ?',
    submit: 'Usuń',
    cancel: 'Anuluj',
    messages: {
      success: 'Lista została usunięta',
      error: 'Nie udało się usunąć listy',
    },
  },
  images: {
    select: 'FOTO',
    loadFromComputer: 'Załaduj własne zdjęcie',
    findInPhotoBank: 'Pobierz z banku zdjęć',
  },
  card: {
    active: 'aktywna',
  },
  wordsListForm: {
    labels: {
      name: 'NAZWA',
      description: 'OPIS',
      image: 'grafika',
      words: 'SŁOWA',
      term: 'Zwrot',
      definition: 'Definicja',
      submit: 'Zapisz',
      edit: 'Edytuj',
    },
    info: {
      wordsEmpty: 'Lista jest pusta',
    },
    validation: {
      name: {
        required: 'Nazwa jest wymagana',
      },
      description: {
        required: 'Opis jest wymagany',
      },
    },
  },
};

export default PolishWordsListTranslations;
