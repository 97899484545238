import React, { FC, useCallback } from 'react';
import { Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './EditStudentModal.styles';
import classes from './EditStudentModal.module.less';
import closeIcon from '../../../assets/icons/x.svg';
import StudentForm from '../../../components/StudentForm';
import { UserData } from '../../../services/ApiService.types';
import { success, error } from '../../../components/Message';
import { editStudent, setEditingStudentError } from '../slice/actions';
import useActionStatus from '../../../hooks/useActionStatus';
import { getIsEditingError, getIsEditing } from '../slice/selectors';
import ErrorMessage from '../../../components/ErrorMessage';

type EditStudentModalProps = {
  isVisible: boolean;
  hideModal: () => void;
  student: UserData;
};

const { Title } = Typography;

const EditStudentModal: FC<EditStudentModalProps> = ({ student, isVisible, hideModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsEditing);
  const apiError = useSelector(getIsEditingError);

  const onSubmit = useCallback((values: UserData) => {
    if (student.id) {
      dispatch(editStudent(student.id, values));
    }
  }, [dispatch, student]);

  const cleanupError = useCallback(() => {
    dispatch(setEditingStudentError(null));
  }, [dispatch]);

  const onCancel = useCallback(() => {
    hideModal();
    cleanupError();
  }, [hideModal, cleanupError]);

  const onSuccess = useCallback(() => {
    success(t('student:edit.messages.success'));
    hideModal();
  }, [hideModal, t]);

  const onError = useCallback(() => {
    error(t('student:edit.messages.error'));
  }, [t]);

  useActionStatus(onSuccess, onError, {
    isLoadingSelector: getIsEditing,
    errorSelector: getIsEditingError,
  });

  return (
    <Modal
      bodyStyle={styles.modalBodyStyle}
      footer={null}
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      centered
      closeIcon={<img src={closeIcon} alt="Close" />}
    >
      <Title className={classes.title} style={{ color: 'gray', fontWeight: 'bolder' }}>{t('student:edit.title')}</Title>
      {apiError && <ErrorMessage>{apiError.message}</ErrorMessage>}
      <StudentForm student={student} isLoading={isLoading} onSubmit={onSubmit} />
    </Modal>
  );
};

export default EditStudentModal;
