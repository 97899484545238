import React, { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import classes from './ActivationStudentView.module.less';
import { isTokenValid } from '../../../common/validators';
import {
  getActivationError, getIsActivatingAccount,
} from '../slice/selectors';
import logo from '../../../assets/ logo.png';
import useGoToRoute from '../../../hooks/useGoToRoute';
import { Path } from '../../../constants/Path';
import Text from '../../../components/Text';
import { error, success } from '../../../components/Message';
import useActionStatus from '../../../hooks/useActionStatus';
import ActivationStudentForm from './components/ActivationStudentForm';
import { activateStudentAccount } from '../slice/actions';

const { Title } = Typography;

const ActivationStudentView: FC = () => {
  const { token } = useParams<{ token: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const goToLogin = useGoToRoute(Path.LOGIN);

  const errorMessage = useSelector(getActivationError);

  const onSuccess = useCallback(() => {
    success(t('common:signUp.messages.activationSuccess'));
    goToLogin();
  }, [t]);

  const onError = useCallback(() => {
    error(t('common:signUp.messages.activationError'));
    goToLogin();
  }, [t]);

  useActionStatus(onSuccess, onError, {
    isLoadingSelector: getIsActivatingAccount,
    errorSelector: getActivationError,
  });

  useEffect(() => {
    if (!isTokenValid(token)) {
      goToLogin();
    }
  }, [goToLogin, token]);

  const onSubmit = useCallback((values) => (
    token && dispatch(activateStudentAccount(token, values.password))
  ), [dispatch, token]);

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={logo} alt="Logo" />
      <div />
      <Title className={classes.title}>{t('common:passwordStrength.title')}</Title>
      <Text className={classes.subtitle}>{t('common:passwordStrength.description')}</Text>
      <ActivationStudentForm onSubmit={onSubmit} />
      {Boolean(errorMessage) && (
        <div>
          <Text type="danger" strong>{errorMessage.message}</Text>
        </div>
      )}
    </div>
  );
};

export default ActivationStudentView;
