import React from 'react';
import { message } from 'antd';
import MessageContent from './components/MessageContent';

message.config({
  maxCount: 3,
});

const success = (messageContent: string) => message.success({
  content: <MessageContent type="success">{messageContent}</MessageContent>,
  icon: <span />,
});

const error = (messageContent: string) => message.error({
  content: <MessageContent type="error">{messageContent}</MessageContent>,
  icon: <span />,
});

const Message = {
  success,
  error,
};

export default Message;
export { success, error };
