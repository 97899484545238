/* eslint-disable max-len */
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { Typography } from 'antd';
// import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import logo from '../../../assets/ logo.png';
import classes from './LoginView.module.less';
import { Path } from '../../../constants/Path';
import useAuthToken from '../../../hooks/useAuthToken';
import { signIn } from '../slice/actions';
import { UserData } from '../../../services/ApiService.types';
import { Roles } from '../../../constants/Roles';

// const { Title } = Typography;

const LoginView: FC = () => {
  // const { t } = useTranslation();
  const { push } = useHistory();

  const goToHomePage = (user: UserData) => push(user.role === Roles.TEACHER ? Path.STUDENTS : Path.MY_LISTS);

  const dispatch = useDispatch();

  useAuthToken(undefined, goToHomePage);

  useEffect(() => () => {
  }, [dispatch]);

  const onSubmit = useCallback(
    (values) => {
      dispatch(signIn(values.email, values.password));
    },
    [dispatch],
  );

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={logo} alt="Logo" />
      {/* <Title className={classes.title} style={{ color: 'lightgrey', fontSize: '1.5rem' }}>{t('common:login.title')}</Title> */}
      <LoginForm onSubmit={onSubmit} />
    </div>
  );
};

export default LoginView;
