import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import './MemoryView.less';
import FixWidthContent from '../../components/FixWidthContent';
import Card from '../../components/Memory';
import {
  removeCard,
} from './slice/slice';
import { getActiveList } from '../auth/slice/selectors';
import useGoToRoute from '../../hooks/useGoToRoute';
import { Path } from '../../constants/Path';
import { BASE_API_URL } from '../../services/ApiService';
import withTagManager from '../../hooks/withTagManager';
import InfoButton from '../../components/InfoButton/InfoButton';
import RulesOfTheGame from './RulesOfTheGame';
import EndGameInfo from '../../components/EndGameInfo/EndgameInfo';

interface CardData {
  wordId: string,
  cardId: string,
  term: string,
  image: string,
  type: 'word' | 'image',
  isFlipped: boolean,
  isFinished: boolean,
}

const PART_SIZE = 10;

const divideWordsIntoParts = (words: any): any[] => {
  const dividedWords: any[] = [];
  for (let i = 0; i < words.length; i += PART_SIZE) {
    dividedWords.push(words.slice(i, i + PART_SIZE));
  }

  return dividedWords;
};

const generateCardsForPart = (part: any): CardData[] => {
  const cards: CardData[] = [];

  part.forEach((word: any) => {
    cards.push({
      wordId: word.id,
      cardId: nanoid(),
      term: word.term,
      image: word.image,
      type: 'word',
      isFlipped: false,
      isFinished: false,
    });

    cards.push({
      wordId: word.id,
      cardId: nanoid(),
      term: word.term,
      image: word.image,
      type: 'image',
      isFlipped: false,
      isFinished: false,
    });
  });

  return cards.sort(() => 0.5 - Math.random());
};

function MemoryView() {
  const activeList = useSelector(getActiveList);
  const [activePartIndex, setActivePartIndex] = useState<number>(0);
  const [dividedWords, setDividedWords] = useState<any[]>([]);
  const [isFinished, setIsFinish] = useState<boolean>(false);
  const [listEndInfo, setListEndInfo] = useState(false);
  const [timeoutHandler, setTimeoutHandler] = useState<any>(null);
  const [audio, setAudio] = useState<any>(null);
  const goToNext = useGoToRoute(Path.RECOGNIZE);
  const dispatch = useDispatch();
  const [cards, setCardsList] = useState<CardData[]>([]);

  const clearActualMatch = useCallback(() => {
    setCardsList(
      cards.map((word: any) => ({
        ...word,
        isFlipped: false,
      })),
    );
  }, [cards]);

  const handleClick = useCallback((cardId) => {
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }

    if (audio) {
      audio.pause();
    }

    const currentCard: CardData = cards.find((card: CardData) => card.cardId === cardId) as CardData;

    const newAudio = new Audio(`${BASE_API_URL}/v1/tts?q=${currentCard.term}`);
    setAudio(newAudio);
    if (currentCard.type === 'image') newAudio.play();

    const newCards = cards.map((card: any) => {
      if (card.cardId === currentCard.cardId) {
        return {
          ...card,
          isFlipped: true,
        };
      }

      return card;
    });

    setCardsList(newCards);

    const flippedCards = newCards.filter((card: CardData) => card.isFlipped);

    if (flippedCards.length === 2 && flippedCards[0].wordId === flippedCards[1].wordId) {
      const notFinished = newCards.filter((card: CardData) => !card.isFinished);

      if (notFinished.length <= 2) {
        setIsFinish(true);
      }

      setTimeout(() => setCardsList(
        newCards.map((card: any) => {
          if (card.wordId === flippedCards[0].wordId) {
            return {
              ...card,
              isFlipped: false,
              isFinished: true,
            };
          }

          return card;
        }),
      ), 700);
    } else if (flippedCards.length > 1) {
      setTimeout(clearActualMatch, 700);
    } else {
      const handler = setTimeout(clearActualMatch, 3000);
      setTimeoutHandler(handler);
    }
  }, [timeoutHandler, dispatch, cards, audio]);

  const handleDoubleClick = () => {
    dispatch(removeCard(
      {
        type: 'removeCard',
        id: 'cardId',
      },
    ));
  };

  useEffect(() => {
    if (activeList.words.length >= PART_SIZE) {
      const words = divideWordsIntoParts(activeList.words);
      setDividedWords(words);
      const firstPart = words[0];
      const firstCards = generateCardsForPart(firstPart);
      setCardsList(firstCards);
    } else if (activeList.words.length < PART_SIZE) {
      const cardsForShortList = generateCardsForPart(activeList.words);
      setDividedWords([activeList.words]); // Ustaw listę bez dzielenia
      setCardsList(cardsForShortList);
    }
  }, [activeList.words]);

  const repeatGame = () => {
    clearActualMatch();
    setIsFinish(false);
    setListEndInfo(false);
    // Update indexes
    setActivePartIndex(0);
    // Generate new words rounds
    const newDividedWords = divideWordsIntoParts(activeList.words);
    setDividedWords(newDividedWords);
    // First round
    const firstPart = newDividedWords[0];
    const newCards = generateCardsForPart(firstPart);
    setCardsList(newCards);
  };

  useEffect(() => {
    if (isFinished && activePartIndex >= 0 && activePartIndex < dividedWords.length - 1) {
      setTimeout(() => {
        const newIndex = activePartIndex + 1;
        setActivePartIndex(newIndex);
        const newPart = dividedWords[newIndex];
        const newCards = generateCardsForPart(newPart);
        setCardsList(newCards);
        setIsFinish(false);
      }, 1500);
    } else if (isFinished && activePartIndex === dividedWords.length - 1) {
      setTimeout(() => {
        setListEndInfo(true);
      }, 1500);
    }
  }, [isFinished, dividedWords]);

  return (
    <FixWidthContent className="test">
      <InfoButton content={<RulesOfTheGame />} />

      {dividedWords.length >= 2 && (
        <p id="pages"> {activePartIndex + 1} / {dividedWords.length} </p>
      )}

      {!listEndInfo && (
        <div>
          <div className="cardsGrid">
            {cards && cards.map((card: CardData) => (
              <Card
                id={card.cardId}
                text={card.term}
                image={card.image}
                type={card.type}
                isFinished={card.isFinished}
                isFlipped={card.isFlipped}
                handleClick={handleClick}
                handleDoubleClick={handleDoubleClick}
                key={card.cardId}
              />
            ))}
          </div>
        </div>
      )}

      {listEndInfo && (
        <EndGameInfo repeatGameTrigger={repeatGame} nextGame={goToNext} />
      )}
    </FixWidthContent>
  );
}

export default withTagManager(MemoryView);
