/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FC, memo, useEffect, useState,
} from 'react';
import './WritingView.less';
import './slice/slice';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { ReactComponent as Repeat } from '../../assets/icons/games/writing/refresh-ccw-rec.svg';
import { ReactComponent as NextAnyway } from '../../assets/icons/games/writing/arrow-right-rec.svg';
import FixWidthContent from '../../components/FixWidthContent';
import withTagManager from '../../hooks/withTagManager';
import { WordFromList } from '../../components/Writing/WordFromList';
import InfoButton from '../../components/InfoButton/InfoButton';
import RulesOfTheGame from './RulesOfTheGame';
import { getActiveList } from '../auth/slice/selectors';
import EndGameInfo from '../../components/EndGameInfo/EndgameInfo';

export const WritingView: FC = memo(() => {
  const activeList = useSelector(getActiveList);
  const { words } = activeList;
  const wordsCopy = [...words];

  const [currentWords, setCurrentWords] = useState(wordsCopy);
  const [wordOnScreen, setWordOnScreen] = useState(0);
  const [nextWordButton, setNextWordButton] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState<any>('');
  const [listEndInfo, setListEndInfo] = useState(false);
  const [tryAgainButton, setTryAgainButton] = useState<boolean>(false);
  const [inputContent, setInputContent] = useState<string>('');

  const numberOfWords = currentWords.length;
  const index = currentWords.indexOf(currentWords[wordOnScreen]) + 1;

  const repeatGame = () => {
    setListEndInfo(false);
    setWordOnScreen(0);
    setFeedbackInfo('');

    setTimeout(() => {
    }, 500);
  };

  const handleNextWordButton = () => {
    if (index < numberOfWords) {
      setWordOnScreen((prev) => prev + 1);
      setFeedbackInfo('');
      setNextWordButton(false);
      setTryAgainButton(false);
    } else if (index === numberOfWords) {
      setListEndInfo(true);
      setNextWordButton(false);
    }
  };

  const tryAgain = () => {
    setFeedbackInfo('');
    setTryAgainButton(false);
    setNextWordButton(false);
    setInputContent('');
  };

  const handleNextAnywayButton = () => {
    const clearingSteps = () => {
      setFeedbackInfo('');
      setTryAgainButton(false);
      setNextWordButton(false);
      setInputContent('');
    };

    if (wordOnScreen < currentWords.length - 1) {
      setTimeout(() => {
        setWordOnScreen((prev) => prev + 1);
      }, 100);
      clearingSteps();
    } else {
      setListEndInfo(true);
      clearingSteps();
    }
  };

  useEffect(() => {
    if (feedbackInfo && nextWordButton) {
      setTimeout(() => {
        handleNextWordButton();
      }, 1000);
    }
  }, [feedbackInfo, nextWordButton]);

  return (
    <>
      <FixWidthContent className="enter">

        <InfoButton content={<RulesOfTheGame />} />

        {!listEndInfo && (
        <>
          <WordFromList
            id={currentWords[wordOnScreen].id}
            content={currentWords[wordOnScreen].term}
            translation={currentWords[wordOnScreen].definition}
            setNextWordButton={setNextWordButton}
            setFeedbackInfo={setFeedbackInfo}
            setTryAgainButton={setTryAgainButton}
            tryAgainButton={tryAgainButton}
            tryAgain={tryAgain}
            setInputContent={setInputContent}
            inputContent={inputContent}
          />

          <div id="feedback">{feedbackInfo}</div>

          <div className="optionsContainer">

            {tryAgainButton && (
              <>
                <Button
                  className="clearButton"
                  onClick={tryAgain}
                >
                  <Repeat className="btnLabel" />
                  <br />
                  CLEAR
                </Button>

                <Button
                  className="nextButton"
                  onClick={handleNextAnywayButton}
                >
                  <NextAnyway className="btnLabel" />
                  <br />
                  NEXT
                </Button>
              </>
            )}
          </div>
        </>
        )}

        {listEndInfo && (
          <EndGameInfo repeatGameTrigger={repeatGame} bigCongrats />
        )}

      </FixWidthContent>
    </>
  );
});

export default withTagManager(WritingView);
