import React, { FC } from 'react';
import classNames from 'classnames';
import classes from '../PasswordStrengthMeter/PasswordStrengthMeter.module.less';

type PasswordStrengthBlockProps = {
  className: string | false;
};

const PasswordStrengthBlock: FC<PasswordStrengthBlockProps> = ({ className }) => (
  <div className={classNames([classes.block, className])} />
);

export default PasswordStrengthBlock;
