/* eslint-disable max-len */
// eslint-disable-next-line react/react-in-jsx-scope
import React from 'react';

const RulesOfTheGame = () => (
  <>
    <p style={{ color: 'darkslategray', margin: '0' }}>
      <b>Moduł WYBIERZ: </b>
      <li>Kliknij na słowo odpowiadające widocznej grafice/zdjęciu. </li>
      <li>Jeśli wybór był poprawny, zostanie pokazany kolejny obraz. Jeśli nie, ponów próbę. </li>
      <li> INFO: Błąd powoduje ponowne wyświetlenie danego słowa na koniec gry. </li>
    </p>
  </>
);

export default RulesOfTheGame;
