import { useLocation } from 'react-use';
import { useMemo } from 'react';
import { Path } from '../constants/Path';

const useMatchRoute = (paths: Path[]) => {
  const { pathname = '' } = useLocation();
  const buildRegexpString = (value: string) => `^${value.replace(/:(\w+)/g, '[a-zA-Z0-9]+')}(/?)$`;
  const matchers = paths.map((p) => new RegExp(buildRegexpString(p)));

  return useMemo(
    () => matchers.some((matcher) => pathname.match(matcher)),
    [matchers, pathname],
  );
};

export default useMatchRoute;
