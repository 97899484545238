/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React, { FC, useCallback } from 'react';
import {
  // Button,
  Modal, Typography,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  fetchWordsListPage,
  resetData,
  setSharingWordsListError,
} from '../slice/actions';
import {
  getSharingWordsListError, getIsSharingWordsList,
} from '../slice/selectors';
import classes from './ShareWordsListModal.module.less';
import styles from './ShareWordsListModal.styles';
import { success, error } from '../../../components/Message';
import Text from '../../../components/Text';
import closeIcon from '../../../assets/icons/x.svg';
import useActionStatus from '../../../hooks/useActionStatus';
import ListOfStudentsInModal from './ListOfStudentsInModal';

type DeleteWordsListModalProps = {
  wordsList: { id: number, name: string, combinedIds: string[] };
  hideModal: () => void;
};
const { Title } = Typography;

const ShareWordsListModal: FC<DeleteWordsListModalProps> = ({
  wordsList, hideModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSuccess = useCallback(() => {
    success(t('words-list:share.messages.success'));
    hideModal();

    return wordsList;
  }, [hideModal, t, wordsList]);

  const onError = useCallback(() => {
    error(t('words-list:share.messages.failure'));
  }, [t]);

  useActionStatus(onSuccess, onError, {
    isLoadingSelector: getIsSharingWordsList,
    errorSelector: getSharingWordsListError,
  });

  const onCancel = useCallback(() => {
    dispatch(setSharingWordsListError(null));
    hideModal();
    dispatch(resetData());
    dispatch(fetchWordsListPage());
  }, [dispatch, hideModal]);

  return wordsList && (
    <Modal
      bodyStyle={styles.modalBodyStyle}
      footer={null}
      visible={Boolean(wordsList)}
      onCancel={onCancel}
      destroyOnClose
      closeIcon={<img src={closeIcon} alt="Close" />}
      centered
    >
      <Title className={classes.centered}>{t('words-list:share.title')}</Title>
      <Text className={classes.description}> {wordsList.name} </Text>

      <ListOfStudentsInModal wordsList={wordsList} />

    </Modal>
  );
};

export default ShareWordsListModal;

// /* eslint-disable react/no-unescaped-entities */ --> ostatni działający kod
// import React, { FC, useCallback } from 'react';
// import {
//   Button,
//   Modal, Typography,
// } from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import classNames from 'classnames';
// import {
//   setSharingWordsListError,
//   // shareWordsList,
//   // setAccessToWordListByCombinedId,
// } from '../slice/actions';
// import {
//   getSharingWordsListError, getIsSharingWordsList,
// } from '../slice/selectors';
// import classes from './ShareWordsListModal.module.less';
// import styles from './ShareWordsListModal.styles';
// import { success, error } from '../../../components/Message';
// import Text from '../../../components/Text';
// import closeIcon from '../../../assets/icons/x.svg';
// import useActionStatus from '../../../hooks/useActionStatus';
// import ListOfStudentsInModal from './ListOfStudentsInModal';

// type DeleteWordsListModalProps = {
//   wordsList: { id: number, name: string, combinedIds: string[] };
//   hideModal: () => void;
// };
// const { Title } = Typography;

// const ShareWordsListModal: FC<DeleteWordsListModalProps> = ({
//   wordsList, hideModal,
// }) => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const isLoading = useSelector(getIsSharingWordsList);
//   const apiError = useSelector(getSharingWordsListError);

//   const onSuccess = useCallback(() => {
//     success(t('words-list:share.messages.success'));
//     hideModal();
//   }, [hideModal, t]);

//   const onError = useCallback(() => {
//     error(t('words-list:share.messages.failure'));
//   }, [t]);

//   useActionStatus(onSuccess, onError, {
//     isLoadingSelector: getIsSharingWordsList,
//     errorSelector: getSharingWordsListError,
//   });

//   // const onClick = useCallback(() => {
//   //   if (wordsList && wordsList.id) {
//   //     dispatch(shareWordsList(wordsList.id));
//   //   }
//   // }, [dispatch, wordsList]);

//   // const onClick = useCallback(() => {
//   //   if (wordsList && wordsList.id) {
//   //     dispatch(shareWordsList(wordsList.id));
//   //   }
//   // }, [dispatch, wordsList]);

//   const onCancel = useCallback(() => {
//     dispatch(setSharingWordsListError(null));
//     hideModal();
//   }, [dispatch, hideModal]);

//   return wordsList && (
//     <Modal
//       bodyStyle={styles.modalBodyStyle}
//       footer={null}
//       visible={Boolean(wordsList)}
//       onCancel={onCancel}
//       destroyOnClose
//       centered
//       closeIcon={<img src={closeIcon} alt="Close" />}
//     >
//       <Title className="centered" style={{ color: 'gray', fontWeight: 'bolder' }}>{t('words-list:share.title')}</Title>
//       <Text className={classes.description}>{t('words-list:share.description')} "{wordsList.name}" </Text>
//       <div className={classes.buttons}>
//         <Button
//           className={classNames([classes.button, classes.cancelButton])}
//           size="large"
//           type="ghost"
//           onClick={onCancel}
//           disabled={isLoading}
//         >
//           {t('words-list:share.cancel')}
//         </Button>
//         <Button
//           className={classes.button}
//           size="large"
//           type="primary"
//           // onClick={onClick}
//           disabled={isLoading}
//           loading={isLoading}
//         >
//           {t('words-list:share.submit')}
//         </Button>
//       </div>

//       {apiError && <div>{apiError.message}</div>}

//       <ListOfStudentsInModal wordsList={wordsList} />

//     </Modal>
//   );
// };

// export default ShareWordsListModal;
