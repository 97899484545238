import React, { FC, useCallback } from 'react';
import { Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './AddWordsListModal.styles';
import classes from './AddWordsListModal.module.less';
import closeIcon from '../../../assets/icons/x.svg';
import { success, error } from '../../../components/Message';
import { addWordsList, setAddingWordsListError } from '../slice/actions';
import useActionStatus from '../../../hooks/useActionStatus';
import { getIsAddingError, getIsAdding } from '../slice/selectors';
import ErrorMessage from '../../../components/ErrorMessage';
import WordsListForm from '../../../components/WordsListForm';

type AddWordsListModalProps = {
  isVisible: boolean;
  hideModal: () => void;
};

const { Title } = Typography;

const AddWordsListModal: FC<AddWordsListModalProps> = ({ isVisible, hideModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsAdding);
  const apiError = useSelector(getIsAddingError);

  const onSubmit = useCallback((values: { name: string }) => {
    dispatch(addWordsList(values));
  }, [dispatch]);

  const cleanupError = useCallback(() => {
    dispatch(setAddingWordsListError(null));
  }, [dispatch]);

  const onCancel = useCallback(() => {
    hideModal();
    cleanupError();
  }, [hideModal, cleanupError]);

  const onSuccess = useCallback(() => {
    success(t('words-list:add.messages.success'));
    hideModal();
  }, [hideModal, t]);

  const onError = useCallback(() => {
    error(t('words-list:add.messages.error'));
  }, [t]);

  useActionStatus(onSuccess, onError, {
    isLoadingSelector: getIsAdding,
    errorSelector: getIsAddingError,
  });

  return (
    <Modal
      bodyStyle={styles.modalBodyStyle}
      footer={null}
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      centered
      closeIcon={<img src={closeIcon} alt="Close" />}
    >
      <Title className={classes.title}>{t('words-list:add.title')}</Title>
      {apiError && <ErrorMessage>{apiError.message}</ErrorMessage>}
      <WordsListForm isLoading={isLoading} onSubmit={onSubmit} />
    </Modal>
  );
};

export default AddWordsListModal;
