import { AxiosError } from 'axios';
import slice from './slice';
import history from '../../../getBrowserHistory';
import { ApiError } from '../../../services/ApiService.types';
import { AppThunk } from '../../../redux/store';
import API from '../../../services/ApiService';
import { Path } from '../../../constants/Path';

const {
  startLoadingData, finishLoadingData, setData, setLoadingDataError,
  startSaving, finishSaving, setSavingError,
} = slice.actions;

const fetchWordsListData = (id: string): AppThunk => (
  async (dispatch) => {
    dispatch(startLoadingData());
    try {
      const { data: wordsList } = await API.wordsList.getOne(id);

      dispatch(setData({ data: wordsList }));
    } catch (e) {
      const { response } = e as AxiosError<ApiError>;
      dispatch(setLoadingDataError(response && response.data));
    } finally {
      dispatch(finishLoadingData());
    }
  });

const saveWordList = (id: string, values: { name: string, description: string, combinedId: string, words: any[] }): AppThunk => (
  async (dispatch) => {
    dispatch(startSaving());
    try {
      const { data: wordsList } = await API.wordsList.save(id, values);

      dispatch(setData({ data: wordsList }));
      history.push(Path.WORDS_LIST);
    } catch (e) {
      const { response } = e as AxiosError<ApiError>;
      dispatch(setSavingError(response && response.data));
    } finally {
      dispatch(finishSaving());
    }
  });

export {
  fetchWordsListData,
  saveWordList,
  setLoadingDataError,
};
