/* eslint-disable max-len */
import React from 'react';

const RulesOfTheGame = () => (
  <>
    <p style={{ color: 'darkslategray', margin: 0 }}>
      <b> Moduł MEMORY: </b>
      <li> Jest to klasyczna gra memory: kliknięcie elementu spowoduje odwrócenie karty.</li>
      <li>Przy każdroazowej aktywacji elementu słychać jego wymowę. </li>
      <li>Celem jest odwrócenie wszystkich odpowiadających sobie par słowo-obraz. Poprawny wybór wyłącza z gry dane elementy. </li>
      <li>INFO: Jeśli lista jest dłuższa niż 10 elementów, karty zostaną rodzielone na mniejsze grupy.</li>
    </p>
  </>
);

export default RulesOfTheGame;
