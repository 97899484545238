import React, { FC } from 'react';
import classNames from 'classnames';
import classes from './FixWidthContent.module.less';

const FixWidthContent: FC<{ className?: string }> = ({ children, className }) => (
  <div className={classNames(classes.content, className)} id="content">
    {children}
  </div>
);

export default FixWidthContent;
