import * as Yup from 'yup';
import { UserData } from '../../services/ApiService.types';

const INITIAL_VALUES: UserData = {
  name: '',
  surname: '',
  email: '',
};

const getValidationSchema = (t: any) => Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(95)
    .required(t('student:studentForm.validation.name.required')),
  surname: Yup.string()
    .trim()
    .max(95)
    .required(t('student:studentForm.validation.surname.required')),
  email: Yup.string()
    .trim()
    .email()
    .required(t('student:studentForm.validation.email.required')),
});

export { getValidationSchema, INITIAL_VALUES };
