import * as Yup from 'yup';

export default (t: any) => Yup.object().shape({
  email: Yup.string()
    .email(t('common:validation.email.invalid'))
    .required(t('common:validation.email.required')),
  password: Yup.string()
    .min(6, t('common:validation.password.tooShort'))
    .max(100, t('common:validation.password.tooLong'))
    .required(t('common:validation.password.required')),
});

export type Values = {
  email: string;
  password: string;
};

export const INITIAL_VALUES: Values = {
  email: '',
  password: '',
};
