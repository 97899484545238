import React, { FC } from 'react';
import { LayoutProps } from 'antd/lib/layout';
import { Layout } from 'antd';
import classnames from 'classnames';
import classes from './AppContent.module.less';

const { Content } = Layout;

type AppContentProps = LayoutProps & {
  noFrames?: boolean,
  noFooter?: boolean,
  isPublic?: boolean,
  fullMode?: boolean,
  summary?: boolean,
};
const AppContent: FC<AppContentProps> = ({
  children,
  className,
  noFrames,
  noFooter,
  isPublic,
  fullMode,
  summary,
}) => (
  <Content
    className={classnames({
      [classes.content]: true,
      [classes.noFrames]: noFrames,
      [classes.noFooter]: noFooter,
      [classes.centered]: isPublic,
      [classes.withSideMenu]: !isPublic,
      [classes.fullMode]: fullMode,
      [classes.summary]: summary,
    }, className)}
    id="main"
  >
    {children}
  </Content>
);

export default AppContent;
