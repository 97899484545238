/* eslint-disable max-len */
// eslint-disable-next-line react/react-in-jsx-scope
import * as React from 'react';

const RulesOfTheGame = () => (
  <>
    <p style={{ color: 'darkslategray', margin: '0' }}>
      <b>Moduł POWTÓRZ: </b>
      <li>Wciśnij przycisk GO, aby kolejno zobaczyć i usłyszeć wszystkie słowa znajdujące się na liście. Elementy pojawiają się w losowej kolejności w każdej grze. </li>
      <li>W dowolnym momencie można zatrzymać prezentację przyciskiem PAUSE. Przy ponownym uruchomieniu słowo zostanie powtórzone, zanim zostanie pokazane następne słowo z listy. </li>
      <li>Po zakończeniu listy można w razie potrzeby odtworzyć całość ponownie. Szczególnie jeśli dopiero zaczynasz uczyć się listy lub sprawia ci ona trudność. </li>
    </p>
  </>
);

export default RulesOfTheGame;
