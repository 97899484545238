import { Selector } from 'react-redux';
import { RootState } from '../../../redux/store';
import authSlice from './slice';
import { Nullable } from '../../../types';
import { ApiError } from '../../../services/ApiService.types';

const getIsSignUp = (state: RootState) => state[authSlice.name].isSignUpAccount;
const getSignUpError = (state: RootState) => state[authSlice.name].signUpError;

const getIsAuthenticating = (state: RootState) => state[authSlice.name].isLoggingIn;
const getIsActivatingAccount = (state: RootState) => state[authSlice.name].isActivatingAccount;
const getAuthError = (state: RootState) => state[authSlice.name].authError;
const getActivationError = (state: RootState) => state[authSlice.name].activationError;
const getUserData = (state: RootState) => state[authSlice.name].userData;
const getActiveList = (state: RootState) => state[authSlice.name].activeList;

export const getIsResettingPassword = (state: RootState) => (
  state[authSlice.name].isResettingPassword
);
export const getResetPasswordError: Selector<RootState, Nullable<ApiError>> = (state) => (
  state[authSlice.name].resetPasswordError
);

export {
  getIsAuthenticating,
  getAuthError,
  getActivationError,
  getIsActivatingAccount,
  getUserData,
  getIsSignUp,
  getSignUpError,
  getActiveList,
};
