/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';
import { Nullable } from '../../../types';

type INITIAL_STATE_TYPE = {
  isSending: boolean,
  error: Nullable<any>;
};

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  isSending: false,
  error: null,
};

const { name, reducer, ...rest } = createSlice({
  name: 'contact',
  initialState: INITIAL_STATE,
  reducers: {
    startSendingContactForm: (state) => {
      state.isSending = true;
      state.error = INITIAL_STATE.error;
    },
    setSendingContactFormError: (state, action) => {
      state.error = action.payload;
    },
    finishSendingContactForm: (state) => {
      state.isSending = false;
    },
  },
});

export default {
  name,
  reducer,
  ...rest,
};
