import { useHistory, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { Path } from '../constants/Path';

const useGoToRoute = (routeName?: Path, keepSearch?: boolean) => {
  const { push } = useHistory();
  const location = useLocation();

  return useCallback(() => {
    if (routeName) {
      push(`${routeName}${keepSearch ? location.search : ''}`);
    }
  }, [keepSearch, location.search, push, routeName]);
};

export default useGoToRoute;
