import React, { FC } from 'react';
import {
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FixWidthContent from '../../components/FixWidthContent';
import classes from './ContactView.module.less';
import ContactForm from '../../components/ContactForm/ContactForm';
import { sendContactForm } from './slice/actions';
import withTagManager from '../../hooks/withTagManager';

const ContactView: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <FixWidthContent>
      <Col span={24}>
        <h1 className={classes.title}>{t('common:contact.title')}</h1>
        <div className={classes.subContent}>
          <ContactForm onSubmit={(values: any) => dispatch(sendContactForm(values))} />
        </div>
      </Col>
    </FixWidthContent>
  );
};

export default withTagManager(ContactView);
