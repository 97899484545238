import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import zxcvbn from 'zxcvbn';
import { LockOutlined } from '@ant-design/icons';
import getValidationSchema, { INITIAL_VALUES, Values } from './ActivationStudentForm.schema';
import { getIsActivatingAccount } from '../../../slice/selectors';
import styles from './ActivationStudentForm.module.less';
import PasswordStrengthMeter from '../../../../../components/PasswordStrengthMeter';

const { Text } = Typography;

type ActivationStudentFormProps = {
  onSubmit: (values: Values) => void;
};

const ActivationStudentForm: FC<ActivationStudentFormProps> = (
  { onSubmit }: ActivationStudentFormProps,
) => {
  const { t } = useTranslation('common');
  const isActivating = useSelector(getIsActivatingAccount);

  const validatePasswordStrength = (password: string): string | undefined => {
    const { score } = zxcvbn(password);

    return score < 2 ? t('validation.password.tooWeak') : undefined;
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(t)}
      initialValues={INITIAL_VALUES}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} data-cy="activationForm">
          <Form.Item name="password" validate={validatePasswordStrength}>
            <Input.Password
              name="password"
              placeholder={t('activation.placeholders.password')}
              prefix={<LockOutlined />}
            />
          </Form.Item>
          <PasswordStrengthMeter password={values.password} />
          <Text>{t('passwordStrength.reminder')}</Text>
          <SubmitButton
            disabled={isActivating}
            loading={isActivating}
            className={styles.button}
            size="large"
            block
          >
            {t('activation.labels.submit')}
          </SubmitButton>
        </form>
      )}
    </Formik>
  );
};

export default ActivationStudentForm;
