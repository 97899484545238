import React, { FC } from 'react';
import ReactCardFlip from 'react-card-flip';
import Tilt from 'react-parallax-tilt';
import classes from './Cards.module.less';
import { ReactComponent as Leaf } from '../../assets/icons/games/memory/leaf-outline.svg';

type CardProps = {
  id: any,
  text?: string,
  image?: string,
  type?: 'word' | 'image',
  isFinished?: boolean,
  isFlipped?: boolean,
  handleClick?: any,
  handleDoubleClick?: any,
};

export const Card: FC< CardProps > = ({
  handleClick,
  text,
  image,
  type,
  id,
  isFlipped,
  isFinished,
  handleDoubleClick,
}) => (
  <Tilt tiltEnable={!isFinished}>
    <ReactCardFlip
      containerClassName={isFinished ? classes.cardFinished : ''}
      isFlipped={isFlipped || isFinished || false}
      flipDirection="vertical"
      flipSpeedFrontToBack={0.2}
      flipSpeedBackToFront={0.2}
    >
      <div
        className={classes.card}
        onClick={() => { handleClick(id); }}
        aria-hidden="true"
        id={id}
      >
        <Leaf className="leaf" />
      </div>

      <div
        className={classes.cardTranslation}
        id={id}
        onDoubleClick={() => handleDoubleClick(id)}
      >
        {type === 'image' && <div className={classes.cardImage} style={{ backgroundImage: `url(${image})` }} />}
        {type === 'word' && text}
      </div>
    </ReactCardFlip>
  </Tilt>
);
export default Card;

